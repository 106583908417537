import CompositionBase from 'core/CompositionBase.coffee'
###* Props definition
  * @typedef {Object}  ApprovalbarProps
  * @property {string} instancename - Our Instancename for registration in ComponentHub
  * @property {object} resource
  ###


###*
* @augments CompositionBase
###
export class Approvalbar extends CompositionBase
  ###*
    * @param {Object} options
    * @property {ApprovalbarProps} props
    * @property {Function} emit
    * @property {object} refs
    * @property {object} quasar
    * @constructor
    ###
  constructor: (options) ->
    super(options)
    this.resource=options.props.resource
    this.$emit=options.emit
    this.processChildren=false

  onApprove:  () ->
    ###* @ts-ignore ###
    data = "" # No comment on approval !
    if this.processChildren
      kids = this.ComponentHub.FileSystemTree.getAllChildren this.resource.id, true
      for kid in kids
        await kid.onApproved(data)
        this.$emit('onApproved', kid)
    await this.resource.onApproved(data)
    #this.ComponentHub['ImproveContent_' + this.ComponentHub.activeMainPanel.toString() + '_' + this.props.resource.resourceId].vueRefresh +=1
    await this.resource.inReview.getEntries()
    this.$emit('onApproved', this.resource)


  onReject: () ->
    ###* @ts-ignore ###
    data = "" # No comment on reject as comment in text is enough IM-5727!
    if this.processChildren
      kids = this.ComponentHub.FileSystemTree.getAllChildren this.resource.id, true
      for kid in kids
        await kid.onRejected(data)
        this.$emit('onRejected', kid)
    await this.resource.onRejected(data)
    await this.resource.inReview.getEntries()
    #this.ComponentHub['ImproveContent_' + this.ComponentHub.activeMainPanel.toString() + '_' + this.props.resource.resourceId].vueRefresh +=1
    this.$emit('onRejected', this.resource)
