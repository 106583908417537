import {toRaw} from "vue"
import ComponentHub from "core/componentsHub.coffee"
import {LSSettingsFileObject} from "core/lsSettingsObject.coffee"
import {SettingsFile} from "components/FileSystemBrowser/SettingsFile.coffee"
import PluginBase from 'core/PluginBase.coffee'
import {Server} from 'apis/improveREST/connectionStatus.coffee'
import {initApi, apiGetRootDirWithMeta, apiGetResourceByPath,apiGetResource,apiGetResourceWithMeta} from 'apis/improveREST/rawImprove.coffee'


class Improveweb extends PluginBase
  constructor:->
    super()
    this.name = "improveweb"
    this.icon = "mdi-all-inclusive"
    this.mainTitle="ImproveWeb"
    this.description="Improve Webclient."
    this.author="© scinteco.com 2022"
    this.version="#{ComponentHub.appVersion} ##{ComponentHub.appBuild}"
    this.longDescription="The new improve Web-Interface. Runnable as desktop app, progressive web app or single page application in the browser"
    this.mainNavigationWidget='ImproveBrowser'
    this.webApi='ImproveREST'
    this.server = null
    ###* Mandatory for Plugins.  Does this plugin use the shared contentheader for teleport
      * @type {boolean}
      ###
    this.usesContentHeader=true
    ###* Mandatory for Plugins.  Does this plugin use the shared content footer  for teleport
      * @type {boolean}
      ###
    this.usesContentFooter=true
    ###* Mandatory for Plugins.  Does this plugin use the shared content side bar for teleport
      * @type {boolean}
      ###
    this.usesContentSideBar=true
    ###* Mandatory for Plugins.  Does this plugin hide the whole framework for normal users
      * @type {boolean}
      ###
    this.hideFrameWork=false
    this.explorerPanes=[]
    this.loginAttemptCounter = 1


  openWorkSpaceFromUrl: ->
    # we have to set this here to avoid the tree and menu is shown if hideFramework is true
    ComponentHub.activePlugin =  globalThis.loadedPlugins[this.name]
    queryString = window.location.search
    urlParams = new URLSearchParams(queryString)
    wsConfig =
      workspacename: urlParams.get('workspacename') || this.name
      host:urlParams.get('host') || window.location.origin
      api:urlParams.get('api') || '/repository/api/v1'
      repo:urlParams.get('repo') || '/repository/repository'
      root:urlParams.get('root') || ''
      user:urlParams.get('username') || ''
    this.openWorkSpaceFromConfig wsConfig

  ###* Mandatory for Plugins. Creates and opens a workspace on the fly
   * @description This is mandatory for all PluginClasses
   * Opens the workspace. If a plugin should not open, just overwrite with an no op
   * @param {object} wsConfig
   * @return {boolean}
   ###
  openWorkSpaceFromConfig: (wsConfig)->
    wsName = wsConfig.workspacename
    if wsName is ''
      alert "The workspace definition contains no name. Can't load incomplete workspace definition"
      return false
    else
      workspace = await ComponentHub.dbGetWorkspace this.name, wsName
      if workspace is null
        wsHost = wsConfig.host
        if wsHost is ''
          alert "The workspace definition contains no host url. Can't load incomplete workspace definition"
          return false
        wsApi = wsConfig.api
        if wsApi is ''
          alert "The workspace definition contains no host api url. Can't load incomplete workspace definition"
          return false
        wsRepo = wsConfig.repo
        if wsRepo is ''
          alert "The workspace definition contains no repo url. Can't load incomplete workspace definition"
          return false
        wsRoot = wsConfig.root
        if wsRoot is ''
          alert "The workspace definition contains no root element. Can't load incomplete workspace definition"
          return false
        template =
          workSpaceType: this.name
          name: wsName
          description: wsName
          favourite:true
          icon: this.icon
          improveHost: wsHost,
          improveApi: wsApi,
          improveRepo: wsRepo,
          rootPath: wsRoot,
          workspaceUrl: "#{window.location.origin}#{window.location.pathname}?plugin=#{this.name}&wksType=#{this.name}&workspacename=#{wsName}&host=#{wsHost}&api=#{wsApi}&repo=#{wsRepo}&root=#{wsRoot}"
          username: ''
          ssPassword: ''
          saveSSPassword: true
        if wsConfig.hasOwnProperty "user"
          if wsConfig.user.length
            username = wsConfig.user
        else
          username= await ComponentHub.PageIndex.prompt('Login',  "Enter your Username for #{wsHost} (Minimum 3 characters)" ,{
            model: '',
            isValid: (val) => val.length > 2,
            type: 'text'}
          )
        if username
          template.username = username
        else
          alert "Can not proceed without a valid username. Sorry"
          return false
        if wsConfig.hasOwnProperty "password"
          if wsConfig.password.length
            password = wsConfig.password
        else
          globalUserPWD = ComponentHub.dbGetGlobalPwdForUser wsHost, username
          if globalUserPWD?
            password = globalUserPWD
          else
            password= await ComponentHub.PageIndex.prompt('Login',  "Enter your Password for #{username} (Minimum 3 characters)" ,(
              model: '',
              isValid: (val) => val.length > 2,
              type: 'password')
            )
        if password
          template.ssPassword = password
        else
          alert "Can not proceed without a password. Sorry"
          return false
        this.workspaceFile = new LSSettingsFileObject this.name, template, this.workSpaceSchema()
        this.workspaceFile.displayName =  template.name
        this.workspace = this.workspaceFile.model
        await ComponentHub.dbSetWorkspace this.name.toLowerCase(), wsName, template
        indexFile = await ComponentHub.dbGetIndex this.name, wsName
    ComponentHub.PageIndex.mainNavigation='explorer'
    ComponentHub.PageIndex.onWorkSpaceFileOpened wsName,this.name






  ###* Mandatory for Plugins. Opens the workspace
   * @description This is mandatory for all PluginClasses
   * Opens the workspace. If a plugin should not open, just overwrite with an no op
   * @param {string} workspaceName
   * @return {boolean}
   ###
  openWorkSpace: (workspaceName) ->
    this.folders = {}
    this.workspaceName = workspaceName
    this.workspace = await ComponentHub.dbGetWorkspace this.name, workspaceName
    #Remeber the password Save Setting for this workspace in case the user need more attempts to restore it later
    initialPasswordSetting = this.workspace.saveSSPassword
    index = await ComponentHub.dbGetIndex this.name, workspaceName
    if this.loginAttemptCounter > 1
      #If we are here with a second attempt, then reset the password after we got the workspace fome ComponentHub above
      this.workspace.saveSSPassword = false
    usernameMsg = "Enter your Username"
    if this.loginAttemptCounter > 3
      # User tried 3 times a wrong password, reset the username to give him a chance to correct an evt. wrong username
      this.workspace.username = ""
      this.loginAttemptCounter = 1
      usernameMsg = "You tried 3 times with a wrong password. Enter your Username"

    if !this.workspace.username.length
      username= await ComponentHub.PageIndex.prompt('Login', usernameMsg ,{
        model: '',
        isValid: (val) => val.length > 2,
        type: 'text'}
      )
      if username
        this.workspace.username = username
      else
        alert "Can not proceed without a valid username. Sorry"
        return false
    if !this.workspace.saveSSPassword or this.workspace.ssPassword.length is 0
      password= await ComponentHub.PageIndex.prompt('Login',  "Enter your Password for #{this.workspace.username} (Attempt: #{this.loginAttemptCounter} / 3)" ,{
        model: '',
        isValid: (val) => val.length > 2,
        type: 'password'}
      )
      if password
        this.workspace.ssPassword = password
      else
        return false
    this.workspaceFile = new LSSettingsFileObject this.name, this.workspace, this.workSpaceSchema()
    this.workspaceFile.displayName = this.workspaceName
    if this.workspace is Object({})
      alert "The workspace File contains no definition. (is empty or corrupt))"
      ComponentHub.activeWorkspace  = null
      return false
    loggedIn = await this.login()
    if loggedIn
      ComponentHub.activeWorkspace  = this.workspace
      if password?
        # Store only if user was forced to enter a password. Not necessary when opened regular
        await ComponentHub.dbLoginAllWorkspaces this.workspace.improveHost, this.workspace.username, password
      if this.loginAttemptCounter > 1
        # User tried more then 1 times but finally gots logged in, save the workspace to ensure the username and password is saved again
        this.workspace.saveSSPassword = initialPasswordSetting
        await ComponentHub.dbSetWorkspace this.name.toLowerCase(), this.workspaceName, this.workspace,false
        # do not use this.workspace.ssPassword next line, since this became encryted in dbSetWorkspace
        await ComponentHub.dbLoginAllWorkspaces this.workspace.improveHost, this.workspace.username, password
        this.loginAttemptCounter = 1
      return true
    ComponentHub.activeWorkspace  = null
    # Count up
    this.loginAttemptCounter += 1
    # And do not save the wrong password
    this.workspace.saveSSPassword = false
    # We didn't return yet, so login failed
    return this.openWorkSpace workspaceName

  logout: ->
    answer = await ComponentHub.PageIndex.confirm 'Logout', "Are you sure you want to logout?"
    if answer
      user = this.workspace.username
      this.workspace.ssPassword = ''
      await ComponentHub.dbSetWorkspace this.name.toLowerCase(), this.workspaceName, this.workspace
      #ComponentHub.PageIndex.onWorkSpaceFileOpened this.workspaceName,this.name
      await ComponentHub.dbLogoutAllWorkspaces this.workspace.improveHost, user
      ComponentHub.ConnectionStatusLine.setLoginState(false)
      ComponentHub.resetLayout()
      ComponentHub.PageIndex.mainNavigation = "workspaces"
      ComponentHub.PageIndex.onMainTabClicked "workspaces"


  login: ->
    if this.workspace? and this.checkForValidWorkspaces()
      initApi this.workspace
      ComponentHub.activeWorkspace  = this.workspace
      ComponentHub.activeServer = new Server(this.workspace)
      this.server = ComponentHub.activeServer
      loggedIn = await this.server.login this.workspace.username, this.workspace.ssPassword
      if loggedIn
        return this.loadInitialData()
      else
        if this.server.online
          alert "Could not log you in. It seems the given credentials are wrong."
        else if this.server.onlineIndicator is "red"
          alert "Could not log you in. It seems the server is not reachable or reports an internal error."
        else
          alert "Could not log you in. Can't determine the reason why. Please contact your Administrator!"
    return false

  loadInitialData: ->
    if this.server.userdata.FullWebAccess
      this.hideFrameWork=false
    newRoot = this.workspace.rootPath
    if this.name isnt "improveweb" and newRoot is "/" and !this.server.userdata.FullWebAccess
      alert "You are not allowed to access the root folder. Sorry!"
      return false
    rootKey = "0"
    # This allows to add more then one path or resourceId delimited by colon to the rootPathFiled
    multiRoot = this.workspace.rootPath.split('::')
    if multiRoot.length > 1
      for rootPath in multiRoot
        if rootPath.trim() is '/'
          alert "It is not possible to configure multiple root paths when one of them is '/'"
          return false
    i = 0
    # in case root is /, we only accept /, multiple roots would be nonsense
    if newRoot isnt '/'
      # must determine the parent of our start to initialize Browser
      start = await this.fetchRoot multiRoot[i].trim()
      if start
        newRoot = start.path
        rootKey = start._treeParentId
      else
        if multiRoot.length > 1
          i +=1
          while i < multiRoot.length
            start = await this.fetchRoot multiRoot[i].trim()
            if start
              newRoot = start.path
              rootKey = start._treeParentId
              break
            i +=1
          if not start
            # none of the given resourceIds or paths could be loaded. since this might be a authorizatzion problem, we return false
            return false
    ComponentHub.FileSystemTree.onRootDirChanged newRoot, rootKey
    #this.server.onReady()
    await this.fetchRoot newRoot
    this.server.onReady()
    if multiRoot.length > 1
      i +=1
      while i < multiRoot.length
        if multiRoot[i][1].trim() is '/'
          await this.server.fetchInToVirtualRoot apiGetResourceByPath multiRoot[i].trim(),true
        else
          await this.server.fetchInToVirtualRoot apiGetResourceWithMeta multiRoot[i].trim()
        i += 1
    return true

  ###
    fetches children of given resource
    @param {String} folder Is either  a path, root (/) or a resourceId
    @return {Array}  The contents of the given resource
    ###
  fetchRoot: (folder) ->
    folder=folder.trim()
    if folder is '/'   #root is requested
      result = this.server.fetchData apiGetRootDirWithMeta()
    else if folder[...1] is '/'  # starts with / so a path is requested
      #result = this.server.fetchData apiGetResourceByPath folder
      result = this.server.fetchInToVirtualRoot apiGetResourceByPath folder, true
    else  # a resourceId is given, so folder is like DE1B235E784C45F2B5BD74E14D2F0678
      #result = this.server.fetchData apiGetResource(folder)
      result = this.server.fetchInToVirtualRoot apiGetResourceWithMeta(folder)
    return result

  ###* Mandatory for Plugins. Returns an empty settings template for workspace definition
    * @return {object}
    ###
  workSpaceTemplate: ->
    template =
      workSpaceType: this.name
      name: "New #{this.name} Workspace"
      description: "My shiny new #{this.name} Workspace"
      favourite:false
      icon: this.icon
      improveHost: "https://repo-a.env30.scintecodev.com:8443",
      improveApi: "/repository/api/v1",
      improveRepo: "/repository/repository",
      rootPath: "/",
      workspaceUrl:"",
      username: "",
      ssPassword: "",
      saveSSPassword: true
    if this.isDesktop
      template.localPath = ""

    return template

  ###* Mandatory for Plugins. Retuns the schema for workspace settings
   * @return {object}
   ###
  workSpaceSchema: ->
    schema=[
        {
          id: "name",
          label: "Workspace Name",
          subLabel: "Give Your new Workspace a name",
          component: "QInput",
          dense: true,
          rounded: true,
          standout: true,
          clearable:true,
          span: "100%",
          required: true
        },
        {
          id: "description",
          label: "Short description",
          subLabel: "The short description will show up in the workspace list.",
          component: "QInput",
          dense: true,
          rounded: true,
          standout: true,
          clearable:true,
          span: "100%"
        },
        {
          id: "favourite",
          component: "QToggle",
          type: "checkbox",
          span: 1,
          dense: true,
          label: "Favourite",
          subLabel: "When checked, this will show up in the favourite sections in the workspace list",
          defaultValue: false
        },
        {
          label: "Connection",
          subLabel: "Enter your improve connection information here. Change ImproveAPI and Improve repo only if you know what you are doing or if you gotinstructions from your admin to do so."
        },
        {
          id: "improveHost",
          subLabel: "The url of your improve repository",
          label: "Hostname and port",
          component: "QInput",
          type:"url" ,
          dense: true,
          rounded: true,
          standout: true,
          clearable:true,
          span:"100%",
          hint:"https://repo-a.env31.scintecodev.com:8443",
          required: true
        },
        {
          id: "improveApi",
          label: "Api Path",
          subLabel: "The base url of the API inside your Improve Host. Normally you must not change the default",
          component: "QInput",
          dense: true,
          rounded: true,
          standout: true,
          clearable:true,
          span:"100%",
          hint: "Default: /repository/api/v1",
          required: true

        },
        {
          id: "rootPath",
          label: "Root Directory",
          subLabel: "Enter an Improve Path or resourceId of the folder where to start",
          component: "QInput",
          dense: true,
          rounded: true,
          standout: true,
          clearable:true,
          span:"100%",
          hint: "Default: /",
          required: true

        },
        {
          id: "workspaceUrl",
          label: "WorkspaceUrl",
          subLabel: "This is the url for the workspace to share",
          component: "QInput",
          dense: true,
          rounded: true,
          readonly: true,
          clearable:false,
          span:"100%",
          hint: "Appears after saving and successful opening",
          required: false

        },
        {
          id: "username",
          label: "User Name",
          subLabel: "Your username there",
          component: "QInput",
          dense: true,
          rounded: true,
          standout: true,
          clearable:true,
          span:"50%",
          hint: "Be careful with uppercase/lowercase",
          required: true

        },
        {
          id: "ssPassword",
          label: "Your Password",
          subLabel: "Your password",
          component: "QInput",
          type:"password" ,
          dense: true,
          rounded: true,
          standout: true,
          clearable:true,
          span:"50%",
          hint: "Keep it secret ;)",
        },
        {
          id: "saveSSPassword",
          component: "QToggle",
          type: "checkbox",
          span: 1,
          dense: true,
          label: "Save Password",
          subLabel: "When checked, the password is securly saved and you do not have to login when opening this workspace",
          defaultValue: true,
          showCondition: (val, { formData }) => formData.ssPassword?.length,
      },
      ]
    if this.isDesktop
      localPath ={
        id: "localPath",
        label: "Path for local Downloads",
        subLabel: "An absolut folder path. Use double backslash (\\) in windows.",
        component: "QInput",
        dense: true,
        rounded: true,
        standout: true,
        clearable:true,
        hint: "DoubleClick me for Folder Select Dialog",
        events: {
          dblclick: (evt,{updateField}) =>
            files = ComponentHub.AppBridge.openFolder()
            if files and files.length
              updateField({ id: 'localPath', value: files[0] })
        }
      }
      schema = [schema[0..-4]..., localPath, schema[-3..]...]
    return schema


###
"improveHost": "https://repo-a.env31.scintecodev.com:8443",
  "improveApi": "/repository/api/v1",
  "improveRepo": "/repository/repository",
###



export {Improveweb as PluginMain}
