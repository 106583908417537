import CompositionBase from 'core/CompositionBase.coffee'
import {improveApiUrl, tokenAuth,apiGetSubTree} from 'apis/improveREST/rawImprove.coffee'
import {tableSort} from 'components/FileSystemBrowser/treesorting.coffee'
###* Props definition
  * @typedef {Object}  FolderviewerProps
  * @property {string} instancename - Our Instancename for registration in ComponentHub
  ###


###*
* @augments CompositionBase
###
export class Folderviewer extends CompositionBase
  ###* virtual Field (Getter) returns the current url*
    * @name Folderviewer#apiUrl
    * @type {string} apiUrl
    ###
  Object.defineProperty this::, "apiUrl",
    get: -> return improveApiUrl
    enumerable: true
    configurable: true



  ###*
    * @param {Object} options
    * @property {FolderviewerProps} props
    * @property {Function} emit
    * @property {object} refs
    * @property {object} quasar
    * @constructor
    ###
  constructor: (options) ->
    super(options)
    this.resource=options.props.resource
    this.uploader=options.refs.uploader
    this.quasar=options.quasar
    this.icon= 'mdi-folder-outline'
    this.sortAlias= {}
    this.newFileNames=[]
    this.myKids=[]
    this.showContent= false
    this.columns =  [
        { name: 'resourceId', label: 'ResourceId', field: 'resourceId', sortable: true,align: 'left' },
        { name: 'name', label: 'Filename', field: 'name', sortable: true,align: 'left' },
        { name: 'fileSizeFmt', label: 'Size', field: 'fileSizeFmt', sortable: true,sortfield: 'size',align: 'left' ,style: 'width: 150px'  },
        { name: 'lastModifiedFmt', label: 'Modified', field: 'lastModifiedFmt', sortable: true,sortfield: 'lastModified',align: 'left' ,style: 'width: 150px' },
        { name: 'createdAtFmt', label: 'Created', field: 'createdAtFmt', sortable: true,sortfield: 'createdAt',align: 'left' ,style: 'width: 150px' },
        { name: 'callDel', label: 'Delete', field: 'callDel', sortable: true,align: 'left' ,style: 'width: 50px' },
        { name: 'callLock', label: 'Lock', field: 'callLock', sortable: true,align: 'center' ,style: 'width: 50px'  }

      ]
    this.filter = ''
    this.pagination={sortBy:'name',descending:false,page:1,rowsPerPage:20}
    this.tdata = []

  loadFolder: ->
    this.tdata=[]
    await this.ComponentHub.refreshFolder this.resource.resourceId
    datafiles = this.ComponentHub.FileSystemTree.getAllChildren this.resource.resourceId
    for file in datafiles
      filedata = file
        #resourceId: file.resourceId,
        #name: file.name,
        #modified:file.lastModifiedFmt,
        #size: file.fileSizeFmt,
        #del: "🗑",
      filedata['callDel'] = "🗑"
      filedata["callLock"] = if file.isLocked then "🔐" else "🔓"
      this.tdata.push filedata

  factoryFn: (files) ->
    ta = await tokenAuth()
    header = [{name:"Authorization", value:ta}]
    for file in files
      if @newFileNames.indexOf(file.name) >= 0
        return {method: 'POST',  url: "#{@apiUrl}/resources/#{@resource.resourceId}?comment=improveWeb",headers:header}
      else
        toUpdate = (resource.resourceId for resource in @myKids when resource.name is file.name)
        return {method: 'PUT', url: "#{@apiUrl}/resources/#{toUpdate}/content?comment=improveWeb",headers:header}

  onAdded:(files)->
    # helper to compare resources in sub arrays
    comparer = (otherArray) =>
      (current) =>
        otherArray.filter((other) =>
          other['name'] is current['name']
        ).length is  0

    myKidIds = this.ComponentHub.FileSystemTree.findChildren @resource.resourceId, false
    @myKids = this.ComponentHub.FileSystemTree.findResourceByIds myKidIds
    newFiles = files.filter(comparer(@myKids))
    for newFile in newFiles
      @newFileNames.push(newFile.name)

  onFailed: (info) ->
    this.quasar.notify({
      message: 'Some Files could not be uploaded!',
      color: 'red'
      position: 'center'
    })

  noop: ->

  onFinish:()->
    this.uploader.removeUploadedFiles()
    this.newFileNames=[]
    await this.ComponentHub.activeServer.fetchData  apiGetSubTree( this.resource.resourceId)
    this.loadFolder()

  onRowClick: (row,colname,evt) ->
    res = this.ComponentHub.getResourceFromTree row.resourceId
    switch colname
      when 'callLock'
        await res.lock(!res.isLocked)
        this.loadFolder()
      when 'callDel'
        await res.delete()
        this.loadFolder()
      when 'name'
        this.ComponentHub.FileSystemTree.onRowClick 0,res,0,null

  ###*
    * # TableSort
    * Sorts the table levelwise. The sorting happens only within one hierarchy level
    * called by the table widget. This method takes care of hierarchy and expanded states of the tree
    * @param {Array} rows all rows to be sorted Array of ImproveResources
    * @param {string} sortBy he column to sort after
    * @param {boolean} descending true for descending, false for ascending
    * @return {Array} the sorted array
    ###
  tableSort: (rows, sortBy, descending) ->
    return tableSort rows, sortBy, descending, this.sortAlias


  ###* must be called when mounted.
    * creates a map containing those colnames which should be sorted by other cols
    * when a displaycolumn contains a sortfield property, this column will be sorted by that field instead by itself
    * useful when a column is displayed but the sorting should be done by another, not displayed column instead
    ###
  sortAliases: ->
    this.sortAlias = {}
    replaces = (col for col in this.columns when col.hasOwnProperty('sortfield'))
    for col in replaces
      this.sortAlias[col.name] = col.sortfield
    return

  mounted: ->
    super()
    this.sortAliases()
    this.loadFolder()
    this.icon = await this.resource.getDisplayableFromContent()
    this.showContent= true
