###*
* @module components/newFile/newFileDialog
###
import {PandoraProject} from '../PandoraProject.coffee'
import ComponentHub from 'core/componentsHub.coffee'


export default
  name: 'newFileDialog',
  props:
    instancename:
      default: 'newFileDialog'
      type: String
    parent:
      default: null
      type: Object


  data: ->
    directory: ''
    ics:''
    fileName:''
    flags:[]
    firstLine:''
    project:null
    dimension:null

  watch:
    parent: (newVal,oldVal) ->
      if newVal.kind is "file"
        @directory = newVal.parent
      else
        @directory = newVal.path
      @groups()
    directory: (newVal,oldVal) ->
      @groups()
      @$emit("changed", {newFileName: "#{@directory}/#{@ics} #{@fileName}", definition:@firstLine})
    fileName: (newVal,oldVal) ->
      @$emit("changed", {newFileName: "#{@directory}/#{@ics} #{@fileName}", definition:@firstLine})
    ics: (newVal,oldVal) ->
      @$emit("changed", {newFileName: "#{@directory}/#{@ics} #{@fileName}", definition:@firstLine})
    firstLine: (newVal,oldVal) ->
      @$emit("changed", {newFileName: "#{@directory}/#{@ics} #{@fileName}", definition:@firstLine})


  computed:
    getFirstLine: ->
      @firstLine = ''
      unless @dimension
        @getProjectMetaData()
      for group of @dimension
        if !@dimension[group].assignByFolder
          index = @flags.findIndex((x) -> x.column is group)
          if index >= 0
            if @flags[index].set.length
              @firstLine = @firstLine + @flags[index].set + ', '

      if @firstLine.length
        @firstLine = "DimensionValues: " + @firstLine[0..-3]
      return @firstLine




  methods:
    getProjectMetaData: ->
      projectName = this.parent.path.split("/")[1]
      @definition = globalThis.loadedPlugins.pandora.folders[projectName].definition
      @project = globalThis.loadedPlugins.pandora.folders[projectName]
      @dimension =  @definition.dimension




    validate: ->
      @$refs.pathRef.validate()
      @$refs.icsRef.validate()
      @$refs.nameRef.validate()
      if @$refs.pathRef.hasError || @$refs.icsRef.hasError || @$refs.nameRef.hasError
        @$emit("invalidated",false)
      else
        @$emit("validated",true)


    groups: ->
      @flags = []
      unless @dimension
        @getProjectMetaData()
      for group of @dimension
        groupValues=[]
        set=''
        for subKey of @dimension[group].values
          ro=false
          if @dimension[group].assignByFolder
            ro=true
            try
              if @directory.split("/").indexOf(subKey) >= 0
                set=subKey
            catch e
              return
          groupValues.push {label:subKey,ro:ro}
        @flags.push {column:group,set:set, rows:groupValues}

  mounted: ->
    ComponentHub[this.instancename] = this
    if @parent?
      unless @dimension
        @getProjectMetaData()
      if @parent.kind is "file"
        @directory = @parent.parent
      else
        @directory = @parent.path
      @groups()
      projectName = this.parent.path.split("/")[1]
      @ics=await globalThis.loadedPlugins.pandora.folders[projectName].createUUID()
      @validate()




  unmounted: ->
    delete ComponentHub[this.instancename]
