import CompositionBase from 'core/CompositionBase.coffee'
###* Props definition
  * @typedef {Object}  TemplateselectorProps
  * @property {string} instancename - Our Instancename for registration in ComponentHub
  ###


###*
* @augments CompositionBase
###
export class Templateselector extends CompositionBase
  ###*
    * @param {Object} options
    * @property {TemplateselectorProps} props
    * @property {Function} emit
    * @property {object} refs
    * @property {object} quasar
    * @constructor
    ###
  constructor: (options) ->
    super(options)
    this.templates=options.props.templates
    this.emit=options.emit
    this.columns = [

      {name: 'name', label: 'Template', field: 'name', sortable: true, align: 'left'},
      {name: 'version', label: 'Version', field: 'version', sortable: true, align: 'left'},
      #{name: 'dsType', label: 'Report Type', field: 'dsType', sortable: true, align: 'left'},
      {name: 'dsName', label: 'Data Source', field: 'dsName', sortable: true, align: 'left'},
      {name: 'dsFileType', label: 'File Type', field: 'dsFileType', sortable: true, align: 'left'},
      {name: 'dsMultiple', label: 'Multiple', field: 'dsMultiple', sortable: true, align: 'center', style:"width:50px"},
      {name: 'folder', label: 'Location', field: 'folder', sortable: true, align: 'left'},
      {name: 'folderId', label: 'Reference ID', field: 'folderId', sortable: true, align: 'left'},
      ]
    this.tdata = []
    this.setTemplates()
    this.selection = []
    this.selectedrows=null
    this.pagination={sortBy:'name',descending:false,page:1,rowsPerPage:0}

  onSelection: (details) ->
    console.log details
    if details.added
      this.emit('selected', details.rows[0])
    else
      this.emit('selected', null)
  setTemplates: ->
    this.tdata=[]
    for template in this.templates

      tmp =
        folder:template.report.folder,
        folderId:template.report.folderId,
        folderResourceId:template.report.folderResourceId,
        name: template.report.Name,
        helpUrl: template.report.helpUrl,
        version: template.report.Version,
        dsType: template.report.DataSource.type,
        dsName: template.report.DataSource.name,
        dsFileType: template.report.DataSource.fileType.join(", "),
        dsMultiple: if template.report.DataSource.multiple then "◉" else "◎"
        reportFolder: template.report.reportFolder
        dataStore: template.report.dataStore
        step: template.report.Step
        emptyReport:template.report.emptyReport
        fields:template.report.fields
        previewStep: template.preview.Step
        previewSwitchLabel: template.preview.switchLabel
        previewSwitchSubLabel: template.preview.switchSubLabel
        previewDefaultOn: template.preview.defaultOn
        previewErrorMessage: template.preview.errorMessage
        mandatories:template.mandatories
        dataSchema:template.dataSchema
        formColumns:template.report.formColumns



      this.tdata.push tmp
