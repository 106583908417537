import {FileSystemFile} from "components/FileSystemBrowser/FileSystemFile.coffee"
import ComponentHub from "core/componentsHub.coffee"
import {PandoraProject} from './PandoraProject.coffee'
###*
  * @augments FileSystemFile
  ###
export class PandoraReqFile extends FileSystemFile
  ###*
    * @constructor
    * @param {FileObject} object
    ###
  constructor: (object) ->
    # Attention: This calls @update() !
    super object
    ###*  - The name of the project we are belonging to
      * @type {string}
     ###
    @projectName = @cachedPath.split("/")[1]
    ###*
      * The project we are belonging to
      * @type {PandoraProject}
      ###
    @project = globalThis.loadedPlugins.pandora.folders[@projectName]
    unless @shortDescription?
      ###* - A short Descrition, here the filename w/o icsXXX
        * @type {string}
        ###
      @shortDescription = ""
    unless @ics?
      ###* - our ics id can be "ics", "pts", etc. should be defined as prefix in requirements.json
        * @type {string}
        ###
      @ics = ''
    unless @displayName?
      ###*  - what should appear on tabs
        * @type {string}
        ###
      @displayName = ''
    unless @definitionLine?
      ###* - Usually the first line of the file
        * @type {string | null}
        ###
      @definitionLine=null
    unless @requirementLine?
    ###* - Usually the first line of the file
      *@type {string | null}
      ###
      @requirementLine=null
    ###* - Are we in edit or preview mode
      *@type {boolean}
      ###
    @editMode = true



  ###* Returns our actions
   * @description This has to ba a function which is called by a property getter, because they have to be recalculated
   * Every time the container which handles this file is focussed
   * @return {FileActionButtons[]}
  ###
  createToolBarActions: ->
    btns=super()
    return [
      {
        name: "ReqFileToggleView"
        disabled: !this.tabPinned
        hidden: !this.tabPinned
        highlight: if this.editMode then false else true
        icon: if this.editMode then 'o_code' else "o_article"
        tooltip: "Toggle Preview"
        click: () => @togglePreviewMode()
      },
      {
        name: "ReqFileBrowseMode"
        disabled: ComponentHub.loadedPlugins.pandora.disableAtAll() and this.tabPinned
        hidden: false
        highlight: if this.tabPinned then false else true
        icon: if this.tabPinned then 'o_explore' else "o_explore_off"
        tooltip: "Toggle Browse Mode"
        click: () => @toggleBrowserMode()
      },
      {
        name: "ReqFileBack"
        disabled: ComponentHub.loadedPlugins.pandora.disableBack()
        hidden: this.tabPinned
        icon: 'o_navigate_before'
        tooltip: ComponentHub.loadedPlugins.pandora.toolTipPrev()
        click: () => ComponentHub.loadedPlugins.pandora.browsePrev()
      },
      {
        name: "ReqFileNext"
        disabled: ComponentHub.loadedPlugins.pandora.disableNext()
        hidden: this.tabPinned
        icon: 'o_navigate_next'
        tooltip: ComponentHub.loadedPlugins.pandora.toolTipNext()
        click: () => ComponentHub.loadedPlugins.pandora.browseNext()
      },
      #
      ###* @ts-ignore ###
      btns...
    ]

  ###*
    * @description toggles edit and preview Mode. In PreviewMode a html preview is shown instead of references
    ###
  togglePreviewMode: ->
    this.editMode=!this.editMode
    return
  ###*
    * @description toggles browser Mode. In BrowserMode, we also have to unpin the Tab
    * from this moment on, the tab is just used as container for contents which are eventually not longer related
    * to this file
    ###
  toggleBrowserMode: ->
    this.tabPinned = !this.tabPinned
    if this.tabPinned
      ComponentHub.loadedPlugins.pandora.resetBrowserStack()
      ComponentHub.PageIndex.showRightPane=true
      if ComponentHub.PageIndex.rightPaneSplitter is 0
        ComponentHub.PageIndex.rightPaneSplitter = 40
    else
      ComponentHub.PageIndex.showRightPane=false

  ###*
    * Updates ourself with fresh data
    * @param {FileObject | Object} rawData
    ###
  update: (rawData) ->
    super rawData
    @projectName = @cachedPath.split("/")[1]
    @project = globalThis.loadedPlugins.pandora.folders[@projectName]
    @getNameAndHeaders()
    return

  ###* Initializes the FileObject with Pandora specific stuff ###
  getNameAndHeaders: ->
    if !@isDir
      @ics = ""
      for prefix in this.project.prefix
        if @name.startsWith(prefix)
          @ics = @name[..@name.indexOf(" ")-1]
          ###* @ts-ignore ###
          @shortDescription = @name[@name.indexOf(" ")..name.indexOf('.req')]
          break
      if @ics is ""
        @ics = @name
        @shortDescription = @name
      @displayName = @ics
      @findHeader()
      @reindex()
      return


  ###* Finds the two header lines
    * Dimensions (line1)
    * Requirements (line2)
    ###
  findHeader: ->
    if @content?
      #if @ics is "ics540"
      #  debugger
      @requirementLine=''
      splitted = @content.toString().split('\n\n')
      if splitted.length
        header = splitted[0]
        hsplitted = header.split('\n')
        definitionLine = hsplitted[0]
        if definitionLine and definitionLine.indexOf(":") >= 0
          @definitionLine = definitionLine[definitionLine.indexOf(":")+1..]
        if hsplitted.length is 2
          requirementLine = hsplitted[1]
          if requirementLine and requirementLine.indexOf(":") >= 0
            @requirementLine = requirementLine[requirementLine.indexOf(":")+1..].trim()
    return



  ###* Saves the file back to FS with its current content
    * **Important** Never save back binary files !!
    ###
  save: () ->
    super.save()
    @update {content: @content}
    return

  ###* Computes the wole declaration String for this file
      * @return {string}
    ###
  getDeclaration: ->
    #
    ###*
     * @param {object} cacheVal
     * @return {string}
    ###
    buildLine = (cacheVal) ->
      retval = []
      for key of cacheVal
        if cacheVal[key].length
          retval.push  "#{key} (#{cacheVal[key].join(', ')})"
      return retval.join(", ")

    cache = {}
    retval = []
    examinePath = @path.split("/")[2..-2]
    for segment in examinePath
      if !cache.hasOwnProperty(segment)
        cache[segment] = []
      ret = @project.declarationByName "Dimension", "Values",segment,true
      for val in ret
        if cache[segment].indexOf(val) < 0
          cache[segment].push val

    if @content and @firstLine
      # now firstLine is like: DimensionValues: UserRequirements, Run-Server
      fl = @firstLine
      splitted= fl.split(':')
      if splitted.length > 1
        metaKey = splitted[0]
        valueKeys = splitted[1].split(",")
        # now metakey = "DimensionValues"  and valueKeys = ["UserRequirements", "Run-Server"] or ["single"]
        metaKeySplitted = metaKey.replace(/([a-z0-9])([A-Z])/g, '$1 $2').split(' ')
        # metaKeySplitted is now ["Dimension", "Values"]
        if metaKeySplitted.length > 1
          for vKey in valueKeys
            if !cache.hasOwnProperty(vKey.trim())
              cache[vKey.trim()] = []
            ret = @project.declarationByName metaKeySplitted[0],metaKeySplitted[1],vKey.trim()
            for val in ret
              if cache[vKey.trim()].indexOf(val) < 0
                cache[vKey.trim()].push val

    return buildLine(cache)

  ###
    getReferencedBy:  ->
      @referencedBy = (entry for entry in globalProjects[@projectName].entries when entry.className is "PandoraReqFile" and  entry.references.indexOf(@ics) >= 0)

    getReferences: ->
      regex = /([#])\w+/gi
      if @content
        refs = []
        while result = regex.exec(@content)
          refs.push result[0][1..]
        @references =  [...new Set(refs)]
      return @references
    ###

  ###* Return the (Filename without icsxxxx and .req) for the file with the given ics
    * @param {string} ics - the ics to search for
    * @return {string}
    ###
  refNameById: (ics) ->
    if ics.startsWith('#')
      ics = ics[1..]
    refs = (ref.shortDescription for ref in @references when ref.ics is ics)
    if refs.length
      name = refs[0]
      if name.endsWith(".req")
        return name[..-5]
      return name
    return ''


  ###* searches all matrix results
    * TODO: read MAtrix file
    * @return {Array}
    ###
  getMatrixRelations: ->
    matrix = this.project.matrix
    if matrix and Object.keys(matrix).length is 0 and Object.getPrototypeOf(matrix) is Object.prototype
      return []
    ids = []
    result = []
    res = []

    res = ComponentHub.indexer.search(this[matrix.field], { fields: [matrix.lookup] })
    toSearchProject = globalThis.loadedPlugins.pandora.folders[matrix.project]
    for found in res
      if ids.indexOf(found.id) < 0
        file = toSearchProject.docByIcs(found['ics'])
        if file?
          ids.push found.id
          result.push(file)
    return result


###* virtual Field (Getter) returns the firstLine
  * returns the first Line of the File
  * This line contains Dimensions
  * @name PandoraReqFile#firstLine
  * @property {string} firstLine
 ###
Object.defineProperty PandoraReqFile::, "firstLine",
  get: ->
    if @content and @content.length
      splitted = @content.split('\n')
      return splitted[0]
    return ''


###* virtual Field (Getter) returns all references of current req file
  * returns an array of all icsXXX entries in the file content
  * @name PandoraReqFile#references
  * @property {string[]} references
 ###
Object.defineProperty PandoraReqFile::, "references",
  get: ->
    references = []
    regex = /([#])\w+/gi
    if @content
      refs = []
      while result = regex.exec(@content)
        refs.push result[0][1..]
      references =  [...new Set(refs)]
    return references
  enumerable: true
  configurable: true

###* virtual Field (Getter)
  * returns an array of all  files renferencing the current req file
  * @name PandoraReqFile#referencedBy
  * @property {string[]} referencedBy
 ###
Object.defineProperty PandoraReqFile::, "referencedBy",
  get: ->
    return (entry for entry in globalThis.loadedPlugins.pandora.folders[@projectName].entries when entry.mimeType is "text/pandora-requirement" and  entry.references?.indexOf(@ics) >= 0)
  enumerable: true
  configurable: true

###* virtual Field (Getter/Setter)
  * gets or sets BrowserMode via tabPinned
  * @name PandoraReqFile#tabPinned
  * @property {boolean} tabPinned
 ###
Object.defineProperty PandoraReqFile::, "tabPinned",
  get: ->
    return this._tabPinned
  enumerable: true
  configurable: true
  set: (val) ->
    if !val
      ComponentHub.loadedPlugins.pandora.pageBrowsed this
    this._tabPinned = val
