import countapi from 'countapi-js'
import ComponentHub from "core/componentsHub.coffee"
export class PandoraProject
  ###*
    * Property entries
    * @name PandoraProject#entries
    * @typeof {Array.<FileSystemFile>} entries
    ###
  Object.defineProperty this::, "entries",
    get: ->
      (file for file in ComponentHub.FileSystemTree.records when file.cachedPath.startsWith(this.rootPath))
    enumerable: true
    configurable: true


  ###*
    *  Acts like a bracket around a root directory in which requirements.json  is present
    *  Since we do not have any references from the FileSystembrowser to a businesslogic like here
    *  The project itself is created when the first file from a path with including requirements.json
    *  is instantiated
    * @constructor
    * @param {ProjectMetaData} projectMeta
    ###
  constructor: (projectMeta) ->

    ###* Holds the name of the project "specifications" or "requirements"
      * @type {string}
      ###
    @name = projectMeta.projectName
    ###* Holds all ics numbers belonging to this project
      * @type {Array}
      ###
    @allIcsIds = []
    ###* The rootpath of the current project
      * @type {string}
      ###
    @rootPath="/"+ projectMeta.projectName
    ###* This is always "requirements.json"
      * @type {string}
      ###
    @definitionsFileName = projectMeta.requirementFile
    ###* Our current definition
      * @type {object}
      ###
    @definition= projectMeta.definition
    ###* This describes the files prefix, ics, pts etc.
      * @type {string}
      ###
    @prefix=projectMeta.projectKey
    ###* This holds the matrix information
      * @type {object}
      ###
    ###* This is always "matrix.json" or null
      * @type {string | null}
      ###
    @matrixFileName = projectMeta.matrixFile
    @matrix = {}
    if @matrixFileName?
      @matrix = JSON.parse(ComponentHub.FS.osReadFile(@matrixFileName))





  ###*
    * Gets a unique ics number
    * I registered a namespace at https://countapi.xyz
    * calling countapi.hit increases the current counter and returns it
    * @example
    *
    *   https://countapi.xyz
    *   https://www.npmjs.com/package/countapi-js
    *   create
    *   https://api.countapi.xyz/create?namespace=pandora.scinteco.com&key=pts&value=100
    *   set (reset) with
    *   https://api.countapi.xyz/set/pandora.scinteco.com/req?value=1004
    *   {"namespace":"pandora.scinteco.com","key":"req","value":1004}
    *   NOTE: countapi.hit('pandora.scinteco.com', @prefix) will create a new one if it does not exist yet
    * @return {string} Description
    ###
  createUUID: ->
    result = await countapi.hit('pandora.scinteco.com', @prefix)
    return @prefix + result.value



  ###* Returns the Definitions for this Folder Tree
    * @return {object}
    ###
  getDefinition: ->
    definitionsFile = ComponentHub.FileSystemTree.findResourceByPath(@definitionsFileName)
    definition = definitionsFile.getContent()
    try
      @definition = JSON.parse(definition)
      return @definition
    catch err
      alert err
      return {}


  ###* Get a given declaration by a given filename incl. path
    * @param {string} metaKey - Like 'Dimension'
    * @param {string} subKey - Like 'Values'
    * @param {string} name - A Folder name or part of the path hierarchy
    * @param {boolean=} isDir - Whether the given segment in name is a Folder
    * @return {Array}
    ###
  declarationByName: (metaKey,subKey,name,isDir=false) ->
    unless @definition
      @getDefinition()
    metaKey = metaKey.toLowerCase()
    subKey = subKey.toLowerCase()
    ret = []
    if @definition.hasOwnProperty(metaKey)
      dim = @definition[metaKey]
      for key of dim
        if (dim[key].assignByFolder and isDir) or !isDir
          if dim[key].hasOwnProperty(subKey)
            if dim[key][subKey].hasOwnProperty(name.trim())
              ret.push key
      return ret
    return ["undef"]


  getMatrixTree: ->
    tree = []
    reqFiles = (file for file in ComponentHub.FileSystemTree.records when file.cachedPath.startsWith(this.rootPath) and file.mimeType is 'text/pandora-requirement')
    for file in reqFiles
      branch =
        key: "#{file.ics}:#{file.ics}"  # key has to be unique
        label: file.name[..-5]
        projectName:file.projectName
        tlevel:1
        children: []
        icon: 'mdi-file-document-outline'
      matrixFiles = file.getMatrixRelations()
      for mfile in matrixFiles
        entry =
          label: mfile.name[..-5]
          key: "#{file.ics}:#{mfile.ics}"  # key has to be unique
          projectName:mfile.projectName
          tlevel:2
          parent: file.name[..-5]
          icon: 'mdi-file-document-outline'
        branch.children.push entry
      tree.push branch
    return tree


  ###* Retruns the filename of a given icsNumber
    * @param {string} ics - The icsNumber like #isc0001 or ics0001
    * @return {string}
    ###
  refNameById: (ics) ->
    if ics.startsWith('#')
      ics = ics[1..]
    #entryList = (entry for entry in @entries when entry.mimeType is "text/pandora-requirement" and entry.ics.indexOf(ics) >= 0)
    entryList = (entry for entry in @entries when entry.mimeType is "text/pandora-requirement" and entry.ics is ics)
    if entryList.length
      pandoraDoc = entryList[0]
      return pandoraDoc.shortDescription
    return ''


  ###* Retruns the filename of a given icsNumber
    * @param {string} ics
    * @return {PandoraReqFile | null}
    ###
  docByIcs:(ics) ->
    #entryList = (entry for entry in @entries when entry.mimeType is "text/pandora-requirement" and entry.ics.indexOf(ics) >= 0)
    entryList = (entry for entry in @entries when entry.mimeType is "text/pandora-requirement" and entry.ics is ics)
    if entryList.length
      pandoraDoc = entryList[0]
      pandoraDoc.onDeepLoad()
      return pandoraDoc
    return null

  ###* Gets all icsNumbers of the current Project
    * @return {Array}
    ###
  allIcs: ->
    if @allIcsIds.length is 0
      allDocs = (doc for doc in @entries when doc.ics.length > 0)
      @allIcsIds = ({text: "\##{doc.ics}", displayText: "#{doc.ics} - #{doc.shortDescription[..-5]}"} for doc in allDocs when doc.className is "PandoraReqFile" and doc.ics.length > 0)
    return @allIcsIds

  ###* Creates a new Requirements File
    * @param {object} params
    * @param {string} params.definition
    * @param {string} params.newFileName
    ###
  createNewReqFile: (params)->
    content = params.definition + '\n\n'
    filename = params.newFileName
    if !filename.toLowerCase().endsWith('.req')
      filename = filename + '.req'
    improveFS.writeFile(filename,content, 'utf-8')
    return


  ###* Tells whether the given path belongs to this project
    * @param {string} path
    * @return {boolean}
    ###
  hasRecord:(path) ->
    return if this.entries.findIndex((x) -> x.path is path) >= 0 then true else false

