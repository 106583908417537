import CompositionBase from 'core/CompositionBase.coffee'
###* Props definition
  * @typedef {Object}  RevisionbarProps
  * @property {string} instancename - Our Instancename for registration in ComponentHub
  ###


###*
* @augments CompositionBase
###
export class Revisionbar extends CompositionBase

  ###* virtual Field (Getter) returns the current Editor*
    * when the file is opened the editor sets this property to itself
    * @name Revisionbar#getResourceVersion
    * @type {string}
    ###
  Object.defineProperty this::, "getResourceVersion",
    get: ->
      if this.resource?
        return this.resource.getHumanReadableVersion()
      return ''
    enumerable: true
    configurable: true
  #endregion Getters

  ###*
    * @param {Object} options
    * @property {RevisionbarProps} props
    * @property {Function} emit
    * @property {object} refs
    * @property {object} quasar
    * @constructor
    ###
  constructor: (options) ->
    super(options)
    this.model= null
    this.cmodel= null
    this.revisions= []
    this.compare= false
    this.resource=options.props.resource
    this.emit=options.emit
    this.tabDummy = ""

  onNewResource:  ->
    revisions = this.resource.revisionList
    for rev in revisions
      #Add a pinned and compare flag for selection
      rev.view_pinned = false
      rev._view_compare = false
    if revisions.length
      revisions[0].view_pinned = true
      this.tabDummy=revisions[0].resourceVersionId
      for rev in revisions
        if rev.getHumanReadableVersion() is this.resource.getHumanReadableVersion()
          revisions[0].view_pinned = false
          rev.view_pinned = true
          break


    this.revisions = revisions
    this.model = this.resource
    this.cmodel = null
    this.onChanged()


  onClick: (ver) ->
    # User clicked a revision and selected for view
    if ver.view_pinned or ver.view_compare
      return
    for rev in @revisions
      rev.view_pinned = false
    ver.view_pinned = true
    this.model = ver
    this.onChanged()

  onRightClick: (ver) ->
    # User right-clicked a revision and selected for compare
    if ver.view_pinned
      return
    else if ver.view_compare
      ver.view_compare = false
      this.cmodel = null
    else
      for rev in this.revisions
        rev.view_compare = false
      ver.view_compare = true
      this.cmodel = ver
    this.onChanged()


  onChanged: () ->
    this.emit('onRevisionChanged', this.model, this.cmodel)

  getIcon: (ver) ->
    if ver.resourceVersionId is this.tabDummy

      return 'mdi-eye'
    if ver.view_compare
      return 'mdi-eye-plus'
    return ''

  getColor: (ver) ->
    if ver.iWebContext is 'ImproveReview'
      if ver.view_compare then 'purple' else 'secondary'
    else
      if ver.view_compare then 'orange' else 'primary'
  mounted: ->
    super()
    this.onNewResource()
