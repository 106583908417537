import CompositionBase from 'core/CompositionBase.coffee'
import {improveApiUrl, apiSetMetaData, tokenAuth,apiGetSubTree} from 'apis/improveREST/rawImprove.coffee'
import {tableSort} from 'components/FileSystemBrowser/treesorting.coffee'
import { nextTick } from "vue"
import moment from 'moment'

###* Props definition
  * @typedef {Object}  FolderviewerProps
  * @property {string} instancename - Our Instancename for registration in ComponentHub
  ###


###*
* @augments CompositionBase
###
export class ReportFolderviewer extends CompositionBase
  ###* virtual Field (Getter) returns whether we show warning  *
    * @name ReportFolderviewer#showTemplateWarning
    * @type {string} apiUrl
    ###
  Object.defineProperty this::, "showTemplateWarning",
    get: -> return this.resource is null
    enumerable: true
    configurable: true

  ###* virtual Field (Getter) returns the current url*
    * @name ReportFolderviewer#apiUrl
    * @type {string} apiUrl
    ###
  Object.defineProperty this::, "apiUrl",
    get: -> return improveApiUrl
    enumerable: true
    configurable: true

  ###* virtual Field (Getter) gets/sets the resourceId for the highlighted row*
    * @name ReportFolderviewer#highlight
    * @type {string} highlight
    ###
  Object.defineProperty this::, "highlight",
    get: -> return this._highlight
    set: (val)->this._highlight=val
    enumerable: true
    configurable: true

  ###* virtual Field (Getter) may the upload components for revisions bee shown?*
    * @name ReportFolderviewer#showRevisionUpload
    * @type {boolean} showRevisionUpload
    ###
  Object.defineProperty this::, "showRevisionUpload",
    get: ->
      if this.currentSelectedReport?
        if this.currentSelectedReport.isLocked
          if this.currentSelectedReport.mayEditLock()
            return true
          else
            return false
        else
          return true
      return false
    enumerable: true
    configurable: true

  ###* virtual Field (Getter) gets/sets the resourceId for the highlighted row*
    * @name ReportFolderviewer#combinedFilter
    * @type {object} combinedFilter
    ###
  Object.defineProperty this::, "combinedFilter",
    get: ->
      return {
        fullText: this.filter,
        fromDate: this.filterDateFrom,
        toDate: this.filterDateTo,
        locked: this.filterLock
        empty: this.filterOutEmpty
      }
    enumerable: true
    configurable: true


  ###*
    * @param {Object} options
    * @property {FolderviewerProps} props
    * @property {Function} emit
    * @property {object} refs
    * @property {object} quasar
    * @constructor
    ###
  constructor: (options) ->
    super(options)
    this.resource=options.props.resource
    this.uploader=options.refs.uploader
    this.quasar=options.quasar
    this.icon= 'mdi-file-document-outline'
    this.sortAlias= {}
    this.newFileNames=[]
    this.myKids=[]
    this.showContent= false
    this._highlight = ""
    this.highlightedResource = null
    this.columns =  [
        { name: 'pcReportTitle', required: true,label: 'Document Name', field: 'pcReportTitle', sortable: true,align: 'left' },
        { name: 'template', label: 'Template (version)', field: 'template', sortable: true,align: 'left'  },
        { name: 'pred_entity', required: true, label: 'Report ID', field: 'pred_', sortable: true,align: 'left' },
        { name: 'pred_version', label: 'Rev', field: 'pred_version', sortable: true,align: 'left' ,style: 'width: 25px' },
        { name: 'pcReportName', label: 'Report Name', field: 'pcReportName', sortable: true,align: 'left' },
        { name: 'pcReportEmpty', label: 'Empty Report', field: 'pcReportEmpty', sortable: true,align: 'left',style: 'width: 25px' },

        { name: 'pcReportRdr', label: 'RDR', field: 'pcReportRdr', sortable: true,align: 'left'  },

        { name: 'type', label: 'Report Type', field: 'type', sortable: true,align: 'left'  },
        { name: 'source', label: 'Data Source', field: 'source', sortable: true,align: 'left'  },
        { name: 'createdAtFmtRoche', label: 'Creation Date', field: 'createdAtFmtRoche', sortable: true, sortfield: "createdAt"},
        { name: 'createdByName', label: 'Created by', field: 'createdByName', sortable: true,align: 'left'  },
        { name: 'lastModifiedFmtRoche', label: 'Last Modified Date', field: 'lastModifiedFmtRoche', sortable: true, sortfield: "lastModifiedOn"},
        { name: 'lastModifiedByName', label: 'Last Modified by', field: 'lastModifiedByName', sortable: true,align: 'left'  },
        { name: 'callLock', label: 'Locked', field: 'callLock', sortable: false,align: 'left'  ,style: 'width: 25px' },
        { name: 'uploadFile', label: 'Upload', field: 'uploadFile', sortable: false,align: 'center'  ,style: 'width: 25px' },
        { name: 'downloadPackage', label: 'Source', field: 'downloadPackage', sortable: false,align: 'center'  ,style: 'width: 25px' },
        { name: 'callDel', label: "Del", field: 'callDel', sortable: false,align: 'center' ,style: 'width: 25px' },
      ]
    this.revColumns =  [
        { name: 'version', label: 'Version', field: 'version', sortable: true,align: 'left',style: 'width: 125px' },
        { name: 'idm', label: 'IDM Status', field: 'idm', sortable: true,align: 'left' ,style: 'width: 125px' },
        { name: 'downloadVersion', label: "DL", field: 'downloadVersion', sortable: false,align: 'center' ,style: 'width: 25px' },
        { name: 'callDel', label: "Del", field: 'callDel', sortable: false,align: 'center' ,style: 'width: 25px' },
        { name: 'comment', label: 'Comment', field: 'comment', sortable: true,align: 'left'  },
      ]
    this.allowedReportColumns=[
      {name: 'pred_entity',label: 'Report ID'},
      {name: 'pred_version',label: 'Rev'},
      {name: 'pcReportTitle',label: 'Document Name'},
      {name: 'pcReportRdr',label: 'RDR'},
      {name: 'template',label: 'Template (version)'},
      {name: 'createdAtFmtRoche',label: 'Creation Date'},
      {name: 'createdByName',label: 'Created by'},
      {name: 'lastModifiedFmtRoche',label: 'Last Modified Date'},
      {name: 'lastModifiedByName',label: 'lastModifiedByName'},
      {name: 'callLock',label: 'Lock'},
      {name: 'downloadPackage',label: 'Source'},
      {name: 'callDel',label: 'Del'}
      ]
    this.visibleReportColumns=[
            'pred_entity',
            'pcReportTitle',
            'pred_version',
            'template',
            'createdAtFmtRoche',
            'createdByName',
            'lastModifiedFmtRoche',
            'lastModifiedByName',
            'callLock',
            'downloadPackage',
            'callDel'
          ]
    this.visibleRevisionColumns=[
            'version',
            'downloadVersion',
            'callDel',
            'comment']
    this.filter = ''
    this.filterLock = false
    this.filterOutEmpty = true
    this.filterDateFrom = ''
    this.filterDateTo = ''
    this.tdata = []
    this.pagination={sortBy:'PC_REPORT_TITLE',descending:false,page:1,rowsPerPage:0}
    this.revisions=[]
    this.uploadfile= null
    this.uploadcomment = ""
    this.currentSelectedReport = null
    this.revisionsOnlyOneLeft = true


  loadFolder: ->
    reportData=[]
    await this.ComponentHub.refreshFolder this.resource.resourceId
    nextTick =>
      datafiles = this.ComponentHub.FileSystemTree.getAllChildren this.resource.resourceId
      fromDate = 999999999999999
      toDate= 0
      for file in datafiles
        if parseInt(file.created) > toDate then toDate = parseInt(file.created)
        if parseInt(file.created) < fromDate then fromDate = parseInt(file.created)
        title = ''
        rdr = ''
        df=''
        template = ""
        name=''
        empty=false
        hiddenRevisions = []
        if file.hasOwnProperty("metadata")
          for md in file.metadata
            if md.descriptorName is "PC_REPORT_TITLE"
              title = md.textValue
            else if md.descriptorName is "PC_REPORT_NAME"
              name = md.textValue
            else if md.descriptorName is "PC_REPORT_RDR"
              rdr = md.textValue
            else if md.descriptorName is "PC_REPORT_DATA_FOLDER"
              df = md.textValue
            else if md.descriptorName is "PC_REPORT_TEMPLATE_VERSION"
              template = md.textValue
            else if md.descriptorName is "PC_REPORT_HIDDEN_REVISIONS"
              hiddenRevisions.push(md.textValue)
            else if md.descriptorName is "PC_REPORT_EMPTY"
              empty = md.textValue is "TRUE"
        else
          title = "Broken or no metadata !!"
          rdr = "Broken or no metadata !!"
        filedata = file
        filedata["pred_entity"] = file.entityId.split(":")[-1..][0]
        filedata["pred_version"] = file.entityVersionId.split("-")[-1..][0]
        filedata['createdAtFmtRoche'] = moment(parseInt(file.createdAt)).format("YYYY/MM/DD HH:mm")
        filedata['lastModifiedFmtRoche'] = moment(parseInt(file.lastModifiedOn)).format("YYYY/MM/DD HH:mm")
        filedata["pcReportTitle"] = title
        filedata["pcReportName"] = name
        filedata["pcReportRdr"] = rdr
        filedata["type"] = "Standalone"
        filedata["source"] = "Biobook"
        filedata["template"] = template
        filedata["callLock"] = if file.isLocked then "mdi-lock"  else "mdi-lock-open-outline"
        filedata["lockdBy"] = if file.isLocked then "by #{file.lockedByName}"  else ""
        filedata["downloadPackage"] = if df.length then "mdi-folder-download-outline" else "mdi-circle-off-outline"
        filedata["dataFolderID"] = df
        filedata["callDel"]= if file.isLocked and file.lockedByName isnt this.ComponentHub.activeServer.userdata.name then "mdi-circle-off-outline" else "mdi-delete"
        filedata["hiddenRevisions"]=hiddenRevisions
        filedata['pcReportEmpty']=empty

        reportData.push filedata
      this.tdata = this.tableSort reportData, "createdAtFmt", true

  filterRows: (rows, terms, cols) ->

    if terms.empty
      rows = (row for row in rows when !row.pcReportEmpty)
    if terms.locked
      rows = (row for row in rows when row.callLock isnt "mdi-lock-open-outline")
    if terms.fromDate? and terms.fromDate.length
      fromDateInt =  new Date(terms.fromDate)
      rows = (row for row in rows when row.lastModifiedOn >= fromDateInt)
    if terms.toDate? and terms.toDate.length
      toDateInt = new Date(terms.toDate)
      toDatePlusOneInt = toDateInt.setDate(toDateInt.getDate() + 1)
      rows = (row for row in rows when row.createdAt < toDatePlusOneInt)
    if terms.fullText? and terms.fullText.length
      toSearch = terms.fullText.toLowerCase()
      rows = (row for row in rows when row.entityId.toLowerCase().indexOf(toSearch) >= 0 or
              row.entityId.toLowerCase().indexOf(toSearch) >= 0 or
              row.pcReportTitle.toLowerCase().indexOf(toSearch) >= 0 or
              row.pcReportRdr.toLowerCase().indexOf(toSearch) >= 0 or
              row.template.toLowerCase().indexOf(toSearch) >= 0 or
              row.type.toLowerCase().indexOf(toSearch) >= 0 or
              row.createdByName.toLowerCase().indexOf(toSearch) >= 0
              )
    return rows

  lockReport: (res) ->
    if res.isLocked
      if res.mayEditLock()
        await res.lock(false)
      else
        alert "This resource was not locked by you. You can not unlock it"
    else
      await res.lock(true)
    this.loadFolder()
    this.loadRevisions res


  onRowClick: (row,colname,evt) ->
    res = this.ComponentHub.getResourceFromTree row.resourceId
    this.highlight = row.resourceId
    this.highlightedResource = res
    this.currentSelectedReport = res
    await res.getRevisionHistory()
    switch colname
      when 'callLock'
        this.lockReport res
      when 'pcReportTitle'
        await res.updateRawFileContents()
        this.ComponentHub.FileSystemTree.onRowClick 0,res,0,null
        this.loadRevisions res
      when 'downloadPackage'
        if row.dataFolderID.length
          await this.ComponentHub.refreshFolder row.dataFolderID
          nextTick =>
            dataFolder = this.ComponentHub.getResourceFromTree row.dataFolderID
            if !this.ComponentHub.FileSystemTree.isExpanded dataFolder
              this.ComponentHub.FileSystemTree.toggleExpanded dataFolder
            await dataFolder.download()
        this.loadRevisions res
      when 'callDel'
        if row.isLocked and row.lockedByName isnt this.ComponentHub.activeServer.userdata.name
          return
        this.ComponentHub.PageIndex.confirmBox({
          title: 'Delete Report',
          message: "Are yo sure to delete #{res.name} ? Only an admin can undo this action in the improve client !",
          cancel: true,
          persistent: true
        }, () =>  # ok was pressed
          await res.delete()
          this.filter = ''
          this.revisions=[]
          this.loadFolder()
        , () => # cancel was pressed
        )
      else
        this.loadRevisions res


  loadRevisions: (resource) ->
    this.revisions = []
    leftRevsions = 0
    for rev in resource.revisionList
      rev.version = rev.getHumanReadableVersion()
      if resource.hiddenRevisions.indexOf(rev.entityVersionId) > -1
        rev.downloadVersion = "mdi-circle-off-outline"
        rev.callDel = "mdi-circle-off-outline"
        rev.disabled = true
      else
        rev.idm = ""
        rev.downloadVersion = "mdi-file-download-outline"
        rev.callDel = if resource.isLocked then "mdi-circle-off-outline" else "mdi-delete"
        rev.disabled = false
        leftRevsions += 1
      this.revisions.push(rev)
    this.revisionsOnlyOneLeft = false
    if leftRevsions is 1
      this.revisionsOnlyOneLeft = true
    #for rev in this.revisions
    #  rev.version = rev.getHumanReadableVersion()
    #  rev.idm = ""
    #  rev.downloadVersion = "mdi-file-download-outline"
    #  rev.callDel = if resource.isLocked then "mdi-circle-off-outline" else "mdi-delete"

  onVersionRowClick: (row,colname,evt) ->
    # this click is triggered automatically, therefore there are different parameters then in onRowClick above
    if row.disabled
      return
    switch colname
      when 'downloadVersion'
        await row.download()
        if row.mayEditLock()
          ###
          this.ComponentHub.PageIndex.confirmBox({
            title: 'Lock Report?',
            message: "Do you also want to lock this report ?",
            okLbl: "Yes",
            cancelLbl: "No",
            cancel: true,
            persistent: true
          }, () =>  # ok was pressed
            await row.lock(true)
            this.loadFolder()
          , () => # cancel was pressed
          )
          ###
          if await this.ComponentHub.PageIndex.confirm('Lock Report?',"Do you also want to lock this report ?","No","Yes")
            await row.lock(true)
            this.loadFolder()

      when 'callDel'
        if row.callDel is "mdi-circle-off-outline"
          return
        title = 'Soft Delete Report Revision'
        msg =   "Are yo sure to remove #{row.version} ? Only an admin can undo this action in the improve client !"
        if this.revisionsOnlyOneLeft
          title =  'Delete last Revision'
          msg = "Are yo sure to remove #{row.version} ? This is the last revision of this report. If you delete it, the whole report will be deleted as well !"
        this.ComponentHub.PageIndex.confirmBox({
          title: title,
          message: msg,
          cancel: true,
          persistent: true
        }, () =>  # ok on revision disable was pressed
          if this.revisionsOnlyOneLeft
            await this.currentSelectedReport.delete()
            this.filter = ''
            this.loadFolder()
            this.revisions = []
          else
            if await this.hideRevisionMD row.resourceId, row.entityVersionId
              this.currentSelectedReport.hiddenRevisions.push(row.entityVersionId)
            this.loadRevisions this.currentSelectedReport

        , () => # cancel on revision disablewas pressed
        )
      when 'comment'
        this.ComponentHub.PageIndex.alert(row.comment)


  hideRevisionMD: (resourceId,entityVersionId) ->
    md =
      resourceId: resourceId
      descriptorId: this.ComponentHub.activeServer.metaDataDefinitionMapping['PC_REPORT_HIDDEN_REVISIONS'].id
      descriptorName: "PC_REPORT_HIDDEN_REVISIONS"
      descriptorType: "TEXT"
      textValue: entityVersionId
    res = await this.ComponentHub.activeServer.doQuery apiSetMetaData(resourceId,md)
    return res.status is 200

  rejectedUpload: (evt) ->
    this.quasar.notify({
      message: 'Only .docx Files may be uploaded!',
      color: 'red'
      position: 'center'
    })


  uploadnewversion: (evt) ->
    if this.uploadfile?
      reader = new FileReader()
      reader.onload = this.readerLoad
      reader.readAsArrayBuffer(this.uploadfile)


  readerLoad: (e) ->
    console.log "Content: ", e.target.result
    this.uploadcomment = await this.ComponentHub.PageIndex.prompt 'Upload & Comment',"Enter an optional comment or business reason if needed. You will not be able to comment afterwards.",{
      model: '',
      isValid: (val) => val.length <= 4000,
      type: 'text',
      cancelLbl: "Cancel Upload"
      okLbl: "Upload"}
    if typeof this.uploadcomment is 'boolean'
      if !this.uploadcomment
        #User pressed Cancel
        this.uploadfile = null
        this.uploadcomment = ""
        return
      else
        this.uploadcomment = ''
    this.currentSelectedReport.updateContent e.target.result, this.uploadcomment[0..3999]
    resourceId = this.currentSelectedReport.resourceId
    this.uploadfile = null
    this.uploadcomment = ""
    await this.loadFolder()
    nextTick =>
      res = this.ComponentHub.getResourceFromTree resourceId
      await res.getRevisionHistory()
      this.loadRevisions this.currentSelectedReport
      this.revisions = []
      for rev in res.revisionList
        rev.version = rev.getHumanReadableVersion()
        if res.hiddenRevisions.indexOf(rev.entityVersionId) > -1
          rev.downloadVersion = "mdi-circle-off-outline"
          rev.callDel = "mdi-circle-off-outline"
          rev.disabled = true
        else
          rev.idm = ""
          rev.downloadVersion = "mdi-file-download-outline"
          rev.callDel = if res.isLocked then "mdi-circle-off-outline" else "mdi-delete"
          rev.disabled = false
        this.revisions.push(rev)
        this.loadFolder()
      if this.currentSelectedReport.isLocked
        ###
        this.ComponentHub.PageIndex.confirmBox({
          title: 'Unlock Report?',
          message: "Do you also want to unlock this report ?",
          okLbl: "Yes",
          cancelLbl: "No",
          cancel: true,
          persistent: true
        }, () =>  # ok was pressed
          await this.currentSelectedReport.lock(false)
          this.loadFolder()
        , () => # cancel was pressed
        )
        ###
        if await this.ComponentHub.PageIndex.confirm('Unlock Report?',"Do you also want to unlock this report ?","No","Yes")
          await this.currentSelectedReport.lock(false)
          this.loadFolder()


      #await res.getRevisionHistory()
      #this.revisions = res.revisionList
      #for rev in this.revisions
      #  rev.version = rev.getHumanReadableVersion()
      #  rev.idm = ""
      #  rev.downloadVersion = "⬇️"


  ###*
    * # TableSort
    * Sorts the table levelwise. The sorting happens only within one hierarchy level
    * called by the table widget. This method takes care of hierarchy and expanded states of the tree
    * @param {Array} rows all rows to be sorted Array of ImproveResources
    * @param {string} sortBy he column to sort after
    * @param {boolean} descending true for descending, false for ascending
    * @return {Array} the sorted array
    ###
  tableSort: (rows, sortBy, descending) ->
    return tableSort rows, sortBy, descending, this.sortAlias


  ###* must be called when mounted.
    * creates a map containing those colnames which should be sorted by other cols
    * when a displaycolumn contains a sortfield property, this column will be sorted by that field instead by itself
    * useful when a column is displayed but the sorting should be done by another, not displayed column instead
    ###
  sortAliases: ->
    this.sortAlias = {}
    replaces = (col for col in this.columns when col.hasOwnProperty('sortfield'))
    for col in replaces
      this.sortAlias[col.name] = col.sortfield
    return

  mounted: ->
    super()
    this.sortAliases()
    this.loadFolder()
    this.icon = await this.resource.getDisplayableFromContent()
    this.showContent= true
