###*
* The Pandora Editor Component
* @module components/editor/editor
###

###
We must stay in this mode and be happy tht it works anyway. Wrapping editor in a class will not work:
https://github.com/codemirror/CodeMirror/issues/6805 marijnh is the author of codemirror
marijnh commented on 23 Oct 2021:
On closer look, this should never happen, event without the the patch I added.
I don't know a lot about vue, but I do know that it adds invasive proxies around objects in some situations.
Are you using your CodeMirror instance in such a way that vue will mess with it?
That is not supported by this library.
###

import CodeMirror from 'codemirror'
import 'codemirror/lib/codemirror.css'
import 'codemirror/mode/meta'
import 'codemirror/addon/mode/simple.js'
import 'codemirror/theme/base16-dark.css'
import 'codemirror/theme/3024-night.css'
import 'codemirror/theme/monokai.css'
import 'codemirror/theme/darcula.css'
import 'codemirror//addon/hint/show-hint.css'
import 'codemirror/addon/hint/show-hint.js'
import 'codemirror/addon/scroll/annotatescrollbar.js'
import 'codemirror/addon/search/matchesonscrollbar.js'
import 'codemirror/addon/search/searchcursor.js'
import 'codemirror/addon/search/match-highlighter.js'
import 'codemirror/addon/fold/foldgutter.css'
import 'codemirror/addon/fold/foldcode.js'
import 'codemirror/addon/fold/foldgutter.js'
import 'codemirror/addon/fold/brace-fold.js'
import 'codemirror/addon/fold/xml-fold.js'
import 'codemirror/addon/fold/indent-fold.js'
import 'codemirror/addon/fold/markdown-fold.js'
import 'codemirror/addon/fold/comment-fold.js'
import 'codemirror/addon/mode/multiplex.js'
import 'codemirror/addon/selection/active-line.js'
import 'codemirror/addon/selection/mark-selection.js'
import 'codemirror/mode/markdown/markdown.js'
import 'codemirror/mode/javascript/javascript.js'
showdown = require('showdown')
import ComponentHub from "core/componentsHub.coffee"



export default
  name: 'editor',
  props:
    instancename:
      default: 'editor'
      type: String
    file:
      default: {}
      type: Object
    fullHeight:
      default: true
      type: Boolean

  data: ->
    textToEdit:''
    cmInitialized:false
    allIcsIds:[]
    cls: "CodeMirror-Pandora-"
    widgets:{}
    firstLine:''
    imageCache: {}
    editorType: 'codemirror'



  computed:
    cssVars: () ->
      # provides dynamic css vars depending on several environmental stuff (like dimensions or dark)
      fh = 135
      if @fullHeight
        contentHeight =   "calc(100vh - #{fh})"
      else
        contentHeight = "100%"
      {
        '--contentHeight': contentHeight
        '--editorFont': 'Helvetica Neue'
        '--blockQuoteFont': 'Times'
        '--blockQuoteType': 'serif'
        '--blockQuoteSize': '16px'
        '--blockQuoteColor': '#A0A0A0'
        '--h1Size': '30px'
        '--h2Size': '28px'
        '--h3Size': '26px'
        '--h4Size': '24px'
        '--h5Size': '22px'
        '--h6Size': '20px'
        '--textSize': '16px'
      }
    ###* Gets the DOM ID of textarea to place CodeMirror in it
      * @method textAreaId
      * @return {string}
      ###
    textAreaId:->
      return  @instancename + "_TA"
    ###* Returns the file name to be displayed on top of a tab
      * @method displayName
      * @return {string}
      ###
    displayName: ->
      if @file.name
        if @file.name.endsWith('.req')
          return @file.name[..-5]
      return ''
    ###* Returns the path to be displayed on top of a tab
      * @method displayPath
      * @return {string}
      ###
    displayPath: ->
      if @file.path
        if @file.path.endsWith('.req')
          return @file.path.split('/')[..-2].join('/')
      return ''
    ###* Returns the dimensions to be displayeed in the box above the req file
      * @method dimensionValue
      * @return {string}
      ###
    dimensionValue: ->
      ###* @ts-ignore ###
      if @isReqFile
        return @file.getDeclaration()
      return ''
    ###* Wrapper to get the textcontent of the current file
      * @method fileText
      * @return {string}
      ###
    fileText: ->
      return @file.content


    ###* Returns a list with all icsDefinitions of the current tree Branch
      * The method is used for displaying code hints
      * @method allIcs
      * @return {array}
      * @see hint
      ###
    allIcs: ->
      allDocs = (doc for doc in @file.project.entries when doc.mimeType is "text/pandora-requirement" and doc.ics.length > 0)
      allIcsIds = ({text: "\##{doc.ics}", displayText: "#{doc.ics} - #{doc.shortDescription[..-5]}", className: @LetterToIcon(doc.path.split("/")[2][1]) , hint:@hint} for doc in allDocs when doc.ics.length > 0)
      allIcsIds.sort((a,b) => if (a.text > b.text) then 1 else if (b.text > a.text) then -1 else 0)
      retVal = []
      allIcsIds.filter (item) =>
        if retVal.findIndex( (x) => (x.text is item.text)) < 0
          retVal.push item
      return retVal
    ###* Determines whether this is a req file
      * @method isReqFile
      * @return {boolean}
      ###
    isReqFile: ->
      return @file.mimeType is "text/pandora-requirement"

  ###*
    * Everything starts here from the perspective of the program flow
    * whenever the file changes, we putits text into our textarea and intialize CodeMirror
    * The initialization is done after a short timeout to ensure, everything is rendered already correctly
    ###
  watch:
    '$q.dark.isActive': (val) ->
      if val then @theme = 'darcula' else @theme = 'default'
      #@ts-ignore
      this.editor.setOption('theme', @theme)
    file:
      immediate: true,
      handler: ->
        @file.currentEditor=this
        @textToEdit = @fileText
        if !@cmInitialized
          setTimeout =>
            try
              @initCodeMirror()
            catch e
              # nevermind

          ,600



  methods:
    ###* Converts a given Letter to a round icon for displaying in Code Completion
      * @method LetterToIcon
      * @param {string} letter  letter to be displayd as icon
      * @todo finish implementation
      * @return {string} a css class
      ###
    LetterToIcon: (letter) ->
      suffix = "unknown"
      return @cls + "completion " + @cls + "completion-" + suffix

    createToolBarActions: ->
      return []

    ###* Converts a given data type to an icon for displaying in Code Completion
      *
      * @method typeToIcon
      * @param {string} type
      * @return {string} a css class
      ###
    typeToIcon:  (type) ->
      if type is "?" then suffix = "unknown"
      else if type is "number" or type is "string" or type is "bool" then suffix = type
      else if /^fn\(/.test(type) then suffix = "fn"
      else if /^\[/.test(type) then suffix = "array"
      else suffix = "object"
      return @cls + "completion " + @cls + "completion-" + suffix



    ###* A click handler when the user clicks an #icsXXX Fragment in the rendered text
      *
      * @method icsClicked
      * @param {string} ics
      * @see icsExtension
      ###
    icsClicked: (ics) ->
      doc = ComponentHub.loadedPlugins.pandora.folders[@file.projectName].docByIcs ics
      ComponentHub.PageIndex.selectNode doc
      return false

    ###* Scans the rendered text and inserts click handler for very #icsXXX found
      *
      * @method icsExtension
      * @see icsClicked
      ###
    icsExtension: ->
      return [
        {
          type: 'lang',
          regex: '\\B(\\\\)?#([\\S]+)\\b',
          replace: (match, leadingSlash, tag) =>
              # Check if we matched the leading \ and return nothing changed if so
            if leadingSlash is '\\'
              return match
            else
              name = @file.project.refNameById tag
              return "<strong> #{name}</strong> <a href='#' onclick=\"return icsClicked(\'#{tag}\')\"><sup>(\##{tag})</sup></a>"

        }
      ]

    ###* Is used by allics to generate the codeMirror hints in Code Completion
      * Is internally called by codemirror
      * @method hint
      * @param {object} CodeMirror the instance of CodeMirror
      * @param {object} self a codeMirror text position
      * @param {object} data the data (in our case a file Object
      * @see allIcs
      ###
    hint: (CodeMirror, self, data) ->
      self.from.ch = self.from.ch + 1
      CodeMirror.replaceRange( data.text, self.from ,  self.to , "complete")
      self.to.ch = self.to.ch + data.text.length
      hinttext = data.displayText.split("-")[1]
      CodeMirror.markText(self.from ,self.to,{className:"icsNumber",attributes:{displayText:hinttext} })


    ###* Creates a DOM Element for a tooltip. Is used by makeToolTip
      * @method elt
      * @param {string} tagname the tag to be created (span, div etc.)
      * @param {string} cls the class to be assigned
      * @return {object} The dom element
      * @see makeTooltip
      ###
    elt: (tagname, cls ) ->
      e = document.createElement(tagname)
      if cls
        e.className = cls
      i=0
      for arg in arguments
        if i >=2
          elt = arg
          if typeof elt == "string"
            ele= document.createElement(tagname)
            ele.innerHTML = elt
            #elt = document.createTextNode(elt)
            e.appendChild(ele)
        i++
      return e

    ###* Generates a tooltip for the code assistance
      * @method makeTooltip
      * @param {number} x x coordinate
      * @param {number} y y coordinate
      * @param {string} content the content of the tooltip
      * @param {object} cm the codemirror instance
      * @param {string} className the class to be assigned
      * @return {object} the DOM Node element
      ###
    makeTooltip: (x, y, content, cm, className) ->
      node = @elt("div", @cls + "tooltip" + " " + (className or ""), content)
      node.style.left = x + "px"
      node.style.top = y + "px"
      container = ((cm.options or {}).hintOptions or {}).container or document.body
      container.appendChild(node)

      pos = cm.cursorCoords()
      winW = window.innerWidth
      winH = window.innerHeight
      box = node.getBoundingClientRect()
      hints = document.querySelector(".CodeMirror-hints")
      overlapY = box.bottom - winH
      overlapX = box.right - winW

      if hints and overlapX > 0
        node.style.left = 0
        box = node.getBoundingClientRect()
        #@ts-ignore
        node.style.left = (x = x - hints.offsetWidth - box.width) + "px"
        overlapX = box.right - winW

      if overlapY > 0
        height = box.bottom - box.top
        curTop = pos.top - (pos.bottom - box.top)
        if curTop - height > 0 # Fits above cursor
          node.style.top = (pos.top - height) + "px"
        else if height > winH
          node.style.height = (winH - 5) + "px"
          node.style.top = (pos.bottom - box.top) + "px"

      if overlapX > 0
        if box.right - box.left > winW
          node.style.width = (winW - 5) + "px"
          overlapX -= (box.right - box.left) - winW
        node.style.left = (x - overlapX) + "px"
      return node


    ###* Removes a DOM Element from current Page
      * used to remove a tooltip again
      * @method remove
      * @param {object} node the node to be removed
      ###
    remove: (node) ->
      p = node and node.parentNode
      if p
        p.removeChild(node)

    ###* Creates all Code Completion suggestions
      * @method synonyms
      * @param {object} cm the codemirror instance
      * @return {Promise<any>}
      ###
    synonyms: (cm, option) ->
      return new Promise( (accept) =>
        setTimeout(() =>
          pos = cm.findWordAt(cm.getCursor())
          tag = cm.getRange(pos.anchor, pos.head)
          parsedPos = JSON.parse(JSON.stringify(pos))
          parsedPos.anchor.ch = parsedPos.anchor.ch - 1
          if tag is ""
            pos = cm.findWordAt(cm.getCursor())
          parsedPos = JSON.parse(JSON.stringify(pos))
          parsedPos.anchor.ch = parsedPos.anchor.ch - 1
          tag = cm.getRange(parsedPos.anchor, pos.head)
          if /\#[a-z,A-Z,1-9]+|\ #(?!\S)/.test(tag)
            marks = this.editor.findMarksAt(parsedPos.anchor)
            for mark in marks
              if mark.className is "icsNumber" then mark.clear()
            word = tag.trim()[1..].toLowerCase()
            cwords = (ics for ics in @allIcs when ics.displayText.toLowerCase().indexOf(word) >= 0 )
            cwords=[...new Set(cwords)]
            obj = {list: cwords, from: CodeMirror.Pos(parsedPos.anchor.line, parsedPos.anchor.ch),to: CodeMirror.Pos(parsedPos.head.line, parsedPos.head.ch)}
            tooltip = null
            CodeMirror.on(obj, "close", () =>  @remove(tooltip))
            CodeMirror.on(obj, "update", () =>  @remove(tooltip))
            CodeMirror.on(obj, "select", (cur, node) =>
              @remove(tooltip)
              pFile =  @file.project.docByIcs(cur.text[1..]) #if ts.options.completionTip then ts.options.completionTip(cur.data) else cur.data.doc
              if pFile?
                content = @generateShowDownTag(pFile.content)
                if content
                  tooltip = @makeTooltip(node.parentNode.getBoundingClientRect().right + window.pageXOffset, node.getBoundingClientRect().top + window.pageYOffset, content, cm, @cls + "hint-doc")

            )
            return accept(obj)

          else
            return accept(null)
        , 100)
      )

    ###* Generates a markdown renderer to render the current text
      * @method generateShowDownTag
      * @param {string} text the md text to be rendered
      * @return {string} HTML Code
      ###
    generateShowDownTag: (text) ->
      converter = new showdown.Converter({ extensions: [@icsExtension] })
      return converter.makeHtml(text)


    ###* Initializes CodeMirror. Hs to be called every time when we switch back from Diff view
      * At the beginning we determine the os dark/light look
      * @method initCodeMirror
      ###
    initCodeMirror: ->
      if this.$q.dark.isActive then theme = 'darcula' else theme = 'default'
      CodeMirror.defineMode("pandora", (config) ->
        return CodeMirror.multiplexingMode( CodeMirror.getMode(config, "text/markdown"), {open: "<<", close: ">>", mode: CodeMirror.getMode(config, "application/json"), delimStyle: "delimit"}))

      config =
        extraKeys:
          "Ctrl-Space": "autocomplete"
          "Ctrl-Y": @openTab
          "Enter": "newlineAndIndentContinueMarkdownList"
          "Ctrl-S": @saveFile
          "Cmd-S": @saveFile
        lineNumbers: true
        theme: theme
        lineWrapping: true
        readOnly: false
        styleActiveLine: true
        #highlightSelectionMatches:
        #  showToken: /\w/
        #  annotateScrollbar: true
        foldGutter: true,
        gutters: ["CodeMirror-linenumbers","CodeMirror-foldgutter"]
        hintOptions: {hint:@synonyms,completeSingle: false}
        singleCursorHeightPerLine: true
      markdownMode =
        name: 'markdown'
        highlightFormatting: true
        maxBlockquoteDepth: 0
        xml: true
        fencedCodeBlockHighlighting: true
        allowAtxHeaderWithoutSpace: false

      unless @editor?
        this.editor = CodeMirror.fromTextArea document.getElementById(@textAreaId), config
        this.doc = this.editor.getDoc()
      if typeof @textToEdit is "string"
        this.editor.setValue(@textToEdit)
      else
        this.editor.setValue(JSON.stringify(@textToEdit))
      if @file.name.endsWith('.req')
        this.editor.setOption 'mode', markdownMode
      else if @file.name.endsWith('.json')
        this.editor.setOption 'mode', 'javascript'
      else
        type = @file.getMimeType()
        if type?
          this.editor.setOption 'mode', type
      this.editor.setOption 'theme', theme
      this.editor.setOption("singleCursorHeightPerLine", false)
      @highLightPandora()
      #this.editor.on('renderLine',@renderLineHack)
      this.editor.on('change',@highLightPandora)
      this.editor.on("blur", @saveFile)
      this.editor.on("drop", @onEditorDrop)
      this.editor.markClean()
      #@addWidget()
      @cmInitialized=true




    ###* An attempt to render indented paragrahes in a ways that all lines have the same indentation
      * Does not work in the moment since cm has a prolem with indent-block styles when it should display WYSIWYG
      * @method renderLineHack
      * @param {Object} cm CodeMirror instance
      * @param {Object} line the text lone
      * @param {Object} the DOM Node
      ###
    renderLineHack: (cm, line, elt) ->
      if line.text.startsWith(">")
        elt.getRootNode().children[0].classList.add("pandora-blockquote")
        basePadding = 8
      else
        elt.getRootNode().children[0].classList.remove("pandora-blockquote")
        basePadding = 4
      charWidth = cm.defaultCharWidth()
      #cm.setOption 'singleCursorHeightPerLine', false
      offSet = CodeMirror.countColumn(line.text, null, cm.getOption("tabSize")) * charWidth
      elt.style.textIndent = "-" + offSet + "px"
      elt.style.paddingLeft = (basePadding + offSet) + "px"




    ###* Saves the current file
      * @method saveFile
      * @param {Object} cm The CodeMirrror instance
      * @param {Object} ev The Event which triggered us
      ###
    saveFile: (cm, ev) ->
      # TODO: [PANWEB-12] set Project definition on the fly when requirements.json is changed
      @file.content = cm.getValue()
      @file.save()
      this.editor.markClean()

    ###* The code highlighting method
      * First searches for all **#icsXXX** texts, next searches for any **</path/filename.ext>**
      * or **</path/filename.ext!XXXpx:YYYpx>** text fragments for inserting pictures
      * all ics descriptions or pictures are only displayed via css and never really included in the text.
      * @method highLightPandora
      * @param {Object=} cm the codemirror instance
      * @param {Object=} ev the event which triggered us
      ###
    highLightPandora: (cm,ev)->
      #if cm and  @isReqFile
      #  @saveFile cm,ev
      #First search for #ics References
      pandoraRef = /\#[a-z,0-9]+|\ #(?!\S)/
      cursor = this.editor.getSearchCursor(pandoraRef)
      while cursor.findNext()
        tag= this.editor.getRange(cursor.from(), cursor.to())
        marks = this.editor.findMarksAt(cursor.from())
        for mark in marks
          if mark.className is "icsNumber" then mark.clear()
        cwords = (ics for ics in @allIcs when ics.text is tag)
        if cwords.length
          hinttext = cwords[0].displayText[cwords[0].displayText.indexOf('-')+2..]
          this.editor.markText(cursor.from() ,cursor.to(),{className:"icsNumber",attributes:{displayText:hinttext} })
        else
          marks = this.editor.findMarksAt(cursor.from())
          for mark in marks
            if mark.className is "icsNumber" then mark.clear()
      #Next search for Picture link references !/folder/subfolder/file.ext
      picRef = /<\/.+>/ # /\!\/[\w,\/,.]+|\![0-9,px,%]+|\*[0-9,px,%]+\ /
      cursor = this.editor.getSearchCursor(picRef)
      while cursor.findNext()
        pathTag= this.editor.getRange(cursor.from(), cursor.to())
        if pathTag.indexOf('!',1) is -1
          relPath = pathTag[1..-2]
          width="150px"
          height="150px"
        else
          secondMark = pathTag.indexOf('!',1)
          relPath = pathTag[1..secondMark-1]
          wh = pathTag[secondMark+1..-2]
          whl = wh.split(':')
          if whl.length is 2
            width=whl[0]
            height=whl[1]
          else
            width="150px"
            height="150px"
        marks = this.editor.findMarksAt(cursor.from())
        for mark in marks
          if mark.className is "embeddedPic" then mark.clear()

        content = null
        if @imageCache.hasOwnProperty(relPath)
          content = @imageCache[relPath]
        else
          file = ComponentHub.FileSystemTree.findResourceByCachedPath relPath
          if file?
            content = "--img-url: url('#{file.getContent()}');"
            @imageCache[relPath] = content
        if content?
          content = "--img-width: #{width}; --img-height:#{height};#{content}"
          this.editor.markText(cursor.from() ,cursor.to(),{className:"embeddedPic",css:content })



    ###* Opens a new tab with the file which matches the icsnumber under the cursor
      * @method openTab
      * @param {object} cm the codemirror instance
      ###
    openTab: (cm) ->
      range =  cm.findWordAt cm.getCursor()
      tag=cm.getRange(range.anchor, range.head)
      fulltag = '#' + tag
      if /\#[a-z,1-9]/.test(fulltag)
        doc = ComponentHub.loadedPlugins.pandora.folders[@file.projectName].docByIcs tag
        ComponentHub.PageIndex.selectNode doc




    ###* Event Handler when something is dropped on the editor.
      * mainly we use this for inserting pictures
      * @method onEditorDrop
      * @param {Object} cm the codemirror instance
      * @param {Object} ev the event which triggered us
      ###
    onEditorDrop: (cm,event) ->
      sourceRowKey = event.dataTransfer.getData('text')
      sourceRow = ComponentHub.FileSystemTree.findResourceByIds(sourceRowKey)[0]
      if sourceRow
        event.preventDefault()
        cm.replaceSelection "![Alt Text for Image](#{sourceRow._path} =200x200)"

  mounted: ->
    ComponentHub[this.instancename] = this
    # icsClicked gets called in redered markdown which has only access to window, not to the application
    # so we go around the house and enter through the backdoor ;)
    window.icsClicked=@icsClicked

  unmounted: ->
    delete ComponentHub[this.instancename]
    @file.currentEditor=null





