import CompositionBase from 'core/CompositionBase.coffee'
###* Props definition
  * @typedef {Object}  DialogcontainerProps
  * @property {string} instancename - Our Instancename for registration in ComponentHub
  ###


###*
* @augments CompositionBase
###
export class Dialogcontainer extends CompositionBase
  ###* virtual Field (Getter) gets/sets the openFileDialog in IndexPage
    * @name Dialogcontainer#openFileDialog
    * @type {FileSystemFile}
    ###
  Object.defineProperty this::, "openFileDialog",
    get: ->
      return this.ComponentHub.PageIndex.openFileDialog
    enumerable: true
    configurable: true
    set: (value) ->
      this.ComponentHub.PageIndex.openFileDialog = value
  ###* virtual Field (Getter) returns the selectedTreeNode  Index
    * @name Dialogcontainer#selectedTreeNode
    * @type {FileSystemFile}
    ###
  Object.defineProperty this::, "selectedTreeNode",
    get: ->
      return this.ComponentHub.PageIndex.selectedTreeNode
    enumerable: true
    configurable: true
  ###*
    * @param {Object} options
    * @property {DialogcontainerProps} props
    * @property {Function} emit
    * @property {object} refs
    * @property {object} quasar
    * @constructor
    ###
  constructor: (options) ->
    super(options)
    this.newFileInvalid=true
    this.newFileParams={}

  newFileParamsChanged: (params) ->
    @newFileParams = params

  newFileDialogCloseOK: ->
    this.ComponentHub.PageIndex.openFileDialog=false

  newFileDialogCloseCancel: ->
    this.ComponentHub.PageIndex.openFileDialog=false

  mounted: ->
    super()
