import {toRaw} from "vue"
import ComponentHub from "core/componentsHub.coffee"
import {LSSettingsFileObject} from "core/lsSettingsObject.coffee"
import {SettingsFile} from "components/FileSystemBrowser/SettingsFile.coffee"
import {PluginMain} from 'plugins/improveweb/Improveweb.coffee'
import {Server} from 'apis/improveREST/connectionStatus.coffee'
import {initApi, apiGetRootDir, apiGetResourceByPath,apiGetResource} from 'apis/improveREST/rawImprove.coffee'

#Attention This extends PLUGINMAIN from ImproveWeb, not PluginBase !!!
class Predator extends PluginMain
  constructor:->
    super()
    this.name = "predator"
    this.icon = "mdi-google-downasaur"
    this.mainTitle="Predator"
    this.description="Predator Web Client."
    this.author="© scinteco.com 2022"
    this.version="#{ComponentHub.appVersion} ##{ComponentHub.appBuild}"
    this.longDescription="The improve Web Client for Predator"
    this.mainNavigationWidget='ImproveBrowser'
    this.webApi='ImproveREST'
    this.server = null
    this.explorerPanes=[]
    ###* Mandatory for Plugins.  Does this plugin hide the whole framework for normal users
      * @type {boolean}
      ###
    this.hideFrameWork=true


export {Predator as PluginMain}
