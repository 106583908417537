import CompositionBase from 'core/CompositionBase.coffee'
###* Props definition
  * @typedef {Object}  PdfviewerProps
  * @property {string} instancename - Our Instancename for registration in ComponentHub
  ###


###*
* @augments CompositionBase
###
export class Pdfviewer extends CompositionBase
  ###*
    * @param {Object} options
    * @property {PdfviewerProps} props
    * @property {Function} emit
    * @property {object} refs
    * @property {object} quasar
    * @constructor
    ###
  constructor: (options) ->
    super(options)
    this.resource = options.props.resource
    this.compareWith = options.props.compareWith
    this.pdfSource =  null
    this.showContent =  false
    this.compareSource = null
    this.showCompareContent = false
    this.compareName = ""
    this.compareVersion = ""

  changeRevision: (resource) ->
    this.showContent = false
    this.resource=resource
    this.pdfSource=
        data: await this.resource.getDisplayableFromContent()
    this.showContent = true

  changeCompare: (newResource) ->
    this.compareWith=newResource
    this.showContent = false
    if newResource?
      this.showCompareContent = false
      this.compareSource=
        data: await newResource.getDisplayableFromContent()
      this.compareName=newResource.name
      this.compareVersion=newResource.getHumanReadableVersion()
      this.showCompareContent = true
    this.changeRevision this.resource # Needed to force re-render pdf component
