/**
 * THIS FILE IS GENERATED AUTOMATICALLY.
 * DO NOT EDIT.
 *
 * You are probably looking on adding startup/initialization code.
 * Use "quasar new boot <name>" and add it there.
 * One boot file per concern. Then reference the file(s) in quasar.config.js > boot:
 * boot: ['file', ...] // do not add ".js" extension to it.
 *
 * Boot files are your "main.js"
 **/



import {QBtn,QInput,QToggle,QSelect,QSlider,QOptionGroup,QCheckbox,Dialog,LocalStorage,SessionStorage,Notify} from 'quasar'



export default { config: {"dark":"auto"},components: {QBtn,QInput,QToggle,QSelect,QSlider,QOptionGroup,QCheckbox},plugins: {Dialog,LocalStorage,SessionStorage,Notify} }

