###
Very important to avoid CORS Problems against Tomcat:
When a REST Request is done to a different Origin, the Browser makes a preflight request where it expects a Allow-Origin Header with * or the current origin domain name
In case of POST Requests which are done via Url Params we have no data attribute in the request
In this case, when a preflight is done (Also different origin) Tomcat expects a Content-Type Header.
Just setting this Contenttype Header alone is not enough since the axios lib removes it when no data is present.
Therefore for POST/PUT requests which are only defined via URL Params you have to add a header Content-Type 'application/json' AND a empty "data": {} element to the query.

###
import ComponentHub from "core/componentsHub.coffee"
import {apiVersion, apiAuthenticate} from './statusauth.coffee'

improveHost =''
improveApi =''
improveRepo =''
export improveApiUrl = ''
improveStatusUrl=''
currentWorkspace = {}
export initApi = (workspace)->
  currentWorkspace = workspace
  {improveHost,improveApi,improveRepo} = workspace
  if improveHost.endsWith("/")
    improveHost = improveHost[0..-2]
  if improveApi.startsWith("/")
    improveApi = improveApi[1..-1]
  if improveRepo.startsWith("/")
    improveRepo = improveRepo[1..-1]
  improveApiUrl = "#{improveHost}/#{improveApi}"
  improveStatusUrl = "#{improveHost}/#{improveRepo}"
  ComponentHub.ConnectionStatusLine.setServerUrl(improveHost)


#
###*
  * Generates a token Auth String for Authentication Headers
  * @method tokenAuth
  * @return {string}
  ###
export tokenAuth = () ->
  #token = await  ComponentHub.dbGetConnectionData(currentWorkspace.workSpaceType, currentWorkspace.name, 'ImproveToken')
  #return "Bearer " + token
  "Basic " + btoa("#{ComponentHub.activeServer.user}:#{ComponentHub.activeServer.password}")

#
###*
  * Get the current Username
  * @method user
  * @return {string}
  ###
user = () ->
  return await  ComponentHub.dbGetConnectionData(currentWorkspace.workSpaceType, currentWorkspace.name, 'ImproveUser')
#
###*
  * Generates a default commit message string if no one was provided for any modifying API call
  * @method commitDefaultString
  * @return {string}
  ###
commitDefaultString = () ->
  return "performed by #{ComponentHub.activeWorkspace.username}"

# The next three are defined externally and proxied here
# to avoid circular imports and proper boot file processing
export apiGetVersion = () -> apiVersion improveStatusUrl
export apiDoAuthenticate = (uid, pwd) -> apiAuthenticate improveApiUrl, uid, pwd
export apiDoVerifyToken = (token) -> apiAuthenticate improveApiUrl, token


#
###*
  * Generates an Axios API call for retrieving the Servers root folder
  * @method apiGetRootDir
  * @return {Object}
  ###
export apiGetRootDir = () ->
  "method": "GET",
  "url": improveApiUrl + "/resources/",
  "headers":
    "Authorization": await  tokenAuth()
#
###*
  * Generates an Axios API call for retrieving the Servers root folder inclusive Metadata
  * @method apiGetRootDirWithMeta
  * @return {Object}
  ###
export apiGetRootDirWithMeta = () ->
  "method": "GET",
  "url": improveApiUrl + "/resources/",
  "params":
    "optParams": "metadata"
  "headers":
    "Authorization": await  tokenAuth()

#
###*
  * Generates an Axios API call. Retrieves the sub folder  of given resource of the repo
  * @method apiGetSubTree
  * @param {string} resourceId  the id of the folder the subtree should requested
  * @return {Object}
  ###
export apiGetSubTree = (resourceId) ->
  # Retrieves the root folder of the repo
  "method": "GET",
  "url": improveApiUrl + "/resources/" + resourceId + "/resources",
  "headers":
    "Authorization": await tokenAuth()

#
###*
  * Generates an Axios API call. Retrieves review Entries of given review
  * @method apiGetReviewEntries
  * @param {string} resourceId  the id of the review which should requested
  * @return {Object}
  ###
export apiGetReviewEntries = (resourceId) ->
  {
    "method": "GET",
    "url": "#{improveApiUrl}/reviews/#{resourceId}/entries",
    "headers": {
      "Authorization": await tokenAuth()
    }
  }

#
###*
  * Generates an Axios API call. Retrieves review Entry of given review and entryId
  * @method apiGetReviewEntry
  * @param {string} resourceId  the id of the review which should requested
  * @param {string} entryId  the id of the entry which should requested
  * @return {Object}
  ###
export apiGetReviewEntry = (resourceId, entryId) ->
  {
    "method": "GET",
    "url": "#{improveApiUrl}/reviews/#{resourceId}/entries/#{entryId}",
    "headers": {
      "Authorization": await tokenAuth()
    }
  }


#
###*
  * Generates an Axios API call. Retrieves the sub folder  of given resource of the repo incl. Metadata
  * @method apiGetSubTreeWithMeta
  * @param {string} resourceId  the id of the folder the subtree should requested
  * @return {Object}
  ###
export apiGetSubTreeWithMeta = (resourceId) ->
  # Retrieves the root folder of the repo
  "method": "GET",
  "url": improveApiUrl + "/resources/"  + resourceId + "/resources",
  "params":
    "optParams": "metadata"
  "headers":
    "Authorization": await tokenAuth()

#
###*
  * Generates an Axios API call. Retrieves the sub folder  of given resource of the repo incl. Metadata
  * @method apiGetSubTreeWithMeta
  * @param {string} path  the path of the folder the subtree should requested
  * @param {boolean} withMeta  wether metadata should be requested as well
  * @return {Object}
  ###
export apiGetResourceByPath = (path, withMeta= false) ->
  params =
    path: path
  if withMeta
    params.optParams = "metadata"
  "method": "GET"
  "url": improveApiUrl +  "/resources/path"
  "params": params
  "headers":
    "Authorization": await tokenAuth()

#
###*
  * Generates an Axios API call. Retrieves file contents
  * @method apiGetResourceContent
  * @param {string} resourceId  of the file
  * @param {string} responseType optional defaults to 'json' can also be 'arraybuffer', 'document', 'text', 'stream', 'blob'
  * @return {Object}
  ###
export apiGetResourceContent = (resourceId, responseType='json') ->
  "method": "GET",
  "url": improveApiUrl + "/resources/" + resourceId + "/content",
  "responseType": responseType
  "headers":
    "Authorization": await tokenAuth()

#
###*
  * Generates an Axios API call. Retrieves a single resource
  * @method apiGetResource
  * @param {string} resourceId  of the file
  * @return {Object}
  ###
export apiGetResource = (resourceId) ->
  "method": "GET",
  "url": improveApiUrl + "/resources/" + resourceId ,
  "headers":
    "Authorization": await tokenAuth()

#
###*
  * Generates an Axios API call. Retrieves a single resource with metaData
  * @method apiGetResourceapiGetResourceWithMeta
  * @param {string} resourceId  of the file
  * @return {Object}
  ###
export apiGetResourceWithMeta = (resourceId) ->
  "method": "GET",
  "url": improveApiUrl + "/resources/" + resourceId ,
  "params":
    "optParams": "metadata",
  "headers":
    "Authorization": await tokenAuth()

#
###*
  * Generates an Axios API call. Retrieves file contents
  * @method apiGetResourceContent
  * @param {string} resourceId  of the file
  * @param {string} revisionId  of the file
  * @param {string} responseType optional defaults to 'json' can also be 'arraybuffer', 'document', 'text', 'stream', 'blob'
  * @return {Object}
  ###
export apiGetRevisionContent =  (resourceId,revisionId, responseType='json') ->
  {
    "method": "GET",
    "url": "#{improveApiUrl}/revisions/#{revisionId}/resources/#{resourceId}/content",
    "responseType": responseType
    "headers": {
      "Authorization": await tokenAuth()
    }
  }

#
###*
  * Generates an Axios API call. Retrieves an Array with all revisions of the given resource
  * @method apiGetRevisionHistory
  * @param {string} resourceId  of the  resource
  * @return {Object}
  ###
export apiGetRevisionHistory = (resourceId) ->
  "method": "GET",
  "url": improveApiUrl + "/resources/" + resourceId + "/revisionHistory",
  "headers":
    "Authorization": await tokenAuth()

#
###*
  * Generates an Axios API call. Retrieves an Array with all metadata of the given resource
  * @method apiGetResourceMetadata
  * @param {string} resourceId  of the  resource
  * @return {Object}
  ###
export apiGetResourceMetadata = (resourceId) ->
  {
    "method": "GET",
    "url": "#{improveApiUrl}/resources/#{resourceId}/metadata"
    "headers": {
      "Authorization": await tokenAuth()
    }
  }
#
###*
  * Generates an Axios API call. Retrieves an Array with all metadata of the given resource / revision
  * @method apiGetRevisionMetaData
  * @param {string} resourceId  of the  resource
  * @param {string} revisionId  of the  resource
  * @return {Object}
  ###
export apiGetRevisionMetaData =  (resourceId,revisionId) ->
  {
    "method": "GET",
    "url": "#{improveApiUrl}/revisions/#{revisionId}/resources/#{resourceId}/metadata",
    "headers": {
      "Authorization": await tokenAuth()
    }
  }
#
###*
  * Generates an Axios API call. Copies one resource ito another
  * @method apiCopyResource
  * @param {string} sourceId  id of the resource which should be copied
  * @param {string} targetId  id of the target resource
  * @param {string} targetName new name of the copied resource
  * @param {string} commitMsg  optional commit message
  * @return {Object}
  ###
export apiCopyResource =  (sourceId,targetId, targetName, commitMsg) ->
  unless commitMsg?
    commitMsg=commitDefaultString()
  {
    "method": "POST",
    "mode": 'cors',
    "url": "#{improveApiUrl}/resources/#{sourceId}/copy",
    "params": {
      "targetId": targetId,
      "newName": targetName,
      "comment": commitMsg
    },
    "headers": {
      "authorization": await tokenAuth(),
      "Content-Type": "application/json"
    },
    "data":{}
  }
#
###*
  * Generates an Axios API call. Moves one resource ito another
  * @method apiMoveResource
  * @param {string} sourceId  id of the resource which should be moved
  * @param {string} targetId  id of the target resource
  * @param {string} targetName new name of the moved resource
  * @param {string} commitMsg  optional commit message
  * @return {Object}
  ###
export apiMoveResource =  (sourceId,targetId, targetName, commitMsg) ->
  unless commitMsg?
    commitMsg=commitDefaultString()
  {
    "method": "POST",
    "url": "#{improveApiUrl}/resources/#{sourceId}/move",
    "params": {
      "targetId": targetId,
      "newName": targetName,
      "comment": commitMsg
    },
    "headers": {
      "Authorization": await tokenAuth(),
      "Content-Type": "application/json"
    },
    "data":{}
  }
#
###*
  * Generates an Axios API call. Deletes resource
  * @method apiDeleteResource
  * @param {string} resourceId  id of the resource which should be deleted
  * @return {Object}
  ###
export apiDeleteResource =  (resourceId) ->
  {
    "method": "DELETE",
    "url": "#{improveApiUrl}/resources/#{resourceId}",
    "headers": {
      "Authorization": await tokenAuth()
    }
  }
#
###*
  * Generates an Axios API call. Creates a new folderish Item
  * @method apiNewFolder
  * @param {string} resourceId  id where the new resource should be created in
  * @param {string} name  id of the new resource
  * @param {string} type of the new resource. Can be "Folder" (default),"Step" or "Analysis Tree"
  * @param {string} commitMsg  optional commit message
  * @return {Object}
  ###
export apiNewFolder = (resourceId,name,type="Folder", commitMsg) ->
  unless commitMsg?
    commitMsg=commitDefaultString()
  {
    "method": "POST",
    "url": "#{improveApiUrl}/resources/#{resourceId}/resources",
    "headers": {
      "Authorization": await tokenAuth(),
      "Content-Type": "application/json"
    },
    "data": {
      "name": name,
      "comment": commitMsg,
      "nodeType": type
    }
  }

#
  ###*
  * Generates an Axios API call. Updates the content of given resourceId
  * @method apiResourceUpdate
  * @param {string} resourceId  id
  * @param {string} content  new content
  * @param {string} commitMsg  optional commitmsg
  * @return {Object}
  ###
export apiResourceUpdate = (resourceId, content, commitMsg) ->
  unless commitMsg?
    commitMsg= commitDefaultString()
  {
    "method": "PUT",
    "url": "#{improveApiUrl}/resources/#{resourceId}/content",
    "headers": {
      "Authorization": await tokenAuth(),
      "Content-Type": "application/octet-stream"
    },
    "params": {
      "comment": commitMsg
    },
    "data": content
  }

export apiResourceLock = (resourceId, lockit=true) ->
  lock = 'lock'
  if !lockit
    lock='unlock'
  {
    "method": "PUT",
    "url": "#{improveApiUrl}/resources/#{resourceId}/#{lock}",
    "headers": {
      "Authorization": await tokenAuth(),
      "Content-Type": "application/json"
    },
    "data": {}
  }

#
  ###*
  * Generates an Axios API call. Adds given resourceIds to given review
  * @method apiNewFolder
  * @param {string} reviewId  id where the new entry (ies) should be created in
  * @param {Array|String} resourceIds  ids of the new resource
  * @return {Object}
  ###
export apiNewResourceEntries = (reviewId,resourceIds) ->
  if typeof resourceIds is "string"
    resourceIds = [resourceIds]
  {
    "method": "POST",
    "url": "#{improveApiUrl}/reviews/#{reviewId}/entries",
    "headers": {
      "Authorization": await tokenAuth(),
      "Content-Type": "application/json"
    },
    "data": {
      "resourceIds": resourceIds
    }
  }

#
  ###*
  * Generates an Axios API call. Get ChildSteps of given step in given tree
  * @method apiResourceGetChildSteps
  * @param {string} treeId  id where the new entry (ies) should be created in
  * @param {string} stepId  ids of the new resource
  * @return {Object}
  ###
export apiResourceGetChildSteps = (treeId,stepId) ->
  {
    "method": "GET",
    "url": "#{improveApiUrl}/resources/#{treeId}/steps/#{stepId}/childSteps",
    "headers": {
      "Authorization":await tokenAuth()
    }
  }


#
###*
  * Generates an Axios API call. Adds given resourceIds to given review
  * @method apiNewFolder
  * @param {string} name  name
  * @param {string} parentPath  name
  * @param {string} commitMsg  commitMsg
  * @return {Object}
  ###
export apiNewReview = (name,parentPath,commitMsg) ->
  unless commitMsg?
    commitMsg=commitDefaultString()
  {
    "method": "POST",
    "url": "#{improveApiUrl}/reviews",
    "headers": {
      "Authorization": await tokenAuth(),
      "Content-Type": "application/json"
    },
    "data": {
      "name": name,
      "parentPath" :parentPath,
      "comment":commitMsg


    }
  }
#
###*
  * Generates an Axios API call. Gets All Reviews
  * @method apiAllReviews
  * @return {Object}
  ###
export apiAllReviews = ->
  {
    "method": "GET",
    "url": "#{improveApiUrl}/reviews",
    "headers": {
      "Authorization": await tokenAuth()
    },
    "params": {
      "optParams": "reviewers",
      "pageSize": "200"
    }
  }


#
###*
  * Generates an Axios API call. Gets All Reviewers of the given Review
  * @method apiGetReviewReviewers
  * @param {string}  reviewId
  * @return {Object}
  ###
export apiGetReviewReviewers = (reviewId)->
  {
    "method": "GET",
    "url": "#{improveApiUrl}/reviews/#{reviewId}/reviewers",
    "headers": {
      "Authorization": await tokenAuth()
    }
  }

#
###*
  * Generates an Axios API call. Gets All Comments of the given Review
  * @method apiGetReviewReviewers
  * @param {string}  reviewId
  * @return {Object}
  ###
export apiGetReviewComments = (reviewId)->
  {
    "method": "GET",
    "url": "#{improveApiUrl}/reviews/#{reviewId}/comments",
    "headers": {
      "Authorization": await tokenAuth()
    }
  }

#
###*
  * Generates an Axios API call. Gets All Comments of the given Review Entry
  * @method apiGetReviewReviewers
  * @param {string}  reviewId
  * @return {Object}
  ###
export apiGetReviewEntryComments = (reviewId,entryId)->
  {
    "method": "GET",
    "url": "#{improveApiUrl}/reviews/#{reviewId}/entries/#{entryId}/comments",
    "headers": {
      "Authorization": await tokenAuth()
    }
  }
#
###*
  * Generates an Axios API call. Gets Review Details
  * @method apiGetReviewReviewers
  * @param {string}  reviewId
  * @return {Object}
  ###
export apiGetReviewDetails = (reviewId)->
  {
    "method": "GET",
    "url": "#{improveApiUrl}/reviews/#{reviewId}?optParams=comments&optParams=reviewers&optParams=entries",
    "headers": {
      "Authorization": await tokenAuth(),
      "Content-Type": "application/json; charset=utf-8"
    }
  }
#
###*
  * Generates an Axios API call. Adds a reviewer
  * @method apiGetReviewReviewers
  * @param {string}  reviewId
  * @param {string}  userID
  * @param {string}  userName
  * @return {Object}
  ###
export apiAddReviewer = (reviewId, userID, userName)->
  {
    "method": "POST",
    "url": "#{improveApiUrl}/reviews/#{reviewId}/reviewers",
    "headers": {
      "Authorization": await tokenAuth(),
      "Content-Type": "application/json"
    },
    "data": {
      "userId": userID,
      "username" :userName
  }
}

#
###*
  * Generates an Axios API call. Adds a comment to a review
  * @method apiGetReviewReviewers
  * @param {string}  reviewId
  * @param {string}  comment
  * @return {Object}
  ###
export apiAddReviewComment = (reviewId,comment)->
  {
    "method": "POST",
    "url": "#{improveApiUrl}/reviews/#{reviewId}/comments",
    "headers": {
      "Authorization": await tokenAuth(),
      "Content-Type": "application/json"
    },
    "data": {
      "comment": comment
    }
  }

#
###*
  * Generates an Axios API call. Adds a comment to a review entry
  * @method apiGetReviewReviewers
  * @param {string}  reviewId
  * @param {string}  entryId
  * @param {string}  comment
  * @return {Object}
  ###
export apiAddReviewEntryComment = (reviewId,entryId,comment)->
  {
    "method": "POST",
    "url": "#{improveApiUrl}/reviews/#{reviewId}/entries/#{entryId}/comments",
    "headers": {
      "Authorization": await tokenAuth(),
      "Content-Type": "application/json; charset=utf-8"
    },
    "data": comment
  }

#
###*
  * Generates an Axios API call. Sets an answer for an Invitation
  * @method apiAnswerReviewInvitation
  * @param {string}  reviewId
  * @param {boolean}  accept is true (default) decline is false
  * @return {Object}
  ###
export apiAnswerReviewInvitation = (reviewId,accept=true)->
  method = if accept then 'accept' else 'decline'
  answer = if accept then 'accepted' else 'declined'
  {
    "method": "POST",
    "url": "#{improveApiUrl}/reviews/#{reviewId}/#{method}",
    "headers": {
      "Authorization": await tokenAuth(),
      "Content-Type": "application/json; charset=utf-8"
    },
    "data":  "#{answer} invitation"

  }

#
###*
  * Generates an Axios API call. Sets an answer for an Invitation
  * @method apiDeleteReviewer
  * @param {string}  reviewId
  * @param {string}  reviewerId
  * @return {Object}
  ###
export apiDeleteReviewer = (reviewId,reviewerId)->
  {
    "method": "DELETE",
    "url": "#{improveApiUrl}/reviews/#{reviewId}/reviewers/#{reviewerId}",
    "headers": {
      "Authorization": await tokenAuth()
    }
  }

#
###*
  * Generates an Axios API call. Sets an entry as approved
  * @method apiDeleteReviewer
  * @param {string}  reviewId
  * @param {string}  reviewEntryId
  * @return {Object}
  ###
export apiReviewApproveEntry = (reviewId,reviewEntryId,comment="By Improve Web")->
  {
    "method": "POST",
    "url": "#{improveApiUrl}/reviews/#{reviewId}/entries/approve",
    "headers": {
      "Authorization": await tokenAuth(),
      "Content-Type": "application/json"
    },
    "data": {
      "comment": comment,
      "reviewEntryIds": [
        reviewEntryId
      ]
    }
  }

#
###*
  * Generates an Axios API call. Sets an entry as rejected
  * @method apiDeleteReviewer
  * @param {string}  reviewId
  * @param {string}  reviewEntryId
  * @return {Object}
  ###
export apiReviewRejectEntry = (reviewId,reviewEntryId,comment="By Improve Web")->
  {
    "method": "POST",
    "url": "#{improveApiUrl}/reviews/#{reviewId}/entries/reject",
    "headers": {
      "Authorization": await tokenAuth(),
      "Content-Type": "application/json"
    },
    "data": {
      "comment": comment,
      "reviewEntryIds": [
        reviewEntryId
      ]
    }
  }

#
###*
  * Generates an Axios API call. Gets All Users in the system
  * @method apiGetUsers
  * @return {Object}
  ###
export apiGetUsers = ->
  {
    "method": "GET",
    "url": "#{improveApiUrl}/users",
    "headers": {
      "Authorization": await tokenAuth()
    }
  }

#
###*
  * Generates an Axios API call. Gets All Users in the system
  * @method apiGetUserGroupMemberShip
  * @return {Object}
  ###
export apiGetUserGroupMemberShip = (userId) ->
  {
    "method": "GET",
    "url": "#{improveApiUrl}/users/#{userId}/groupMemberships",
    "headers": {
      "Authorization": await tokenAuth()
    }
  }



#
###*
 * Generates an Axios API call. Retrieves all processes of given step revision
 * @method apiGetStepProcesses
 * @param {string} resourceId  of the step
 * @param {string} revisionId  of the step
 * @param {string} responseType optional defaults to 'json' can also be 'arraybuffer', 'document', 'text', 'stream', 'blob'
 * @return {Object}
  ###
export apiGetStepProcesses =  (resourceId,revisionId, responseType='json') ->
  {
    "method": "GET",
    "url": "#{improveApiUrl}/revisions/#{revisionId}/resources/#{resourceId}/processes",
    "responseType": responseType
    "headers": {
      "Authorization": await tokenAuth()
    }
  }
#
###*
  * Generates an Axios API call. Retrieves properties of given runserver
  * @method apiGetResourceContent
  * @param {string} runServerId  of the runserver
  * @return {Object}
  ###
export apiGetRunserver =  (runServerId) ->
  {
    "method": "GET",
    "url": "#{improveApiUrl}/configuration/runservers/#{runServerId}",
    "headers": {
      "Authorization": await tokenAuth()
    }
  }

#
###*
  * Generates an Axios API call. Retrieves all processes of given step revision
  * @method apiGetStepProcesses
  * @param {string} resourceId  of the step
  * @param {string} revisionId  of the step
  * @param {string} processId optional defaults to 'json' can also be 'arraybuffer', 'document', 'text', 'stream', 'blob'
  * @return {Object}
  ###
export apiGetProcessRuns =  (resourceId,revisionId,processId) ->
  {
    "method": "GET",
    "url": "#{improveApiUrl}/revisions/#{revisionId}/resources/#{resourceId}/processes/#{processId}/runs/",
    "headers": {
      "Authorization": await tokenAuth()
    }
  }

#
###*
  * Generates an Axios API call. Retrieves all meta Character Types from Server
  * @method apiGetMetaDataCharacters
  * @return {Object}
  ###
export apiGetMetaDataCharacters = ->
  {
    "method": "GET",
    "url": "#{improveApiUrl}/configuration/metadata/characters",
    "headers": {
      "Authorization": await tokenAuth()
    }
}

#
###*
  * Generates an Axios API call. Retrieves all metadata definitions from Server
  * @method apiGetMetaDataDefinitions
  * @return {Object}
  ###
export apiGetMetaDataDefinitions = ->
  {
    "method": "GET",
    "url": "#{improveApiUrl}/configuration/metadata/definitions",
    "headers": {
      "Authorization": await tokenAuth()
    }
}


#
###*
  * Generates an Axios API call. Sets all given metadata in one bulk operation
  * @method apiSetMetaDataBulk
  * @return {Object}
  ###
export apiSetMetaDataBulk = (resourceId,metadata)->
  {
    "method": "POST",
    "url": "#{improveApiUrl}/resources/#{resourceId}/metadata/bulk",
    "headers": {
      "Authorization": await tokenAuth()
    }
    "data":metadata
}

#
###*
  * Generates an Axios API call. Sets given metadata
  * @method apiSetMetaData
  * @return {Object}
  ###
export apiSetMetaData = (resourceId,metadata)->
  {
    "method": "POST",
    "url": "#{improveApiUrl}/resources/#{resourceId}/metadata",
    "headers": {
      "Authorization": await tokenAuth(),
      "Content-Type": "application/json; charset=utf-8"
    }
    "data":metadata
}

#
###*
  * Generates an Axios API call. Runs the given step
  * @method apiRunStep
  * @return {Object}
  ###
export apiRunStep = (resourceId)->
  {
    "method": "POST",
    "url": "#{improveApiUrl}/resources/#{resourceId}/run",
    "headers": {
      "Authorization": await tokenAuth()
    }
    "data":{}

}
