import CompositionBase from 'core/CompositionBase.coffee'
import {ImproveStep} from 'components/ImproveBrowser/resourceTypes/ImproveStep.coffee'
###* Props definition
  * @typedef {Object}  StepviewerProps
  * @property {string} instancename - Our Instancename for registration in ComponentHub
  ###


###*
* @augments CompositionBase
###
export class Stepviewer extends CompositionBase
  ###*
    * @param {Object} options
    * @property {StepviewerProps} props
    * @property {Function} emit
    * @property {object} refs
    * @property {object} quasar
    * @constructor
    ###
  constructor: (options) ->
    super(options)
    if options.props.resource.iWebType is "ReviewEntry"
      if options.props.resource.resource.nodeType is "Step"
        this.resource= new ImproveStep(options.props.resource.resource)
    else
      this.resource=options.props.resource
    this.tab =  'properties'
    this.splitterModel = 50
    this.columns =[
      { name: 'name', required: true,label: 'Name', align: 'left', field: 'name', sortable: true},
      { name: 'commandLine', label: 'CommandLine', field: 'commandLine', sortable: true ,align: 'left'},
      { name: 'reproducible', label: 'Reproducible', field: 'reproducible' , sortable: true, align: 'center'},
      { name: 'processType',  label: 'Type', field: 'processType', sortable: true,align: 'left' },
      { name: 'runserverLabel', label: 'Runserver', field: 'runserverLabel' , sortable: true,align: 'left'},
      { name: 'toolLabel', label: 'Tool', field: 'toolLabel' , sortable: true, align: 'left'},
      { name: 'toolArgs',required: true, label: 'Args', field: 'toolArgs', sortable: true,align: 'left'}
    ]
    this.running=false
    this.selectedProcess={}
    this.processPropertyGroups= [
      {name:'General',color:'orange'},
      {name:'Tools',color:'red'},
      {name:'Stats',color:'green'}]
    this.processProperties =
      type: {type:"string", caption: "Type",group:"General"}
      id: {type:"string", caption: "Id",group:"General"}
      parentProcessId: {type:"string", caption: "Parent Process Id",group:"General"}
      processType:  {type:"string", caption: "Process Type",group:"General"}
      position: {type:"integer", caption: "Position",group:"General"}
      name:  {type:"string", caption: "Name",group:"General"}
      runserverId:  {type:"string", caption: "Runserver Id",group:"General"}
      runserverLabel: {type:"string", caption: "Runserver Label",group:"General"}
      runserverUrl: {type:"string", caption: "Runserver Url",group:"General"}
      gridTool: {type:"boolean", caption: "Grid Tool",group:"Tools"}
      main: {type:"boolean", caption: "Main",group:"General"}
      repoTool: {type:"boolean", caption: "Repo Tool",group:"Tools"}
      toolLabel:  {type:"string", caption: "Tool Label",group:"Tools"}
      toolInstance: {type:"string", caption: "Tool Instance",group:"Tools"}
      toolArgs: {type:"string", caption: "Tool Args",group:"Tools"}
      toolStreamablePatterns: {type:"string", caption: "Tool Streamable Patterns",group:"Tools"}
      toolDeletePatterns: {type:"string", caption: "Tool Delete Patterns",group:"Tools"}
      toolIgnorePatterns:  {type:"string", caption: "Tool Ignore Patterns",group:"Tools"}
      runStatus: {type:"string", caption: "Run Status",group:"General"}
      deleted: {type:"boolean", caption: "Deleted",group:"General"}
      selected: {type:"boolean", caption: "Selected",group:"General"}
      stepId: {type:"string", caption: "Step Id",group:"General"}
      runId: {type:"string", caption: "Run Id",group:"General"}
      runResult: {type:"string", caption: "Run Result",group:"General"}
      manualResult: {type:"string", caption: "Manual Result",group:"General"}
      startedAt: {type:"datetime", caption: "Started At",group:"Stats"}
      stoppedAt: {type:"datetime", caption: "Stopped At",group:"Stats"}
      toolId: {type:"string", caption: "Tool Id",group:"Tools"}
      runserverToolId: {type:"string", caption: "Runserver Tool Id",group:"Tools"}
      subProcesses: {type:"array", caption: "Sub Processes",group:"General"}

  runStep: () ->
    @running = !@running
    #this.$comps[@toolBarName].setContextButtons @cmpButtons
  stopStep: () ->
    @running = !@running
    #this.$comps[@toolBarName].setContextButtons @cmpButtons
  refreshStep: ->
    unless this.resource?
      return
    await @resource.getProcesses()
    if @resource.processes.length
      @selectedProcess = @resource.processes[0]

  loadStep: (resource) ->
    unless resource?
      return
    if resource.hasOwnProperty("iWebType")
      if resource.iWebType is "ReviewEntry"
        if resource.resource.nodeType is "Step"
          this.resource= new ImproveStep(resource.resource)
          return
    this.resource=resource


  processRowClicked: (event,row,index) ->
    @selectedProcess = row

  mounted: ->
    super()
    await this.refreshStep()


