import {toRaw} from "vue"
import ComponentHub from "core/componentsHub.coffee"
import {LSSettingsFileObject} from "core/lsSettingsObject.coffee"
import {SettingsFile} from "components/FileSystemBrowser/SettingsFile.coffee"
import {PluginMain} from 'plugins/improveweb/Improveweb.coffee'
import {Server} from 'apis/improveREST/connectionStatus.coffee'
import {initApi, apiGetRootDir, apiGetResourceByPath,apiGetResource} from 'apis/improveREST/rawImprove.coffee'

#Attention This extends PLUGINMAIN from ImproveWeb, not PluginBase !!!
class Review extends PluginMain
  constructor:->
    super()
    this.name = "review"
    this.icon = "mdi-glasses"
    this.mainTitle="ImproveReview"
    this.description="Improve Review Client."
    this.author="© scinteco.com 2022"
    this.version="#{ComponentHub.appVersion} ##{ComponentHub.appBuild}"
    this.longDescription="The improve Web Client for Reviewers"
    this.mainNavigationWidget='ImproveBrowser'
    this.webApi='ImproveREST'
    this.server = null
    this.explorerPanes=["AllReviews"]


export {Review as PluginMain}
