import CompositionBase from "core/CompositionBase.coffee"
import showdown from 'showdown'

export class Container extends CompositionBase
  ###* virtual Field (Getter) returns the actionButtons
    * @name Container#renderMode
    * @type {boolean}
    ###
  Object.defineProperty this::, "renderMode",
    get: ->
      parentPanelId = this.options.props.parentPanelId
      renderMode = false
      tab = if this.resource.iWebContext is "ImproveReview" then this.resource.id else this.resource.resourceId
      if this.ComponentHub.layoutTree.getChildByPaneId(parentPanelId)?
        if this.ComponentHub.layoutTree.getChildByPaneId(parentPanelId).tabs[tab]?
          renderMode = this.ComponentHub.layoutTree.getChildByPaneId(parentPanelId).tabs[tab].renderMode
      return renderMode
    enumerable: true
    configurable: true


  ###*
   * @param {VueClassOptions=} options
   * @constructor
  ###
  constructor: (options) ->
    super(options)
    this.options=options
    this.splitterModel =  75
    this.tab="content"
    ###* @ts-ignore ###
    this.resource = options.props.node
    this.revision = this.resource
    this.compareRevision= null
    this.sdOptions =
      "omitExtraWLInCodeBlocks": false,
      "noHeaderId": false,
      "prefixHeaderId": false,
      "rawPrefixHeaderId": false,
      "ghCompatibleHeaderId": false,
      "rawHeaderId": false,
      "headerLevelStart": false,
      "parseImgDimensions": true,
      "simplifiedAutoLink": true,
      "excludeTrailingPunctuationFromURLs": false,
      "literalMidWordUnderscores": false,
      "literalMidWordAsterisks": false,
      "strikethrough": true,
      "tables": true,
      "tablesHeaderId": false,
      "ghCodeBlocks": true,
      "tasklists": true,
      "smoothLivePreview": false,
      "smartIndentationFix": false,
      "disableForced4SpacesIndentedSublists": false,
      "simpleLineBreaks": true,
      "requireSpaceBeforeHeadingText": false,
      "ghMentions": false,
      "ghMentionsLink": "https://github.com/{u}",
      "encodeEmails": true,
      "openLinksInNewWindow": false,
      "backslashEscapesHTMLTags": false,
      "emoji": true,
      "underline": true,
      "completeHTMLDocument": false,
      "metadata": true,
      "splitAdjacentBlockquotes": true


  onTabActivated:(value) ->
    if this.revision.iWebContext is 'ImproveReview'
      this.ComponentHub.PageIndex.showRightPane=true
      if this.ComponentHub.PageIndex.rightPaneSplitter is 0
        this.ComponentHub.PageIndex.rightPaneSplitter = 25
    else
      this.ComponentHub.PageIndex.showRightPane=false


  ###* Generates a markdown renderer to render the current text for Browsing
      * @method generateShowDownTag
      * @return {string} HTML Code
      ###
  generateHTML: ->
    text = this.resource.content
    if this.resource.mimeType is 'text/markdown'
      options = this.sdOptions
      converter = new showdown.Converter(options)
      return converter.makeHtml(text)
    if this.ComponentHub.$quasar?
      if this.ComponentHub.$quasar.dark.isActive
        css='<style>body\n{color:white;}\n a:link {color: #ccc;}</style>'
        doc = document.implementation.createHTMLDocument("tmp")
        if doc.body.parentElement?
          doc.body.parentElement.innerHTML = text + css
          text = doc.body.parentElement.innerHTML

    return text



  onRevisionChanged: (selected, compare) ->
    if selected
      this.revision = selected
    else
      this.revision = this.resource
    this.compareRevision = compare
    this.onTabActivated(true)


  mounted: ->
    super()
    this.ComponentHub.activeContainer = this
    this.onTabActivated(true)
    # Initialize renderview when we are a review html or markdown
    if this.resource.iWebContext is "ImproveReview" and (this.resource.mimeType is 'text/markdown' or this.resource.mimeType is 'text/html')
      parentPanelId = this.options.props.parentPanelId
      tab = this.resource.id
      if this.ComponentHub.layoutTree.getChildByPaneId(parentPanelId)?
        if this.ComponentHub.layoutTree.getChildByPaneId(parentPanelId).tabs[tab]?
          this.ComponentHub.layoutTree.getChildByPaneId(parentPanelId).tabs[tab].renderMode = true

