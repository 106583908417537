import CompositionBase from "core/CompositionBase.coffee"
import showdown from 'showdown'

export class Container extends CompositionBase
  ###* virtual Field (Getter) returns the actionButtons
    * @name Container#renderMode
    * @type {boolean}
    ###
  Object.defineProperty this::, "renderMode",
    get: ->
      parentPanelId = this.options.props.parentPanelId
      renderMode = false
      if this.ComponentHub.layoutTree.getChildByPaneId(parentPanelId)?
        if this.ComponentHub.layoutTree.getChildByPaneId(parentPanelId).tabs[this.resource.resourceId]?
          renderMode = this.ComponentHub.layoutTree.getChildByPaneId(parentPanelId).tabs[this.resource.resourceId].renderMode
      return renderMode
    enumerable: true
    configurable: true

  ###* virtual Field (Getter/Setter)
    * gets or sets the current Doc for Browsing
    * @name Container#browseDoc
    * @property {boolean} browseDoc
    ###
  Object.defineProperty this::, "browseDoc",
    get: ->
      return this._browseDoc
    enumerable: true
    configurable: true
    set: (val) ->
      this._browseDoc = val
      this.refs.vueTrigger += 1

  ###*
   * @param {VueClassOptions=} options
   * @constructor
  ###
  constructor: (options) ->
    super(options)
    this.splitterModel =  50
    this.sdOptions =
      "omitExtraWLInCodeBlocks": false,
      "noHeaderId": false,
      "prefixHeaderId": false,
      "rawPrefixHeaderId": false,
      "ghCompatibleHeaderId": false,
      "rawHeaderId": false,
      "headerLevelStart": false,
      "parseImgDimensions": true,
      "simplifiedAutoLink": true,
      "excludeTrailingPunctuationFromURLs": false,
      "literalMidWordUnderscores": false,
      "literalMidWordAsterisks": false,
      "strikethrough": true,
      "tables": true,
      "tablesHeaderId": false,
      "ghCodeBlocks": true,
      "tasklists": true,
      "smoothLivePreview": false,
      "smartIndentationFix": false,
      "disableForced4SpacesIndentedSublists": false,
      "simpleLineBreaks": true,
      "requireSpaceBeforeHeadingText": false,
      "ghMentions": false,
      "ghMentionsLink": "https://github.com/{u}",
      "encodeEmails": true,
      "openLinksInNewWindow": false,
      "backslashEscapesHTMLTags": false,
      "emoji": true,
      "underline": true,
      "completeHTMLDocument": false,
      "metadata": true,
      "splitAdjacentBlockquotes": true

    this._browseDoc=this.props.node

  onTabActivated:(value) ->
    if this.props.node.tabPinned
      this.browseDoc=this.props.node
    if this.props.node.mimeType is 'text/pandora-requirement'
      this.ComponentHub.PageIndex.showRightPane=true
      if this.ComponentHub.PageIndex.rightPaneSplitter is 0
        this.ComponentHub.PageIndex.rightPaneSplitter = 40
    else
      this.ComponentHub.PageIndex.showRightPane=false


  ###* Generates a markdown renderer to render the current text for Browsing
      * @method generateShowDownTag
      * @return {string} HTML Code
      ###
  generateBrowsingTags: ->

    text = this._browseDoc.content# this.props.node.content
    ext = { extensions: [@targetlink,@icsExtension,@imageReplace] }
    options = {ext..., this.sdOptions...}
    converter = new showdown.Converter(options)
    return converter.makeHtml(text)

  ###* Generates a markdown renderer to render the current text for Preview
      * @method generateShowDownTag
      * @return {string} HTML Code
      ###
  generateShowDownTag: ->
    text = this.props.node.content
    ext = { extensions: [@targetlink,@icsExtensionNoLink,@imageReplace] }
    options = {ext..., this.sdOptions...}
    converter = new showdown.Converter(options)

    return converter.makeHtml(text)

  ###* Scans the rendered text and inserts click handler for very #icsXXX found
    * @description Renders the Webpage for Preview without working Links !
    * @method icsExtension
    ###
  icsExtensionNoLink: ->
    return [
      {
        type: 'lang',
        regex: '\\B(\\\\)?#([\\S]+)\\b',
        replace: (match, leadingSlash, tag) =>
            # Check if we matched the leading \ and return nothing changed if so
          if leadingSlash is '\\'
            return match
          else
            name = this.props.node.project.refNameById tag
            if name.endsWith '.req'
              name = name[..-5]
            return "<a href='#' onclick=\"return icsClicked(\'#{tag}\')\">#{tag}</a> <strong> #{name}</strong> "
      }
    ]
  ###*
   * Replaces all img src urls with the b64 encoded picture data
   * the first regex finds all img tags
   * within the replace functionthe next regex finds the src attributes value
   * we get the image data via this url and the match.replace replaces the url with the b64 blob
   * @return {Array}
  ###
  imageReplace: ->
    return [
      {
        type: 'output',
        regex: '<img [^>]*src="[^"]*"[^>]*>',
        replace: (match) =>
          regex = /<img.*?src="(.*?)"/
          src = regex.exec(match)[1]
          pic = this.ComponentHub.FileSystemTree.findResourceByCachedPath src
          if pic?
            CONTENT = pic.getContent()
            return match.replace(/(img[^>]*src=['"])+(\s*)[^'"]*/g, "$1#{CONTENT}")
          return match

      }
    ]

  ###* A click handler when the user clicks an #icsXXX Fragment in the rendered text
    *
    * @method icsClicked
    * @param {string} ics
    * @see icsExtension
    ###
  prefixClicked: (ics) ->
    debugger
    this._browseDoc = this.ComponentHub.loadedPlugins.pandora.folders[this._browseDoc.projectName].docByIcs ics
    this.ComponentHub.loadedPlugins.pandora.pageBrowsed this._browseDoc
    #this.ComponentHub.PageIndex.selectNode doc
    return false

  ###* Scans the rendered text and inserts click handler for very #icsXXX found
    *
    * @method icsExtension
    * @see icsClicked
    ###
  icsExtension: ->
    return [
      {
        type: 'lang',
        regex: '\\B(\\\\)?#([\\S]+)\\b',
        replace: (match, leadingSlash, tag) =>
            # Check if we matched the leading \ and return nothing changed if so
          if leadingSlash is '\\'
            return match
          else
            name = this.props.node.project.refNameById tag
            if name.endsWith '.req'
              name = name[..-5]
            return "<a href='#' onclick=\"return prefixClicked_#{this.props.node.ino}(\'#{tag}\')\">#{tag}</a> <strong> #{name}</strong> "

      }
    ]

  targetlink: ->
    return [{
    type: 'html',
    filter: (text) =>
        return (''+text).replace(/<a\s+href="/gi, '<a target="_blank" href="')
  }]

  mounted: ->
    super()
    prefix = "prefixClicked_#{this.props.node.ino}"
    window[prefix]=@prefixClicked
    this.ComponentHub.activeContainer = this
    this.onTabActivated()
  unmounted: ->
    super()
    delete window["prefixClicked_#{this.props.node.ino}"]
    #showdown.setFlavor('github')




