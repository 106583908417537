import CompositionBase from 'core/CompositionBase.coffee'
###* Props definition
  * @typedef {Object}  ImageviewerProps
  * @property {string} instancename - Our Instancename for registration in ComponentHub
  ###


###*
* @augments CompositionBase
###
export class Imageviewer extends CompositionBase
  ###*
    * @param {Object} options
    * @property {ImageviewerProps} props
    * @property {Function} emit
    * @property {object} refs
    * @property {object} quasar
    * @constructor
    ###
  constructor: (options) ->
    super(options)
    this.resource = options.props.resource
    this.compareWith = options.props.compareWith
    this.imageData= null
    this.imageCompareData= null
    this.showContent= false
    this.showCompareContent= false
    this.compareName=""
    this.compareVersion=""

  changeRevision: (resource) ->
    this.showContent = false
    this.resource=resource
    this.imageData = await this.resource.getDisplayableFromContent()
    this.showContent = true


  changeCompare: (newResource) ->
    this.compareWith=newResource
    this.showContent = false
    if newResource
      this.showCompareContent = false
      this.imageCompareData = await newResource.getDisplayableFromContent()
      this.compareName=newResource.name
      this.compareVersion=newResource.getHumanReadableVersion()
      this.showCompareContent = true
    this.changeRevision this.resource
