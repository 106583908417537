import CompositionBase from 'core/CompositionBase.coffee'
import {apiGetUsers} from 'apis/improveREST/rawImprove.coffee'

###* Props definition
  * @typedef {Object}  UserlistProps
  * @property {string} instancename - Our Instancename for registration in ComponentHub
  ###


###*
* @augments CompositionBase
###
export class Userlist extends CompositionBase
  ###*
    * @param {Object} options
    * @property {UserlistProps} props
    * @property {Function} emit
    * @property {object} refs
    * @property {object} quasar
    * @constructor
    ###
  constructor: (options) ->
    super(options)
    this.userList= []

  getAllUsers: () ->
    result = await this.ComponentHub.activeServer.doQuery apiGetUsers()
    @userList = result.data

  onLeft:(reset,user) ->
    if this.emit? then this.emit('onSwipeLeft',user)
    this.finalize(reset)

  onRight:(reset,user) ->
    if this.emit? then this.emit('onSwipeRight',user)
    this.finalize(reset)

  finalize: (reset) ->
    clearTimeout(timer)
    timer = setTimeout(() =>
      reset()
    , 500)

  mounted: ->
    super()
    this.getAllUsers()
