import CompositionBase from 'core/CompositionBase.coffee'
###* Props definition
  * @typedef {Object}  PropertiesProps
  * @property {string} instancename - Our Instancename for registration in ComponentHub
  ###


###*
* @augments CompositionBase
###
export class Properties extends CompositionBase
  ###*
    * @param {Object} options
    * @property {PropertiesProps} props
    * @property {Function} emit
    * @property {object} refs
    * @property {object} quasar
    * @constructor
    ###
  constructor: (options) ->
    super(options)
    this.columns = [
      { name: 'caption', required: true,label: 'Property', align: 'left', field: 'caption', sortable: true},
      { name: 'value',  label: 'Value', field: 'value', align: 'left',sortable: true }
    ]
    this.records=[]
    this.resource=options.props.resource
    this.displayfields=options.props.displayfields
    this.captionMapping=options.props.captionMapping

  createRecords: () ->
    retval = []
    if this.resource?
      for field in this.displayfields
        if field of this.captionMapping then caption = this.captionMapping[field] else caption = field
        switch field
          when 'createdAt' then val = this.resource.createdAtFmt
          when 'lastModifiedOn' then val = this.resource.lastModifiedFmt
          when 'lockedAt'  then val = this.resource.lockedAtFmt
          when 'fileSize'  then val = this.resource.fileSizeFmt
          else
            if this.resource.hasOwnProperty(field)
              val = this.resource[field]
            else
              val = ''
        retval.push {caption:caption,value:val}
    this.records=retval
    return retval

  mounted:->
    this.records = this.createRecords()
