import { nextTick } from "vue"
import {FileSystemBrowser} from "components/FileSystemBrowser/FileSystemBrowserCls.coffee"
import getImproveClass from "components/ImproveBrowser/ImproveClasses.coffee"
import {ImproveResource} from "./resourceTypes/ImproveResource.coffee"
import {apiGetRootDirWithMeta, apiGetResourceWithMeta,apiNewFolder} from "apis/improveREST/rawImprove.coffee"


export class ImproveBrowser extends FileSystemBrowser
  constructor: (options) ->
    super(options)
    this.records= []
    initial = {path: "", content:""}
    ###* @ts-ignore ###
    this.lastClickedRow= new ImproveResource(initial)
    this.context="Improve"
    this.watcherReady=false


  createTitleActions: ->
    return [
      {
        name:"refresh"
        highlight: false
        hidden: false
        disabled: !this.records.length
        icon:"o_restart_alt",
        tooltip:"Refresh (Hold alt to refresh the root folder)",
        click: (evt) => this.refresh(evt)

      },
      {
        name:"newFile"
        highlight: false
        hidden: this.ComponentHub.activePlugin.name is 'review'
        disabled: !this.records.length or this.ComponentHub.activePlugin.name is 'review'
        icon:"o_note_add",
        tooltip:"New File ",
        click: () =>
          this.onMenuNewFile()

      },
      {
        name:"newFolder"
        highlight: false
        hidden: this.ComponentHub.activePlugin.name is 'review'
        disabled: !this.records.length or this.ComponentHub.activePlugin.name is 'review'
        icon:"o_create_new_folder",
        tooltip:"New Folder",
        click: () =>
          this.onMenuNewFolder()

      }
    ]

  refresh: (evt) ->
    if this.lastClickedRow? and this.lastClickedRow.resourceId.length and not evt.altKey
      this.lastClickedRow.onExpand()
    else
      if this.startPath is "/"
        this.ComponentHub.activeServer.fetchData apiGetRootDirWithMeta()

  newFileObject: (isDir) ->
    @newFileObjectIsDir = isDir
    @newFileObjectParent = @lastClickedRow
    if !@lastClickedRow.isDir
      @newFileObjectParent = @getParentFromRow(@lastClickedRow)
    @newFileObjectDialog = true
    return

  newFileDialogCloseOK: ->

    filename = @newFileObjectName
    if @newFileObjectIsDir
      await this.ComponentHub.activeServer.doQuery apiNewFolder @newFileObjectParent.resourceId, filename, 'Folder',''
    else
      await this.ComponentHub.activeServer.doQuery apiNewFolder @newFileObjectParent.resourceId, filename, 'File',''
    await this.ComponentHub.refreshFolder @newFileObjectParent.resourceId
    @newFileObjectDialog = false
    return


  ###* toggles expanded state for given id
    *
    * when collapsing also all kids are collapsed
    * @param {FileSystemFile} row
    ###
  toggleExpanded: (row) ->
    ###* @ts-ignore ###
    this.lastClickedRow = row
    thisId = row[this.rowKey]
    index = this.expanded.indexOf thisId
    if index >= 0  #It is expanded, so we have to collapse
      allInThatBranch = this.findChildren thisId
      allInThatBranch.push thisId
      for id in allInThatBranch
        subIndex = this.expanded.indexOf id
        if subIndex >= 0
          this.expanded.splice subIndex, 1
      @allAreExpanded = false
      this.emit('onCollapse', thisId)
    else

      if row.isFolderish
        row.onExpand()
        this.expanded.push thisId
        this.emit('onExpand', thisId)

      this.refs.vueTrigger +=1
    return

  ###* returns all children instances of a given rowKey in data, returns them in an array
    *
    * @param {string} id the resourceID of the folder
    * @param {boolean} [infinite] default true searches recursive the whole structure. If false, only direct kids are returned
    * @return {FileSystemFile[]}  an array of resourceIds is returned
    ###
  getAllReviewChildren: (id, infinite = true) ->
    # finds all children of a given rowKey in data, returns all their rowKeys in an array
    kids = (resource for resource in this.records when resource.treeParentId is id)
    if infinite
      grandKids = []
      for kid in kids
        grandKids = [grandKids..., this.getAllChildren(kid.resourceId, true)...]
      return [kids..., grandKids...]
    else
      return kids

  ###* Is emitted when the user clicks on a row (not when expanding/collapsing) it)
    *
    * It also emits an event with the same name
    * @param {number} index  the current index in the records array
    * @param {object} row  The resource itself
    * @param {Object} col  The column Object
    ###
  onRowClick: (index, row, col,evt) ->
    if evt?
      if evt.altKey
        if row.hasOwnProperty "metadata"
          if row.metadata.length
            plugin = (md for md in row.metadata when md.descriptorName is "WebPlugin")
            if plugin.length
              pluginToLoad= plugin[0].lovText
              if globalThis.loadedPlugins.hasOwnProperty pluginToLoad
                wsConfig =
                  workspacename: "#{row.name}_#{row.entityVersionId}"
                  host: this.ComponentHub.activeServer.config.improveHost
                  api: this.ComponentHub.activeServer.config.improveApi
                  repo:this.ComponentHub.activeServer.config.improveRepo
                  root: row.resourceId
                  user: this.ComponentHub.activeServer.user
                  password: this.ComponentHub.activeServer.password
                globalThis.loadedPlugins[pluginToLoad].openWorkSpaceFromConfig wsConfig
                console.log "PluginToSwitch",  pluginToLoad, wsConfig
                return
    this.lastClickedRow = row
    @selectedRows = [row]
    res = this.getResourceFromRow(row)
    if res.virtual
      return
    await res.onDeepLoad()
    if this.ComponentHub.isDesktop
      this.ComponentHub.AppBridge.setMenu('FileNew', true)
      this.ComponentHub.AppBridge.setMenu('FileNewFolder', true)
      this.ComponentHub.AppBridge.setMenu('FileDelete', true)
    this.emit('onRowClick', res, index, row, col)
    this.traverseTreeForParentTypes row[this.rowKey]


  ###* Called from the system when something is dropped
    * @param {FileSystemFile|ImproveResource} targetRessource - the row over which which the drop occurs
    * @param {object} e - the event object
    ###
  onDrop: (targetRessource, e) ->
    console.clear()
    if e.altKey
      operation = 'copy'
    else if e.ctrlKey
      operation = 'link'
    else
      operation = 'move'
    e.preventDefault()
    e.target.parentElement.classList.remove('drag-enter')
    e.target.classList.remove('drag-enter')
    # don't drop on own parent
    rawData = e.dataTransfer.getData('text')
    dragdata = JSON.parse(rawData)
    sourceRowKey = dragdata.resource
    sourceRow = @findResourceByIds(sourceRowKey)[0]
    if sourceRow[@parentKey] is targetRessource[@rowKey]
      e.dataTransfer.dropEffect = 'none'
      return
    ###
    if sourceRow.hasOwnProperty('className')
      if sourceRow.className is "ReviewEntry"
        e.dataTransfer.dropEffect = 'none'
        alert("Objects in Reviews may not be moved around")
        return
    if targetRessource.hasOwnProperty('className')
      if targetRessource.className is "ReviewEntry"
        e.dataTransfer.dropEffect = 'none'
        alert("Objects in Reviews may not be modified")
        return
    ###

    # check if original parent node
    if sourceRow[@rowKey] is targetRessource[@rowKey]
      return
    #make the exchange but recheck

    targetDir = targetRessource
    if !targetRessource.isDir
      targetDir = @findResourceByIds(targetRessource[this.parentKey])[0]
    switch operation
      when 'copy'
        ###* @ts-ignore ###
        if await targetRessource.copyFrom(sourceRow,null)
          await @refreshFolder targetRessource, false
      when 'link'
        ###* @ts-ignore ###
        this.ComponentHub.$quasar.notify({
          message: 'Linking is not yet implemented',
          color: 'orange'
          position: 'center'
        })
      when 'move'
        ###* @ts-ignore ###
        if await targetRessource.moveFrom(sourceRow,null)
          await @refreshFolder sourceRow, true
          await @refreshFolder targetRessource, false
    return

  ### Called by confirmation dialog when user actually confirms deletion
    *
    ###
  doDelete: ->
    this.ComponentHub.FS.pathDelete @lastClickedRow.path

  setLoading: ->
    this.updateCounterBadge = false
    this.refs.vueTrigger +=1
    console.log "set"

  unsetLoading: ->
    this.updateCounterBadge = true
    nextTick =>
      this.refs.vueTrigger +=1
      console.log "unset"


  ###* Called by Menu new project via Preload
    *
    * Here we change the root path of the folder we display
    * @param {string} newRoot The root directory of the structure to display
    * @param {string} rootKey The inode of the root file
    * ###
  onRootDirChanged: (newRoot, rootKey, index) ->
    try
      this.updateCounterBadge = false
      callBacks =
        newRecord: this.onNewRecord
        moveRecord: this.onMoveRecord
        delRecord: this.onDelRecord
        changeRecord: this.onChangeRecord
        watcherReady: this.onWatcherReady
      this.ComponentHub.activeServer.setFSCallBacks(callBacks)
      this.FSWcallBacks = callBacks
      this.indexFilePresent = this.ComponentHub.openActiveIndex()
      this.watcherReady=false
      this.startPath = newRoot
      this.startSortParentKey = rootKey
      this.records = []
      this.internalTitle = newRoot.split("/")[-1..][0]
      this.signalBadgeColor = "orange"

      if this.ComponentHub.isDesktop
        this.ComponentHub.AppBridge.setMenu('FileNew', false)
        this.ComponentHub.AppBridge.setMenu('FileNewFolder', false)
        this.ComponentHub.AppBridge.setMenu('FileDelete', false)
      this.refs.vueTrigger +=1
    catch e
      console.error "On RootDirChanged", e
    return

  ###* Called by Filesystemwatcher when a file has been detected
    *
    * @param {object} rec FileSystemObject Raw record
    ###
  onNewRecord: (rec) ->
    try
      if rec.hasOwnProperty("iWebType")
        index = this.records.findIndex((x) => x[this.rowKey] is rec[this.rowKey])
      else
        index = this.records.findIndex((x) => x[this.rowKey] is rec.resourceId)
      if index >= 0
        # if present, replace it
        this.records[index].update rec
        if @watcherReady
          this.emit('onChangedRecord',this.records[index])
          this.refs.vueTrigger +=1
      else
        if rec.hasOwnProperty("iWebType")
          # We are not triggered from the REST API, we are triggered from inside with an already instanciated object
          record = rec
        else
          fileClass = getImproveClass(rec)
          record = new fileClass rec
          parent = this.getResource record.parentId
          if parent?
            record.parent = parent
        this.records.push record
        if @watcherReady
          this.emit('onNewRecord',record)
          this.refs.vueTrigger+=1
      if this.records.length is 1 and this.startPath isnt '/'
        this.toggleExpanded this.records[0]
        this.onRowClick 0, this.records[0],0,null
    catch e
      console.error "onNewRecord", e
    return

  ###* Called by Filesystemwatcher when a file has been changed
    *
    * @param {object} rec FileSystemObject Raw record
    ###
  onChangeRecord: (rec) ->
    try
      console.log "change", rec.name
      if rec.hasOwnProperty("iWebType")
        index = this.records.findIndex((x) => x[this.rowKey] is rec[this.rowKey])
      else
        index = this.records.findIndex((x) => x[this.rowKey] is rec.resourceId)
      if index >= 0
        # if present, replace it
        this.records[index].update rec
        if @watcherReady
          this.emit('onChangedRecord',this.records[index] )
          this.refs.vueTrigger +=1
      else
        if rec.hasOwnProperty("iWebType")
          # We are not triggered from the REST API, we are triggered from inside withan already instanciated object
          record = rec
        else
          fileClass = getImproveClass(rec)
          record = new fileClass rec
        this.records.push record
        if @watcherReady
          this.emit('onNewRecord',record)
          this.refs.vueTrigger +=1

    catch e
      console.error "onChangeRecord", e

    return

  traverseTreeForParentTypes: (id) ->
    types = []
    index = this.records.findIndex((x) => x[this.rowKey] is id)
    types.push this.records[index].iWebType
    parentId = this.records[index][this.parentKey]
    while index >= 0
      index = this.records.findIndex((x) => x[this.rowKey] is parentId)
      if index >= 0
        if this.records[index].hasOwnProperty('iWebType')
          types.unshift this.records[index].iWebType
        try
          parentId = this.records[index][this.parentKey]
        catch e
          index =-1


  ###* Finds row with given resourceId or entityId and if not already present, loads it and all of its parents
    *
    * @param {string} rowKey the Id to search for. Maybe a resourceId or entityId in improveWeb
    * @return {object}
    ###
  forceResource: (rowKey) ->
    res = this.getResource rowKey
    if res is null
      res = await this.ComponentHub.activeServer.fetchData apiGetResourceWithMeta rowKey
      if res
        parentKey = res.parentId
        while this.getResource(parentKey) is null
          parent = await this.ComponentHub.activeServer.fetchData apiGetResourceWithMeta parentKey
          if parent
            parentKey = parent.parentId
          else
            break
        return res
      return null
    return res





    return null



  mounted: () ->
    super()
    this.records = []
    this.visibleCols = this.props.visibleCols
    this.sortAliases()


    if this.ComponentHub.isDesktop
      this.ComponentHub.AppBridge.on('menuDeleteFile', @onMenuDeleteFile)
      this.ComponentHub.AppBridge.on('menuNewDir', @onMenuNewFolder)

