import { boot } from 'quasar/wrappers'
import ComponentHub from "core/componentsHub.coffee"
import {BlitzForm,BlitzListForm, BlitzTable} from 'blitzar'




builtinPlugins = null
# process.env.SCINTECO_PLUGINS is automatically set in the build process ( quasar.conf.js -> build -> env)
# and depends on which plugins have been linked into the /plugins folder at compile time
try
  builtinPlugins = process.env.SCINTECO_PLUGINS
  builtinPlugins = JSON.parse(builtinPlugins)
catch e
  alert "No Plugins"


###*
 * retrieves all wanted  plugins via url parameter
 * in case of localhost (dev mode) when plugins is not set in the url, all plugins will be loaded
 * set plugins to "all" t load all available plugins in any case
 * @return {array}
###
getPluginParams = () ->
  queryString = window.location.search
  pluginPath = window.location.pathname
  urlParams = new URLSearchParams(queryString) # e.g.	?plugin=review&wksType=review&workspacename=test&host=https://repo-a.env30.scintecodev.com:8443&api=/repository/api/v1&repo=/repository/repository&root=D7ED94787BAA44EEA36C1F6DADB43508
  # when a plugin url parameter is provided, this overrules the url path
  plugins = urlParams.get('plugin') || ''
  pathPlugin = ""
  if plugins is ''
    # if no plugin parameter is provided we hav a look at the path e.g. /review/ or if no path is here it would be /
    if pluginPath is '/'
      # no url parameter, and the app is installed in the web root, which is only the case at internal servers
      plugins='all' # we load all plugins for our own comfort
      ComponentHub.isDebugVersion = true
    else
      # make review-d from /review-d/#/
      pluginPath = pluginPath.replace(/[^a-zA-Z0-9- ]/g, '')
      if pluginPath.endsWith('-d')
        # a debug version is loaded which is indicated by suffix -d like /review-d/
        plugins = pluginPath[0..-3]
        pathPlugin = pluginPath[0..-3]
        ComponentHub.isDebugVersion = true
      else
        # production version which is just /review/
        plugins = pluginPath
        pathPlugin = pluginPath
        ComponentHub.isDebugVersion = false
  if plugins is 'all' or ComponentHub.isDesktop # or (plugins is '' and (window.location.origin.indexOf('localhost') > 0 || window.location.origin.indexOf('127.0.0.1') > 0  ))
    pluginsToLoad= Object.keys(builtinPlugins )
  else
    pluginsToLoad = plugins.split(',')
  wksType = urlParams.get('wksType') || ''
  if wksType.length
    if pluginsToLoad.indexOf(wksType) >= 0
      ComponentHub.startFromUrl = wksType
  else
    # in case the user dropped all url paramters, we catch him here and start with the pluginname taken from the url
    if pathPlugin.length
      ComponentHub.startFromUrl = pathPlugin


  return pluginsToLoad
console.log "Desktop mode: ",ComponentHub.isDesktop
plugins = []
# check whether all requeted plugins are available and is so, put them into the plugins list
for plugin in getPluginParams()
  if builtinPlugins.hasOwnProperty(plugin)
    plugins.push(plugin)


# @ts-ignore
export default boot( ( { app } ) =>
  globalThis.app = app
  app.component('BlitzForm', BlitzForm)
  app.component('BlitzListForm', BlitzListForm)
  app.component('BlitzTable', BlitzTable)
  # define a global object to hold plugin Instances
  globalThis.loadedPlugins ={}
  globalThis.loadedPluginComponents ={}
  #Load Settings must happen here, since ComponentHub is a singleton module and can't call async methods from toplevel outside methods
  await ComponentHub.dbInitStore "globalApp"
  ComponentHub.globalAppSettings = await ComponentHub.loadGlobalSettings()
  for pluginName in plugins
    # pluginnames have to be lowercase
    # they have to reside in plugins in folder which matches (lowercase) the pluginname
    # inside the folder there has to be a class naed like the plugin but 1st letter capitalized
    # so when plugin is named 'pandora' the classFile and the has to be 'Pandora'
    # within the classfile this class has to be exported as 'PluginMain'
    # e.g. export {Pandora as PluginMain} in the last line
    # compose the Component nmae (make 1st Letter Uppercase)
    componentName = pluginName[0].toUpperCase() + pluginName.slice(1)
    # If we are NOT running in electron we only allow plugins which are not explictly marked as i.need.electron
    if builtinPlugins[pluginName].electron and process.env.MODE isnt 'electron'
      console.warn "Skipping plugin #{pluginName}, because it only can be used in desktop mode"
    else
      # Initialize plugin settings/workspace/index store happens here. Access to indexedDB is async and await is not allowed in constructors
      await ComponentHub.dbInitStore pluginName
      if process.env.PROD
        {PluginMain} = await import(### webpackMode: "eager" ### "plugins/#{pluginName}/#{componentName}.coffee")
      else
        {PluginMain} = await import("plugins/#{pluginName}/#{componentName}.coffee")

      #{PluginMain} = await import("plugins/#{pluginName}/#{componentName}.coffee")
    # instanciate it
      pluginInstance = new PluginMain()
      # put it in globalThis
      globalThis.loadedPlugins[pluginName] = pluginInstance
      ComponentHub.loadedPlugins[pluginName] = pluginInstance

)


###
  Valid Magic Comments https://webpack.js.org/api/module-methods/#dynamic-expressions-in-import
  ----------------------
  webpackInclude: /\.json$/
  webpackExclude: /\.noimport\.json$/
  webpackChunkName: "my-chunk-name"
  webpackMode: "lazy" | "eager" | weak
  webpackPrefetch: true | 0,1,2,etc
  webpackPreload: true | 0,1,2,etc
  webpackExports: ["default", "named"]
  webpackIgnore: true
###
