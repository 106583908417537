
import CompositionBase from 'core/CompositionBase.coffee'
###* Props definition
  * @typedef {Object}  DataformProps
  * @property {string} instancename - Our Instancename for registration in ComponentHub
  ###


###*
* @augments CompositionBase
###
export class Dataform extends CompositionBase
  ###*
    * @param {Object} options
    * @property {DataformProps} props
    * @property {Function} emit
    * @property {object} refs
    * @property {object} quasar
    * @constructor
    ###
  constructor: (options) ->
    super(options)
    this.rdrNumber = ''
    this.roNumber = ''
    this.experimentName = ''
    this.experimentDate = ''
    this.respScientist = ''
    this.collabScientist = ''
    this.qualityTestRun = false

