import { Notify, Dark } from 'quasar'
import {reactive} from 'vue'
import {depthTreeSort} from './treesorting.coffee'
import getFileClass from "./FileClasses.coffee"
import {FileSystemFile} from "./FileSystemFile.coffee"
import CompositionBase from "core/CompositionBase.coffee"

###* Props definition
  * @typedef {Object} FileSystemBrowserProps
  * @property {string} instancename - Our Instancename for registration in ComponentHub
  * @property {string} toptitle - Title to display above
  * @property {string} childIndicator - which field in the rest call result indicates the resource has children
  * @property {string} rowKey - what is the unique key field
  * @property {string} parentKey - the name of the field referencing the parent key
  * @property {number} screenOffset - height of the ui component
  * @property {string} width - width of the ui component
  * @property {object[]} displaycolumns - array of columns to display
  * @property {string[]} visibleCols - columns are currently visible
  * @property {boolean} recursive - which field in the rest call result indicates the resource has children
  * @property {Function} readHook - optional hook function which is called for every resource with the resource before it is added/updated in the table
  * @property {boolean} fileSystemMode - Are we working in FS Mode or Rest Mode
  ###

passthroughTypes = ["Review"]

export class FileSystemBrowser extends CompositionBase
  #region Getters
  ###* virtual Field (Getter) returns the cssVars
    * Defines css Variables to be set to any Object via style attribute
    * After that they can be used with var(--name) in css
    * @name FileSystemBrowser#cssVars
    * @type {string} cssVars
    ###
  Object.defineProperty this::, "cssVars",
    get: ->
      # provides dynamic css vars depending on several environmental stuff (like dimensions or dark)
      {
        '--bgColor': if @quasar.dark.isActive then '#1d1d1e' else '#ffffff'
        '--height': "calc(100vh - #{@props.screenOffset + 100}px)"
        '--width': this.props.width
      }
  ###* virtual Field (Getter) returns the tableTitle
    * Returns the title of the Tree
    * @name FileSystemBrowser#tableTitle
    * @type {string}
    ###
  Object.defineProperty this::, "tableTitle",
    get: ->
      unless @props.toptitle
        return @internalTitle
      return @props.toptitle

  ###* virtual Field (Getter) returns the recordCount
    * Returns the number of files in the tree
    * @name FileSystemBrowser#recordCount
    * @type {number}
    ###
  Object.defineProperty this::, "recordCount",
    get: ->
      if @updateCounterBadge
        return @records.length
      else
        return '-'
  ###* virtual Field (Getter) returns the breadCrumbPath
    * Returns the path of the last clicked item as breadcrumbs
    * @name FileSystemBrowser#breadCrumpPath
    * @type {string}
    ###
  Object.defineProperty this::, "breadCrumpPath",
    get: ->
      if @lastClickedRow.cachedPath?
        if @lastClickedRow.isDir
          return @lastClickedRow.cachedPath.split('/')
        else
          return @lastClickedRow.cachedPath.split('/')[0..-2]
      return '/'
  #endregion

  ###*
   * @param {Object} options
   * @property {FileSystemBrowserProps props
   * @property {Function} emit
   * @property {object} refs
   * @property {object} quasar
   * @constructor
  ###
  constructor: (options) ->
    super()
    @props = options.props
    @emit = options.emit
    @refs = options.refs
    @quasar = options.quasar
    this.rowKey = this.props.rowKey
    this.parentKey = this.props.parentKey
    this.startPath= '/'
    this.records= []
    this.expanded= []                   # holds all rowKeys of expanded rows (parents)
    this.filter= ''                     # the current search filter
    this.sortAlias= {}
    initial = {path: "", content:""}
    this.lastClickedRow= new FileSystemFile(initial)     # reference for the last row the user clicked on
    this.visibleColumns= []
    this.startSortParentKey= '/'        # internal used for Sorting
    this.selectedRows= []
    this.smallestPath= '/'              # this is used to determine the leftPadding CSS
    this.allAreExpanded= false
    this.internalTitle=''
    this.signalBadgeColor= "red"
    this.updateCounterBadge= true
    this.confirmDelete= false
    this.newFileObjectDialog= false
    this.newFileObjectIsDir= false
    this.newFileObjectParent= "/"
    this.newFileObjectName= ""
    this.watcherReady=false
    this.indexFileName=''
    this.indexFilePresent=false
    this.FSWcallBacks =
      newRecord: this.onNewRecord
      moveRecord: this.onMoveRecord
      delRecord: this.onDelRecord
      changeRecord: this.onChangeRecord
      watcherReady: this.onWatcherReady
    this.lastSortOrder = 'name'
    this.lastSortDescending = false
    this.context="FileSystem"

  ###
  binder: ->
    this.onRootDirChanged=this.onRootDirChanged.bind(this)
    this.onNewRecord=this.onNewRecord.bind(this)
    this.onMoveRecord=this.onMoveRecord.bind(this)
    this.onDelRecord=this.onDelRecord.bind(this)
    this.onChangeRecord=this.onChangeRecord.bind(this)
    this.onWatcherReady=this.onWatcherReady.bind(this)
    this.onRootDirChanged=this.onRootDirChanged.bind(this)
    this.treeSort=this.treeSort.bind(this)
  ###
  createTitleActions: ->
    return [
      {
        name:"newFile"
        highlight: false
        hidden: false
        disabled: !this.records.length
        icon:"o_note_add",
        tooltip:"New File ",
        click: () =>
          this.onMenuNewFile()

      },
      {
        name:"newFolder"
        highlight: false
        hidden: false
        disabled: !this.records.length
        icon:"o_create_new_folder",
        tooltip:"New Folder",
        click: () =>
          this.onMenuNewFolder()

      }
    ]



  formatDate: (val, row) ->
    new Date(parseInt(val, 10)).toLocaleString()


  onMenuNewFile: ->
    if this.ComponentHub.activePlugin.name is 'pandora'
      this.ComponentHub.PageIndex.newFileDialogOpen()
    else
      @newFileObject false



  onMenuNewFolder: ->
    @newFileObject true

  newFileObject: (isDir) ->
    @newFileObjectIsDir = isDir
    @newFileObjectParent = @lastClickedRow.path
    if !@lastClickedRow.isDir
      @newFileObjectParent = @getParentFromRow(@lastClickedRow).path
    @newFileObjectDialog = true
    return

  newFileDialogCloseOK: ->
    if this.ComponentHub.activePlugin.name is 'pandora'
      filename = @newFileObjectParent + '/' + @newFileObjectName
      if @newFileObjectIsDir
        this.ComponentHub.FS.mkDir(filename)
      else
        this.ComponentHub.FS.writeFile(filename, '', 'utf-8')
    @newFileObjectDialog = false
    return

  newFileDialogCloseCancel: ->
    @newFileObjectDialog = false
    return




  ###*
    * # TreeSort
    * Sorts the table levelwise. The sorting happens only within one hierarchy level
    * called by the table widget. This method takes care of hierarchy and expanded states of the tree
    * @param {Array} rows all rows to be sorted Array of ImproveResources
    * @param {string} sortBy he column to sort after
    * @param {boolean} descending true for descending, false for ascending
    * @return {Array} the sorted array
    ###
  treeSort: (rows, sortBy, descending) ->
    this.lastSortOrder = sortBy
    this.lastSortDescending = descending
    data = [rows...]
    # We first check of the sorting should be based on another field by using the sortAlias Map
    if sortBy
      if sortBy of this.sortAlias
        sortBy = this.sortAlias[sortBy]
    asc = (a, b) ->
      # our plain sorting algorithm
      # change any sort logic only here
      if a[sortBy] is b[sortBy]
        return 0
      if a[sortBy] < b[sortBy]
        return -1
      return 1

    desc = (a, b) ->
      # for descending just reverse parameters
      return asc(b, a)
    # if there is any sorting requested
    if sortBy
      if descending
        depthTreeSort data, desc, this.rowKey, this.parentKey, this.startSortParentKey
      else
        depthTreeSort data, asc, this.rowKey, this.parentKey, this.startSortParentKey
    return data

  ###*
    * calculates whether the given row should be shown or not at the moment.
    * Needed to hide rows which parents are collapsed at the moment
    * It is always true if either
    * - the rowKey (resourceId) is equal the startPath (thats the case when a single resourceId should be the root)
    * - the row.path is equal the startPath (thats the case when a single passed path should be the root)
    * - the parent key of the row is / or is null or is equal the startPath (normal full browser node and the row is a root based folder
    * - or the parent node is expanded
    * @param {FileSystemFile}  row
    * @return {boolean}
    ###
  isRowVisible: (row)->
    #"props.row[parentKey] === '/' || expanded.indexOf(props.row[parentKey]) >= 0"
    return row[this.parentKey] is '0' or
    row[this.rowKey] is this.startPath or
    row.cachedPath is this.startPath or
    row[this.parentKey] is '/' or
    row[this.parentKey] is this.startPath or
    row[this.parentKey] is null or
    row[this.parentKey] is '' or
    row[this.parentKey] is this.startSortParentKey or
    this.expanded.indexOf(row[this.parentKey]) >= 0

  ###* depending on the given path, this method calculates the padding needed for the first column in a collapsible tree
    *
    * @param {string} path
    * @return {string}  a css directive
    ###
  setPadding: (path) ->
    try
      return "padding-left: #{(path.split('/').length - this.smallestPath.split('/').length) * 10}px;"
    catch e
      return ''

  ###* Determines the left icon for the tree depending on the given resource
    *
    * @param {FileSystemFile} row
    * @return {string}  an icon name
    ###
  getExpandIcon: (row) ->
    if row.isFolderish and this.expanded.indexOf(row[this.rowKey]) >= 0
      return 'arrow_drop_down'
    if row.isFolderish and this.expanded.indexOf(row[this.rowKey]) == -1
      return 'arrow_right'
    return 'mdi-file-document-outline'

  expandAll: ->
    if !@allAreExpanded
      dirs = (rec for rec in @records when rec.isFolderish)
      for row in dirs
        thisId = row[this.rowKey]
        this.expanded.push thisId
      @allAreExpanded = true

  collapseAll: ->
    if @allAreExpanded
      this.expanded = []
      @allAreExpanded = false


  ###*
    * queries expanded state for given id
    * @param {FileSystemFile} row
    ###
  isExpanded: (row) ->
    thisId = row[this.rowKey]
    index = this.expanded.indexOf thisId
    if index >= 0
      return true
    return false


  ###* toggles expanded state for given id
    *
    * when collapsing also all kids are collapsed
    * @param {FileSystemFile} row
    ###
  toggleExpanded: (row) ->
    this.lastClickedRow = row
    thisId = row[this.rowKey]
    index = this.expanded.indexOf thisId
    if index >= 0  #It is expanded, so we have to collapse
      allInThatBranch = this.findChildren thisId
      allInThatBranch.push thisId
      for id in allInThatBranch
        subIndex = this.expanded.indexOf id
        if subIndex >= 0
          this.expanded.splice subIndex, 1
      @allAreExpanded = false
      this.emit('onCollapse', thisId)
    else
      if row.isFolderish
        this.expanded.push thisId
        this.emit('onExpand', thisId)
    return

  statusColumnClicked: (row) ->
    return ''

  ###* must be called when mounted.
    * creates a map containing those colnames which should be sorted by other cols
    * when a displaycolumn contains a sortfield property, this column will be sorted by that field instead by itself
    * useful when a column is displayed but the sorting should be done by another, not displayed column instead
    ###
  sortAliases: ->
    this.sortAlias = {}
    replaces = (col for col in this.props.displaycolumns when col.hasOwnProperty('sortfield'))
    for col in replaces
      this.sortAlias[col.name] = col.sortfield
    return


  ###* Is part included in full ?
    * @description Checks whether part has same keys and matching values as full
    * used by findByExample
    * @param {object} full
    * @param {object} part
    * @return {boolean}
    ###
  isIncluded: (full, part) ->
    res= Object
      .keys(part)
      .every( (key) => full.hasOwnProperty(key) and full[key] is part[key])
    return res

  ###*
    * @description Takes a sample object and finds all records which match with all properties and values
    * @param {object} search - the search example
    * @param {boolean} returnFirstOnly - whether only the first record should be returned
    * @return {null | FileSystemFile[]}
    ###
  findByExample:  (search, returnFirstOnly=false) ->

    res = this.records.filter((entry) => @isIncluded entry, search)
    if returnFirstOnly
      if res.length
        return res[0]
      return null
    return res

  ###* Finds all occurences of resources in records which are matching the given resourceIds
    *
    * @param {string} rowKey the Id to search for. Maybe a resourceId or entityId in improveWeb
    * @return {object}
    ###
  getResource: (rowKey) ->
    if rowKey.indexOf(":") >= 0
      res= (resource for resource in this.records when rowKey is resource['entityId'])
    else
      res= (resource for resource in this.records when rowKey is resource[this.rowKey])
    if res.length
      return res[0]
    return null

  ###* Finds all occurences of resources in records which are matching the given resourceIds
    *
    * @param {string|string[]} resourceIds the Id(s) to search for. Either a string with one or an array with more ids
    * @return {FileSystemFile[]}  an array of resources
    ###
  findResourceByIds: (resourceIds) ->
    if typeof resourceIds is 'string'
      return (resource for resource in this.records when resourceIds is resource[this.rowKey])
    else
      return (resource for resource in this.records when resourceIds.indexOf(resource[this.rowKey]) >= 0)

  ###* Finds all occurences of resources in records which are matching the given resourceIds
    *
    * @param {string} filePath path to Find
    * @return {FileSystemFile | null}
    ###
  findResourceByCachedPath: (filePath) ->
    for resource in this.records
      if resource.cachedPath is filePath
        return resource
    return null

  ###* Finds all occurences of resources in records which are matching the given resourceIds
    *
    * @param {string} filePath - path to Find
    * @return {FileSystemFile | null}  resource
    ###
  findResourceByPath: (filePath) ->
    #ino = this.ComponentHub.FS.inoByPath(filePath)
    for resource in this.records
      if resource._path is filePath
        return resource
    return null

  ###* returns all children instances of a given rowKey in data, returns them in an array
    *
    * @param {string} id the resourceID of the folder
    * @param {boolean} [infinite] default true searches recursive the whole structure. If false, only direct kids are returned
    * @return {FileSystemFile[]}  an array of resourceIds is returned
    ###
  getAllChildren: (id, infinite = true) ->
    # finds all children of a given rowKey in data, returns the rows in an array
    kids = (resource for resource in this.records when resource[this.parentKey] is id)
    if infinite
      grandKids = []
      for kid in kids
        grandKids = [grandKids..., this.getAllChildren(kid[this.rowKey], true)...]
      return [kids..., grandKids...]
    else
      return kids
  ###* finds all children of a given rowKey in data, returns all their rowKeys in an array
    *
    * @param {string} id the resourceID of the folder
    * @param {boolean} [infinite] default true searches recursive the whole structure. If false, only direct kids are returned
    * @return {FileSystemFile[]}  an array of resourceIds is returned
    ###
  findChildren: (id, infinite = true) ->
    # finds all children of a given rowKey in data, returns all their rowKeys in an array
    kids = (resource[this.rowKey] for resource in this.records when resource[this.parentKey] is id)
    if infinite
      grandKids = []
      for kid in kids
        grandKids = [grandKids..., this.findChildren(kid, true)...]
      return [kids..., grandKids...]
    else
      return kids

  ###* Refreshes the given folder.
    * isChild indicates, that the given resource itself is a child of the folder which
    * should be refreshed. in that case the parent will be reloaded
    * @param {FileSystemFile} row the row in the table as resource
    * @param {boolean} isChild indicates the given row is a child and it's parent should be refreshed
    ###
  refreshFolder: (row, isChild = true) ->



  ###* Filters records
    *
    * Also takes care that the parents of found records are displayed
    * **Is called from the table component**
    * @returns {Array} filtered Records
    ###
  filterMethod: ->
    if this.records.length > 2
      if this.filter.length > 0
        parents = []
        founds = (row for row in @records when row.name.indexOf(this.filter) >= 0)
        for found in founds
          rowparents = (row for row in @records when found.cachedPath.indexOf(row.cachedPath) >= 0)
          parents = parents.concat(rowparents)
        founds = founds.concat(parents)
        return [...new Set(founds)]

    return  this.records

  ###* Is emitted when the user clicks on a row (not when expanding/collapsing) it)
    *
    * It also emits an event with the same name
    * @param {number} index  the current index in the records array
    * @param {FileSystemFile} row  The resource itself
    * @param {Object} col  The column Object
    ###
  onRowClick: (index, row, col,evt = null) ->
    this.lastClickedRow = row
    @selectedRows = [row]
    res = this.getResourceFromRow(row)
    res.onDeepLoad()
    this.ComponentHub.AppBridge.setMenu('FileNew', true)
    this.ComponentHub.AppBridge.setMenu('FileNewFolder', true)
    this.ComponentHub.AppBridge.setMenu('FileDelete', true)
    this.emit('onRowClick', res, index, row, col)

  ###* Gets a new selection if the currently selected row was deleted
    *
    * Is called by buildResources, if no row within the same parent left then the parent is selected
    * @todo Handle the case when the last root folder is deleted
    ###
  selectAfterDelete: () ->
    index = this.records.findIndex((x) => x[this.parentKey] is this.lastClickedRow[this.parentKey])
    if index > 0
      row = this.records[index]
    else
      index = this.records.findIndex((x) => x[this.rowKey] is this.lastClickedRow[this.parentKey])
      row = this.records[index]
    @onRowClick(index, row, 0)
    return


  ###*
    * Called by the UI When the titlebar of the treeis clicked
    ###
  onTopTitleClick: () ->
    this.emit('onTitleClick')
    return


  ###* Gets the instance (FileSystemFile etc) from the given object out of the records
    *
    * @param {object} row A raw object containing the selected row
    * @return {object} e.g. FileSystemFile
    ###
  getResourceFromRow: (row) ->
    this.records[this.records.findIndex((x) => x[this.rowKey] is row[this.rowKey])]


  ###* Gets the instance (FileSystemFile etc) from the parent of the given object out of the records
    *
    * @param {object} row A raw object containing the selected row
    * @return {object} e.g. FileSystemFile
    ###
  getParentFromRow: (row) ->
    this.records[this.records.findIndex((x) => x[this.rowKey] is row[this.parentKey])]

  #region DND - Find all Drag and Drop events here
  ###* Called from the system when the user starts dnd operation
    * @param {FileSystemFile} ressource - the row which is dragged
    * @param {object} e - the event object
    ###
  onDragStart: (ressource, e) ->
    dragdata =
      type: 'tree'
      resource: ressource[@rowKey]
    e.dataTransfer.effectAllowed = 'all'
    e.dataTransfer.setData('text', JSON.stringify(dragdata))
    if e.altKey
      e.dataTransfer.dropEffect = 'copy'
    else if e.ctrlKey
      e.dataTransfer.dropEffect = 'link'
    else
      e.dataTransfer.dropEffect = 'move'
    return

  ###* Called from the system when the dragged row comes over another
    * @param {FileSystemFile} row - the row which is dragged
    * @param {object} e - the event object
    ###
  onDragEnter: (row, e) ->
    # don't drop on other draggables
    e.target.parentElement.classList.add('drag-enter')
    e.target.classList.add('drag-enter')
    if e.altKey
      e.dataTransfer.dropEffect = 'copy'
    else if e.ctrlKey
      e.dataTransfer.dropEffect = 'link'
    else
      e.dataTransfer.dropEffect = 'move'
    return

  ###* Called from the system when the dragged row leaves another
    * @param {FileSystemFile} row - the row which is dragged
    * @param {object} e - the event object
    ###
  onDragLeave: (row, e) ->
    e.target.parentElement.classList.remove('drag-enter')
    e.target.classList.remove('drag-enter')
    if e.altKey
      e.dataTransfer.dropEffect = 'copy'
    else if e.ctrlKey
      e.dataTransfer.dropEffect = 'link'
    else
      e.dataTransfer.dropEffect = 'move'
    return

  ###* Called from the system when the row is dragged over another one
    * @param {FileSystemFile} targetRessource - the row over which which is dragged
    * @param {object} e - the event object
    ###
  onDragOver: (targetRessource, e) ->
    e.preventDefault()
    if e.altKey
      e.dataTransfer.dropEffect = 'copy'
    else if e.ctrlKey
      e.dataTransfer.dropEffect = 'link'
    else
      e.dataTransfer.dropEffect = 'move'

  ###* Called from the system when something is dropped
    * @param {FileSystemFile} targetRessource - the row over which which the drop occurs
    * @param {object} e - the event object
    ###
  onDrop: (targetRessource, e) ->
    console.clear()
    if e.altKey
      operation = 'copy'
    else if e.ctrlKey
      operation = 'link'
    else
      operation = 'move'
    e.preventDefault()
    e.target.parentElement.classList.remove('drag-enter')
    e.target.classList.remove('drag-enter')
    # don't drop on own parent
    rawData = e.dataTransfer.getData('text')
    dragdata = JSON.parse(rawData)
    sourceRowKey = dragdata.resource
    sourceRow = @findResourceByIds(sourceRowKey)[0]
    if sourceRow[@parentKey] is targetRessource[@rowKey]
      e.dataTransfer.dropEffect = 'none'
      return
    # check if original parent node
    if sourceRow[@rowKey] is targetRessource[@rowKey]
      return
    #make the exchange but recheck

    targetDir = targetRessource
    if !targetRessource.isDir
      targetDir = @findResourceByIds(targetRessource[this.parentKey])[0]
    switch operation
      when 'copy'
        this.ComponentHub.FS.pathCopy sourceRow.path, targetDir.path
      when 'link'
        this.ComponentHub.FS.pathLink sourceRow.path, targetDir.path
      when 'move'
        this.ComponentHub.FS.pathMove sourceRow.path, targetDir.path
    return
  #endregion DND

  ###* Should be triggered when user hits a key inside the tree
    * # Does not work see: https://github.com/quasarframework/quasar/issues/8539
    * @param {object} e - the event object
    ###
  onTableKeyUp: (e) ->
    console.log   e
    return

  #region - Events from the FileSystemWatcher
  ###* Called by Menu new project via Preload
    *
    * Here we change the root path of the folder we display
    * @param {string} newRoot The root directory of the structure to display
    * @param {string} rootKey The inode of the root file
    * ###
  onRootDirChanged: (newRoot, rootKey, index) ->
    try

      this.indexFilePresent = this.ComponentHub.openActiveIndex()
      @watcherReady=false
      @startPath = newRoot
      @startSortParentKey = rootKey
      @records = []
      @internalTitle = newRoot.split("/")[-1..][0]
      @signalBadgeColor = "orange"
      @updateCounterBadge = false
      this.ComponentHub.AppBridge.setMenu('FileNew', false)
      this.ComponentHub.AppBridge.setMenu('FileNewFolder', false)
      this.ComponentHub.AppBridge.setMenu('FileDelete', false)
      this.refs.vueTrigger +=1
    catch e
      console.error "On RootDirChanged", e
    return

  ###* Called by Filesystemwatcher when a file has been detected
    *
    * @param {FileObject} rec FileSystemObject Raw record
    ###
  onNewRecord: (rec) ->
    try
      index = this.records.findIndex((x) => x[this.rowKey] is rec[this.rowKey])
      if index >= 0
        # if present, replace it
        this.records[index].update rec
        if @watcherReady
          this.emit('onChangedRecord',this.records[index])
          this.refs.vueTrigger +=1
      else
        fileClass = getFileClass(rec)
        record = new fileClass rec
        this.records.push record
        if @watcherReady
          this.emit('onNewRecord',record)
          this.refs.vueTrigger+=1
    catch e
      console.error "onNewRecord", e
    return

  ###* Called by FileSystemWatcher when a file or directory is moved
    *
    * @param {Object} moveInfo
    * @param {string} moveInfo.ino toInode
    * @param {string} moveInfo.toParentIno parentInode
    * @param {string} moveInfo.from relativePath of fromOsPath
    * @param {string} moveInfo.fromParent relativePath of oldParent
    * @param {string} moveInfo.to relativePath of toOsPath
    * @param {string} moveInfo.toParent relativePath of newParent
    * @param {string} moveInfo.info info attributes, stats
    ###
  onMoveRecord: (moveInfo) ->
    try
      console.log "move"
      index = this.records.findIndex((x) => x[this.rowKey] is moveInfo.ino)
      this.records[index].update moveInfo.info
      if @watcherReady then this.emit('onMoveRecord',this.records[index])
    catch e
      console.error "onMoveRecord", e
    return

  ###* Called by FileSystemWatcher when a file or directory is deleted
    *
    * @param {string} ino the inode of the deleted file/folder
    ###
  onDelRecord: (ino) ->
    try
      @updateCounterBadge = false
      @signalBadgeColor = "orange"
      allKids = @findChildren(ino)
      for key in allKids
        index = this.records.findIndex((x) => x[this.rowKey] is key)
        this.records[index].unindex()
        this.records.splice(index, 1)
        subIndex = this.expanded.indexOf key
        if subIndex >= 0
          this.expanded.splice subIndex, 1
      deletedFileOrDir = this.records.findIndex((x) => x[this.rowKey] is ino)
      try
        delRec = JSON.parse(JSON.stringify(this.records[deletedFileOrDir]))
        if @watcherReady then this.emit('onDelRecord',delRec)
      catch
        if @watcherReady then this.emit('onDelRecord',ino)
      this.records[deletedFileOrDir].unindex()
      this.records.splice(deletedFileOrDir, 1)
      subIndex = this.expanded.indexOf deletedFileOrDir
      if subIndex >= 0
        this.expanded.splice subIndex, 1
      # @ts-ignore
      if deletedFileOrDir is @lastClickedRow[this.rowKey] or @lastClickedRow[this.rowKey] in allKids
        @selectAfterDelete()
      @updateCounterBadge = true
      @signalBadgeColor = "green"
      this.refs.vueTrigger +=1
    catch e
      console.error "onDelRecord", e
    return



  ###* Called by Filesystemwatcher when a file has been changed
    *
    * @param {FileObject} rec FileSystemObject Raw record
    ###
  onChangeRecord: (rec) ->
    try
      console.log "change", rec.name
      index = this.records.findIndex((x) => x[this.rowKey] is rec[this.rowKey])
      if index >= 0
        # if present, replace it
        this.records[index].update rec
        if @watcherReady
          this.emit('onChangedRecord',this.records[index] )
          this.refs.vueTrigger +=1
      else
        fileClass = getFileClass(rec)
        record = new fileClass rec
        this.records.push record
        if @watcherReady
          this.emit('onNewRecord',record)
          this.refs.vueTrigger +=1

    catch e
      console.error "onChangeRecord", e

    return

  ###* Triggered when the filesystem Watcher is ready
    *
    * Resets counters
    ###
  onWatcherReady: ->
    try
      @updateCounterBadge = true
      @signalBadgeColor = "green"
      this.emit('onReady')
      @watcherReady=true
      @records = this.treeSort @records, "name", false
      this.reIndex()
      this.refs.vueTrigger +=1
    catch e
      console.error "onWatcherReady", e
    return



  #endregion

  ###* Triggered when User clicks Menu File Delete
    *
    * Displays a confirm dialog
    ###
  onMenuDeleteFile: ->
    try
      if @lastClickedRow.hasOwnProperty('name')
        @confirmDelete = true
      else
        Notify.create({
          message: "No file/directory selected",
          color: "red",
          position: 'center'
        })
    catch e
      console.error "onMenuDelete", e
    return

  ### Called by confirmation dialog when user actually confirms deletion
    *
    ###
  doDelete: ->
    this.ComponentHub.FS.pathDelete @lastClickedRow.path

  reIndex:->
    this.ComponentHub.resetActiveIndex()
    for file in this.records
      file.reindex()
    this.ComponentHub.storeActiveIndex()

  mounted: () ->
    super()
    @records = []
    this.visibleColumns = this.props.visibleCols
    this.sortAliases()
    if this.ComponentHub.isDesktop
      callBacks =
        newRecord: @onNewRecord
        moveRecord: @onMoveRecord
        delRecord: @onDelRecord
        changeRecord: @onChangeRecord
        watcherReady: @onWatcherReady

      this.ComponentHub.AppBridge.setFSCallBacks(callBacks)
      this.ComponentHub.AppBridge.on('menuDeleteFile', @onMenuDeleteFile)
      this.ComponentHub.AppBridge.on('menuNewDir', @onMenuNewFolder)
      this.FSWcallBacks = callBacks


  unmounted: () ->
    super()
