import CompositionBase from 'core/CompositionBase.coffee'
import * as XLSX from 'xlsx'
###* Props definition
  * @typedef {Object}  WordviewerProps
  * @property {string} instancename - Our Instancename for registration in ComponentHub
  ###


###*
* @augments CompositionBase
###
export class Sheetviewer extends CompositionBase
  ###*
    * @param {Object} options
    * @property {WordviewerProps} props
    * @property {Function} emit
    * @property {object} refs
    * @property {object} quasar
    * @constructor
    ###
  constructor: (options) ->
    super(options)
    this.resource=options.props.resource
    this.sheetData=''
    this.sheetDataCompare= null
    this.showContent= false
    this.showCompareContent= false
    this.compareName=""
    this.compareVersion=""
    this.pagination={sortBy:'name',descending:false,page:1,rowsPerPage:50}
    this.filter = ''

  changeRevision:  (resource) ->
    this.showContent = false
    this.resource=resource
    await this.resource.getRawFileContents()
    wb = XLSX.read(this.resource.content, {type: 'binary'})
    wsname = wb.SheetNames[0]
    ws = wb.Sheets[wsname]
    this.sheetData = XLSX.utils.sheet_to_json(ws,{header:'A'})
    this.showContent = true


  changeCompare: (newResource) ->
    this.compareWith=newResource
    this.showContent = false
    if newResource
      this.showCompareContent = false
      await newResource.getRawFileContents()
      this.compareName=newResource.name
      this.compareVersion=newResource.getHumanReadableVersion()
      wb = XLSX.read(newResource.content, {type: 'binary'})
      wsname = wb.SheetNames[0]
      ws = wb.Sheets[wsname]
      this.sheetDataCompare = XLSX.utils.sheet_to_json(ws,{header:'A'})
      this.showCompareContent = true
    this.changeRevision this.resource
