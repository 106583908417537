import {toRaw} from "vue"
import {PandoraProject} from "./PandoraProject.coffee"
import ComponentHub from "core/componentsHub.coffee"
import {LSSettingsFileObject} from "core/lsSettingsObject.coffee"
import {PandoraReqFile} from "./PandoraReqFile.coffee"
import {SettingsFile} from "components/FileSystemBrowser/SettingsFile.coffee"
import PluginBase from 'core/PluginBase.coffee'
import path from 'path'

###* Type Definition ProjectMetaData for global Projects
  * @typedef {object} ProjectMetaData
  * @property {string} projectName - the name
  * @property {string} projectKey - the default prefix for requirement files (ics, pts etc)
  * @property {string} requirementFile - the path for requirements.json
  * @property {object} definition - The content of the requirementfile
  * @property {string | null} matrixFile - the path for matrix.json

  ###

class Pandora extends PluginBase
  ###* virtual Field (Getter/Setter) loadingState for pluginButton
    * gets or sets loadingState for pluginButton
    * @name Pandora#buttonLoading
    * @property {boolean} buttonLoading
    ###
  Object.defineProperty Pandora::, "buttonLoading",
    get: ->
      return this._buttonLoading
    enumerable: true
    configurable: true
    set: (val) ->
      this._buttonLoading = val

  ###*
   * @constructor
  ###
  constructor:->
    #region mandatory properties
    super()
    this.name = "pandora"
    this.icon = "rule"
    this.mainTitle="Pandora"
    this.description="An App for creating Requirements & Specs"
    this.author="© scinteco.com 2022"
    this.version = "#{ComponentHub.appVersion} ##{ComponentHub.appBuild}"
    this.longDescription="Create Requirements and Specifications in Markdown Style. Define and see tracability matrices and explore linked documents"
    this.isDesktop=true
    this.workspace = {}
    this.settingsFileName = "pandora"
    this.workspaceName = ''
    this.indexFileName = ''
    this.workspaceFileName = ''
    this.mainNavigationWidget='FileSystemBrowser'
    this.webApi=null
    #
    ###* Mandatory for Plugins. holds the plugins current WorkspaceSettings SettingsFile Instance
      * @typeof {LSSettingsFileObject | null}
      ###
    this.workspaceFile = null
    #
    ###* Mandatory for Plugins. holds the plugins current WorkspaceSettings FileObject Instance
     * @type {FileObject | null}
     ###
    this.workspaceRawFile = null
    #
    ###* Mandatory for Plugins. holds the plugin Settings FileObject Instance
     * @type {FileObject | null}
     ###
    this.settingsRawFile = null
    #
    ###* Mandatory for Plugins. holds the plugin SettingsFile Instance
      * @typeof {LSSettingsFileObject | null}
      ###
    this.settingsFile = null
    this.settings = null
    #
    ###* Mandatory for Plugins.  If the plugin deals with its own filetypes, it should provide an indexer model., if not set this to null
      * @type {SearchIndexModel}
      ###
    this.indexerModel =
      idField: 'ino'
      fields: ['ics','name', 'content','definitionLine','requirementLine'] # fields to index for full-text search
      storeFields: ['ics','name','project', 'cachedPath','definitionLine','requirementLine'] # fields to return with search result
    #
    ###* Mandatory for Plugins.  This is the third Button on the Plugin Item shown in the Main Navigation Plugins Pane
      * @type {object}
      ###
    this.pluginButton=
        icon: "more_vert"
        tooltip: "No function"
        click: () =>
        disabled: !this.workspaceFile
    #
    ###* Mandatory for Plugins. Holds a flag which should be true when the 3rd additional PluginButton performs a long running Task
      * @type {boolean}
      ###
    this._buttonLoading = false
    ###* Mandatory for Plugins.  This are the fileTypes this Pluguin handles
      * @type {object}
      ###
    ###* Mandatory for Plugins.  Does this plugin hide the whole framework for normal users
      * @type {boolean}
      ###
    this.hideFrameWork=false
    this.registeredFileTypes =
      req: PandoraReqFile


    #endregion mandatory properties
    #
    ###* Every folder in a pandora workspace is represented in here in Form of a PandoraProject
      * @type {Object.<string, PandoraProject>}
      ###
    this.folders = {}
    # Our BrowserStack
      ###*
      * @typeof {PandoraReqFile[]}
      ###
    this.browserStack = []
    # The last "next" which was selected via Prev/Next Buttons
      ###*
      * @type {string}
      ###
    this.nextFile=''
    # The last "prev" which was selected via Prev/Next Buttons
      ###*
      * @type {string}
      ###
    this.prevFile=''
    # The current Index in the BrowserStack
      ###*
      * @type {number}
      ###
    this.browseIndex=-1
    this._usesContentSideBar=true


  #region Workspace and Settings Handling

  ###* Mandatory for Plugins. Will be called in future when everythin is setup
    * @return {*}
    ###
  onAppReady: ->
    settings = await ComponentHub.dbGetSettings "pandora"
    if !settings.hasOwnProperty("pluginsettings")
      ComponentHub.dbSetSettings "pandora", @pluginSettingsTemplate()
    this.settings = await ComponentHub.dbGetSettings this.settingsFileName
    this.settingsFile = new LSSettingsFileObject this.settingsFileName, this.settings , this.pluginSettingsSchema()


  ###* Mandatory for Plugins. Will be called from the IndexPage when User selects new Workspace from the menu
    * @return {LSSettingsFileObject}
    ###
  onNewWorkspace: ->
    this.workspaceFile = new LSSettingsFileObject 'pandora', this.workSpaceTemplate(), this.workSpaceSchema()
    this.workspaceFile.displayName =  this.workSpaceTemplate().name
    this.workspace = this.workspaceFile.model
    return this.workspaceFile

  ###* Mandatory for Plugins. Will be called from the Settingsfile on save
    * It is recommended to pickup the provided workspace settings and ask the user to (re) open the workspace
    * @param {object} workspace
    ###
  onWorkSpaceSaved: (workspace)->
    if workspace.name is this.workspace.name
      this.workspace = workspace
      msg = "Do you like to reopen the workspace now"
    else
      msg = "Do you like to open the workspace now"
    workspaceName = workspace.name
    indexFile = await ComponentHub.dbGetIndex "pandora", workspaceName
    ComponentHub.PageIndex.confirmBox({
      title: 'Workspace saved',
      message: msg,
      cancel: true,
      persistent: true
    }, () =>  # ok was pressed
      ComponentHub.PageIndex.mainNavigation='explorer'
      ComponentHub.PageIndex.onWorkSpaceFileOpened workspaceName,'pandora'
    , () => # cancel was pressed
    )
    return


  ###* Mandatory for Plugins. Opens the workspace
   * @description This is mandatory for all PluginClasses
   * Opens the workspace. If a plugin should not open, just overwrite with an no op
   * @param {string} workspaceName
   * @return {boolean}
   ###
  openWorkSpace: (workspaceName) ->
    this.folders = {}
    this.workspaceName = workspaceName
    this.workspace = await ComponentHub.dbGetWorkspace "pandora", workspaceName
    index = await ComponentHub.dbGetIndex "pandora", workspaceName
    this.workspaceFile = new LSSettingsFileObject "pandora", this.workspace, this.workSpaceSchema()
    this.workspaceFile.displayName = workspaceName
    if this.workspace is Object({})
      alert "The workspace File contains no definition. (is empty or corrupt))"
      ComponentHub.activeWorkspace  = null
      return false
    if this.checkForValidWorkspaces()
      ComponentHub.activeWorkspace  = this.workspace
      ComponentHub.FileSystemTree.onRootDirChanged "/", "0"
      ###*
        * @type {FileSystemWatcherOptions}
        ###
      FSWOptions =
        ignoreDotFiles: true
        readContentsOnNewFiles: !(index is null)
        rootPath:""
        workspace: toRaw(this.workspace)
      ComponentHub.AppBridge.startFileSystemScan toRaw(FSWOptions)
      return true
    ComponentHub.activeWorkspace  = null
    return false

  ###* Recommended for Plugins. Checks workspace file for validity
    * @description validates the workspace and fills our member vars
    * @return {boolean}
    ###
  checkForValidWorkspaces: ->
    failed = []
    if @workspace.folders?
      count = 0
      for folder in @workspace.folders
        folder.path = this.toUnix(folder.path)
        if folder.path.endsWith('/')
          folder.path = folder.path[..-2]
        requirementFile= folder.path + "/requirements.json"
        matrixFile = null

        if ComponentHub.FS.osExists folder.path + "/matrix.json"
          matrixFile = folder.path + "/matrix.json"
        if ComponentHub.FS.osExists requirementFile
          content = ComponentHub.FS.osReadFile requirementFile, 'utf-8'
          try
            definition = JSON.parse content
            if folder.path.indexOf('\\') is -1
              projectName = folder.path.split('/')[-1..][0]
            else
              # Fucking Windows
              projectName = folder.path.split('\\')[-1..][0]

            ###* @type {ProjectMetaData} ###
            p =
              projectName: projectName #path.basename(folder.path)
              projectKey:  folder.prefix
              requirementFile:  folder.path + "/requirements.json"
              definition: definition
              matrixFile: matrixFile
            this.folders[p.projectName] = new PandoraProject p
            count +=1
          catch e
            failed.push folder.path
      if failed.length
        alert "The following folders do not contain a requirements.json file. Skipping: #{failed}"
      if failed.length is @workspace.folders.length
        alert "None of the provided folders in the workspace file contained a valid requirements.json. Aborting."
        this.folders = {}
        return false
      else
        return count > 0
    else
      alert "Invalid workspace file, could not find any folders in it."
      this.folders = {}
      return false

  ###* converts path to unix path
    * Just converts all `` to / and consolidates duplicates, without performing any normalization.
    * @param {string} p
    * return {string}
    ###
  toUnix: (p) ->
    p = p.replace /\\/g, '/'
    p = p.replace /(?<!^)\/+/g, '/' # replace doubles except beginning for UNC path
    return p
  ###* Mandatory for Plugins. Returns a fully instanciated SettingsFile for Workspace Settings
   * @return {LSSettingsFileObject|null}
   ###
  getWorkspaceAndSchema: ->
    return this.workspaceFile

  ###* Mandatory for Plugins. Returns an empty settings template for workspace definition
    * @return {object}
    ###
  workSpaceTemplate: ->
    template =
      workSpaceType: "pandora"
      name: 'New Pandora Workspace'
      description: 'My shiny new Workspace'
      favourite:false
      icon: this.icon
      folders: [
        {
          path:''
          prefix:''
        }
      ]
    return template

  ###* Mandatory for Plugins. Retuns the schema for workspace settings
   * @return {object}
   ###
  workSpaceSchema: ->
    schema=[
      {
        id: "name",
        label: "Workspace Name",
        subLabel: "Give Your new Workspace a name",
        component: "QInput",
        dense: true,
        rounded: true,
        standout: true,
        clearable:true,
        span: "100%"
      },
      {
        id: "description",
        label: "Short description",
        subLabel: "The short description will show up in the workspace list.",
        component: "QInput",
        dense: true,
        rounded: true,
        standout: true,
        clearable:true,
        span: "100%"
      },
      {
        id: "favourite",
        component: "QToggle",
        type: "checkbox",
        span: 1,
        dense: true,
        label: "Favourite",
        subLabel: "When checked, this will show up in the favourite sections in the workspace list",
        defaultValue: false
    }
      {
        label: "Included Folders",
        subLabel: "Enter your folders i the left column, and the prefix key of this folder in the right one. The prefix key is something like 'ics', 'pts' etc."
      },
      {
        id: "folders",
        component: "BlitzListForm",
        required: true,
        span:"100%"
        schema: [
          {
            id: "prefix",
            subLabel: "The file prefix used here like 'ics', 'pts'",
            label: "PrefixKey",
            component: "QInput",
            dense: true,
            rounded: true,
            standout: true,
            clearable:true,
            span:"20%",
            hint:""
          },
          {
            id: "path",
            label: "Path",
            subLabel: "An absolut file path. Use double backslash (\\) in windows.",
            component: "QInput",
            dense: true,
            rounded: true,
            standout: true,
            clearable:true,
            span:"80%",
            hint: "DoubleClick me for Folder Select Dialog",
            events: {
              dblclick: (evt,{rowData}) =>
                files = ComponentHub.AppBridge.openFolder()
                if files and files.length
                  rowData.path = files[0]

            }
          }

        ]
      }
    ]
    return schema

  ###* Mandatory for Plugins. Returns a template object for Plugin Settings
    * @return {object}
    ###
  pluginSettingsTemplate: ->
    template =
      pluginsettings:"pandora"
      newWorkspaceFiles: globalThis.commonFolders.documents
    return template


  ###* Mandatory for Plugins. Returns the Schema for Plugin Settings
    * @return {object}
    ###
  pluginSettingsSchema: ->
    schema= [
          {
            id: "newWorkspaceFiles",
            label: "Folder for new Workspaces",
            subLabel: "Obsolete now! An absolut file path where new Workspaces should be created by default. Use double backslash (\\) in windows.",
            component: "QInput",
            dense: true,
            rounded: true,
            standout: true,
            clearable:true,
            span: "100%",
            hint: "DoubleClick me for Folder Select Dialog",
            events: {
              dblclick: (evt,{updateField}) =>
                files = ComponentHub.AppBridge.openFolder()
                if files and files.length
                  updateField({ id: 'newWorkspaceFiles', value: files[0] })
            }
          }

        ]
    return schema

  ###* Mandatory for Plugins. Returns a fully initialized Plugin SettingsFile
   * @return {LSSettingsFileObject}
   ###
  getSettingsAndSchema: ->
    if this.settings?
      await ComponentHub.dbSetSettings "pandora", this.settings
    else
      ComponentHub.dbSetSettings  "pandora", @pluginSettingsTemplate()
    this.settings = await ComponentHub.dbGetSettings  "pandora"
    this.settingsFile = new LSSettingsFileObject "pandora", this.settings , this.pluginSettingsSchema()
    this.settingsFile.displayName = "Pandora Settings"
    return this.settingsFile

  #endregion Workspace and Settings Handling
  #region EventHandlers FileTree

  ###*  Mandatory for Plugins. Ommitted when the RootDir Changed
    * @deprecated
    ###
  onFileTreeRootDirChanged: (folder) ->
    return
  ###* Mandatory for Plugins.  Ommitted when a new Record appers
    * @param {FileSystemFile | PandoraReqFile} resource
    ###
  onFileTreeNewRecord: (resource) ->
    @recalculateProject resource
    return

  ###* Mandatory for Plugins. Ommitted when a  Record was moved or renamed
    * @param {FileSystemFile | PandoraReqFile} resource
    ###

  onFileTreeMoveRecord: (resource) ->
    @recalculateProject resource
    return

  ###* Mandatory for Plugins. Ommitted when a  Record was deleted
    * @param {FileSystemFile | PandoraReqFile} resource
    ###
  onFileTreeDelRecord: (resource) ->
    @recalculateProject resource
    return

  ###* Mandatory for Plugins. Ommitted when a  Record was modified
    * @param {FileSystemFile | PandoraReqFile} resource
    ###
  onFileTreeChangedRecord: (resource) ->
    @recalculateProject resource
    return
  ###* Ommitted when the FileSystemWatcher is ready ###
  onFileTreeReady: ->
    return

  #endregion EventHandlers FileTree

  ###* called by the most event handlers to ensure each Project is up to date
    * @param {FileSystemFile | PandoraReqFile} resource
    ###
  recalculateProject: (resource) ->
    if resource?
      projectName = resource._path.split('/')[1]
      if @folders.projectName?
        @folders.projectName.allIcs


  #region BrowserStack. This handles the state and functionality of the tabActionButtons

  ###*
    * @description Resets the whole history.Is called when Browser Mode is turned off in PandoraReqFile
    ###
  resetBrowserStack: ->
    this.browserStack = []
    this.nextFile=''
    this.prevFile=''
    this.browseIndex=-1
    return

  ###*
   * @description Is called from PandoraReqFile tabPinned Setter
   * @param {PandoraReqFile} file
  ###
  pageBrowsed: (file) ->
    ics = file.ics
    if ics is this.nextFile or ics is this.prevFile
      # In the case the file comes i because next or previous button was clicked
      # just ignore it
      return
    currIndex = this.browserStack.indexOf(file)
    if currIndex < 0
      # It is not in the stack
      if this.browseIndex is this.browserStack.length - 1 or this.browseIndex is -1
        # and we are at the end of the stack, then just append
        this.browserStack.push file
        this.browseIndex = this.browserStack.length - 1
      else
        # Cut the the stack at the current position and and go on from here
        this.browserStack = this.browserStack[..currIndex - 1]
        this.browserStack.push file
        this.browseIndex = this.browserStack.length - 1
      return
    else
      this.browserStack.push file
      this.browseIndex +=1
      return

  ###*
    * @description Is called from PandoraReqFile when next Button is clicked
    ###
  browseNext: ->
    if this.browseIndex is this.browserStack.length - 1
      return
    this.browseIndex += 1
    doc =  this.browserStack[this.browseIndex]
    this.nextFile = doc.ics
    if ComponentHub.activeContainer?
      if ComponentHub.activeContainer._browseDoc?
        ComponentHub.activeContainer.browseDoc = doc
    return
  ###*
    * @description Is called from PandoraReqFile when previous  Button is clicked
    ###
  browsePrev: ->
    if this.browseIndex <= 0
      return
    this.browseIndex -= 1
    doc =  this.browserStack[this.browseIndex]
    this.nextFile = doc.ics
    if ComponentHub.activeContainer?
      if ComponentHub.activeContainer._browseDoc?
        ComponentHub.activeContainer.browseDoc = doc

  ###*
    * @description Is called from tabactions Button (defined in PandoraReq File) to get disbled State
    ###
  disableBack: ->
    return  this.browseIndex <= 0
  ###*
    * @description Is called from tabactions Button (defined in PandoraReq File) to get disbled State
    ###
  disableNext: ->
    return  this.browseIndex >= this.browserStack.length - 1
  ###*
    * @description Is called from tabactions Button (defined in PandoraReq File) to get disbled State
    * only valid in combination of tabPinned of current req File
    ###
  disableAtAll: ->
    return this.browserStack.length > 0
  ###*
    * @description Is called from tabactions Button (defined in PandoraReq File) to get tooltip
    ###
  toolTipNext: ->
    if this.browseIndex >= this.browserStack.length - 1
      return "This is last in History"
    else
      return "Navigate to #{this.browserStack[this.browseIndex + 1].ics}"
  ###*
    * @description Is called from tabactions Button (defined in PandoraReq File) to get tooltip
    ###
  toolTipPrev: ->
    if this.browseIndex <= 0
      return "This is first in History"
    else
      return "Navigate to #{this.browserStack[this.browseIndex - 1].ics}"

  #endregion



export {Pandora as PluginMain}

