import CompositionBase from 'core/CompositionBase.coffee'
import {convertToHtml} from  'mammoth/mammoth.browser'
import {nextTick} from 'vue'
import { useRouter, useRoute } from 'vue-router'
import {diff} from 'core/htmlCompare.coffee'
###* Props definition
  * @typedef {Object}  WordviewerProps
  * @property {string} instancename - Our Instancename for registration in ComponentHub
  ###


###*
* @augments CompositionBase
###
export class Wordviewer extends CompositionBase
  ###*
    * @param {Object} options
    * @property {WordviewerProps} props
    * @property {Function} emit
    * @property {object} refs
    * @property {object} quasar
    * @constructor
    ###
  constructor: (options) ->
    super(options)
    this.resource=options.props.resource
    this.html=''
    this.undiffedHtml=''
    this.undiffedCompareHtml=''
    this.messages=''
    this.htmlCompare= null
    this.showContent= false
    this.showCompareContent= false
    this.compareName=""
    this.compareVersion=""
    this.router = useRouter()

  changeRevision:  (resource) ->
    this.showContent = false
    this.resource=resource
    await this.resource.getRawFileContents()
    convertToHtml({arrayBuffer:this.resource.content})
    .then((result) =>
      this.html = result.value # The generated HTML
      this.undiffedHtml=result.value # Keep a clean copy
      this.messages = result.messages # Any messages, such as warnings during conversion
      this.showContent = true
    )
    .done()

  changeCompare: (newResource) ->
    this.compareWith=newResource
    this.showContent = false
    if newResource
      this.showCompareContent = false
      await newResource.getRawFileContents()
      this.compareName=newResource.name
      this.compareVersion=newResource.getHumanReadableVersion()
      convertToHtml({arrayBuffer:newResource.content})
      .then((result) =>
        this.undiffedCompareHtml= result.value # The generated HTML
        this.messages = result.messages # Any messages, such as warnings during conversion
        this.htmlCompare = diff this.undiffedHtml, this.undiffedCompareHtml

        this.showContent = true
        this.showCompareContent = true
        this.changeRevision this.resource
        this.html = diff  this.undiffedCompareHtml, this.undiffedHtml
      )
      .done()
    else
      this.changeRevision this.resource
      this.html =  this.undiffedHtml

  ###*
    * Since there is a bug within vue router, any click on an anchor tag like #table4 we would get
    * a 404 Error.
    * Here I capture the click on the preview div, check if it is an anchor and it has a hash value
    * if so I determine the target and prevent the default click handling which would trigger the vue router
    * @param {object} evt - the click event provided by the click handler in the template
    ###
  wordClicked: (evt) ->
    console.log evt.target
    if evt.target.nodeName is "A"
      if evt.target.hash.length
        evt.currentTarget.querySelector(evt.target.hash).scrollIntoView()
        evt.preventDefault()

  mounted: ->
    super()

