import CompositionBase from 'core/CompositionBase.coffee'
import {apiAllReviews,apiGetResource} from 'apis/improveREST/rawImprove.coffee'
###* Props definition
  * @typedef {Object}  LeftpanelsProps
  * @property {string} instancename - Our Instancename for registration in ComponentHub
  ###


###*
* @augments CompositionBase
###
export class Leftpanels extends CompositionBase
  ###*
    * @name Leftpanels#reviewList
    * @type {array}
    ###
  Object.defineProperty this::, "reviewList",
    get: ->
      try
        if this.ComponentHub.activeServer?
          this.fetchAll()
      catch e
        # neverMind
      return this._reviewList
    enumerable: true
    configurable: true
  ###*
    * @param {Object} options
    * @property {LeftpanelsProps} props
    * @property {Function} emit
    * @property {object} refs
    * @property {object} quasar
    * @constructor
    ###
  constructor: (options) ->
    super(options)
    this.titlebuttons=[]
    this.tableTitle = "All Reviews"
    this._reviewList = []

  createTitleActions: ->
    return [
      {
        name:"refresh"
        highlight: false
        hidden: false
        disabled: false
        icon:"o_restart_alt",
        tooltip:"Load or refresh ",
        click: () => this.fetchAll()

      }
    ]

  fetchAll: ->
    result = await this.ComponentHub.activeServer.doQuery apiAllReviews()
    if result.status isnt 200
      return []
    user = this.ComponentHub.activeServer.userdata
    res = []
    if user.role is 'admin'
      res = result.data.elements
    else
      for entry in result.data.elements
        if entry.requestor.username is user.username
          res.push entry
        else
          if entry.hasOwnProperty("reviewers")
            for reviewer in entry.reviewers
              if reviewer.user.username is user.username
                res.push entry
                break
    this._reviewList = res


  loadReview: (id) ->
    await this.ComponentHub.activeServer.fetchInToVirtualRoot apiGetResource(id)



