import CompositionBase from 'core/CompositionBase.coffee'
###* Props definition
  * @typedef {Object}  MetadataProps
  * @property {string} instancename - Our Instancename for registration in ComponentHub
  ###


###*
* @augments CompositionBase
###
export class Metadata extends CompositionBase
  ###* virtual Field (Getter/Setter)
    *
    * @name Metadata#records
    * @property {array}
  ###
  Object.defineProperty this::, "records",
    get: ->
      if this.resource?
        if !this.resource.isSettingsFile
          return this.resource.metadata
      return []
    enumerable: true
    configurable: true

  ###*
    * @param {Object} options
    * @property {MetadataProps} props
    * @property {Function} emit
    * @property {object} refs
    * @property {object} quasar
    * @constructor
    ###
  constructor: (options) ->
    super(options)
    this.resource = options.props.resource

  mounted: ->
    super()
    if !this.resource.isSettingsFile
      this.resource.getMetadata()
