
import CompositionBase from 'core/CompositionBase.coffee'
###* Props definition
  * @typedef {Object}  PropertygridProps
  * @property {string} instancename - Our Instancename for registration in ComponentHub
  ###


###*
* @augments CompositionBase
###
export class Propertygrid extends CompositionBase
  ###*
    * @param {Object} options
    * @property {PropertygridProps} props
    * @property {Function} emit
    * @property {object} refs
    * @property {object} quasar
    * @constructor
    ###
  constructor: (options) ->
    super(options)
    this.objecttodisplay = options.props.objecttodisplay
    this.groups = options.props.groups
    this.mapping = options.props.mapping
    this.records= []
    this.columns=[
      { name: 'caption', label: 'Property', align: 'left', field: 'caption', sortable: false},
      { name: 'value',  label: 'Value', field: 'value', align: 'left',sortable: false }
      ]
  createRecords: () ->
    @records=[]
    for group in @groups
      @records.push {caption: group.name, color: group.color,type:'groupheader', value:''}
      added = false
      for key of @mapping
        if !@mapping[key].hasOwnProperty('group')
          @mapping[key].group = 'General'
        if @mapping[key].group is group.name
          if @objecttodisplay.hasOwnProperty(key)
            rec= JSON.parse(JSON.stringify(@mapping[key]))
            rec.value = @objecttodisplay[key]
            @records.push rec
            added = true
      if !added
        @records.splice(@records.length-1,1)
