import CompositionBase from 'core/CompositionBase.coffee'
import {apiAddReviewComment,apiGetReviewComments,apiAddReviewEntryComment,apiGetReviewEntryComments} from 'apis/improveREST/rawImprove.coffee'
import {nextTick} from 'vue'

###* Props definition
  * @typedef {Object}  CommentchatProps
  * @property {string} instancename - Our Instancename for registration in ComponentHub
  ###


###*
* @augments CompositionBase
###
export class Commentchat extends CompositionBase
  ###*
    * @name Commentchat#currentUser
    * @type {string}
    ###
  Object.defineProperty this::, "currentUser",
    get: ->
      return this.ComponentHub.activeServer.user
    enumerable: true
    configurable: true
  #region Getters
  ###* virtual Field (Getter) returns the cssVars
    * Defines css Variables to be set to any Object via style attribute
    * After that they can be used with var(--name) in css
    * @name Commentchat#cssVars
    * @type {string} cssVars
    ###
  Object.defineProperty this::, "cssVars",
    get: ->
      heightDiff = 150
      try
        if !this.resource.userIsRequestor
          heightDiff = 250
      catch
        # never mind
      {
        '--chatHeight': "calc(100vh - #{heightDiff}px)"
      }

  ###*
    * @param {Object} options
    * @property {CommentchatProps} props
    * @property {Function} emit
    * @property {object} refs
    * @property {object} quasar
    * @constructor
    ###
  constructor: (options) ->
    super(options)
    this.resource=options.props.resource
    this.iwebtype=options.props.iwebtype
    this.revisionOnly=options.props.revisionOnly
    this.chatText= ''
    this.messages=[]
    this.commentLinePrefix="$"
    this.aggregate=false

  addComment: (evt,text) ->
    if text?
      this.chatText = text
    if !this.chatText.trim().length
      return
    if this.iwebtype is 'ImproveReview'
      res = await this.ComponentHub.activeServer.doQuery apiAddReviewComment this.resource.resourceId, this.chatText
      if res.status is 200
        refetch = await this.ComponentHub.activeServer.doQuery apiGetReviewComments this.resource.resourceId
        if refetch.status is 200
          this.resource.comments = refetch.data
      this.chatText=""
    else if this.iwebtype is 'ReviewEntry'
      res = await this.ComponentHub.activeServer.doQuery apiAddReviewEntryComment this.resource.inReview.resourceId,this.resource.id, this.chatText
      if res.status is 200
        refetch = await this.ComponentHub.activeServer.doQuery apiGetReviewEntryComments this.resource.inReview.resourceId, this.resource.id
        if refetch.status is 200
          this.resource.comments = refetch.data
      this.chatText=""
    await this.getMessages()
    if this.ComponentHub.hasOwnProperty('textViewer')
      this.ComponentHub['textViewer'].checkForComments()

    else
      return

  getMessages: () ->
    if  this.iwebtype is 'ReviewEntry'
      if this.resource
        if this.revisionOnly
          comments=this.resource.commentsForRevision()
        else
          comments=this.resource.comments
      else
        comments=[]
    else if @iwebtype is 'ImproveReview'
      comments = await @resource.ensureComments()
      if this.aggregate
        for entry in this.resource.entryRecs
          entryComments=[]
          if this.revisionOnly
            e_comments=entry.commentsForRevision()
          else
            e_comments=entry.comments
          for comment in e_comments
            comment.resourceName=entry.resource.name
            entryComments.push comment
          comments = comments.concat(entryComments)
    else
      comments=[]
    allComments = []
    for comment in comments
      if not comment.hasOwnProperty('createdByName')
        comment.createdByName = comment.createdBy.username
      comment.sent=false
      if comment.createdByName is @currentUser or comment.createdBy is @currentUser
        comment.sent=true
      allComments.push(comment)


    allComments.sort( (A,B)->
      if A.createdAt is B.createdAt then return 0
      if A.createdAt < B.createdAt  then  return 1
      if A.createdAt > B.createdAt  then  return -1)

    setTimeout () =>
      this.setEditorComments(allComments)
    ,500
    this.messages = allComments.sort()
    return this.messages

  setEditorComments: (comments) ->
    setSingleComments = =>
      for comment in comments
        if comment.hasOwnProperty("comment")
          if comment.comment.indexOf(this.commentLinePrefix) is 0
            line = parseInt(comment.comment.slice(this.commentLinePrefix.length,comment.comment.indexOf(" ")))
            text = comment.comment.slice(comment.comment.indexOf(' ') + 1)
            user = comment.createdByName
            date = new Date(comment.createdAt).toLocaleString()
            #new Date(comment.createdAt).toLocaleString(),comment.createdByName,comment.comment,line,true)
            this.resource.currentEditor.addComment(text,line,user,date,true)

    if this.resource.currentEditorIsMonaco
      this.resource.currentEditor.clearAllComments(setSingleComments)




