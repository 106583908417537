import CompositionBase from "core/CompositionBase.coffee"
export class References extends CompositionBase
  ###*
   * @param {VueClassOptions=} options
   * @constructor
  ###
  constructor: (options) ->
    super(options)

    ###*
      * @type {string}
      ###
    this.selectedTab = 'references'
    ###*
      * @typeof {PandoraReqFile[]}
      ###
    this.refers = []
    ###*
      * @typeof {PandoraReqFile[]}
      ###
    this.referedBy= []
    ###*
      * @typeof {PandoraReqFile[]}
      ###
    this.matrix = []
    ###*
      * @type {object}
      ###
    this.tree = []
  #
  ###* Handler for vue watch()
   * @param {PandoraReqFile} file
   ###
  watcher: (file) ->
    if file.mimeType is "text/pandora-requirement"
      @refers = []
      for ics in  file.references
        f = file.project.docByIcs( ics)
        if f?
          @refers.push f
      @referedBy = []
      for f in  file.referencedBy
        @referedBy.push f
      matrixFiles = file.getMatrixRelations()
      for f in matrixFiles
        @matrix.push f
      this.tree = file.project.getMatrixTree()
    return
