import CompositionBase from 'core/CompositionBase.coffee'
import {apiGetReviewReviewers,apiGetReviewDetails,apiAddReviewer,apiAnswerReviewInvitation,
  apiDeleteReviewer,apiGetReviewComments} from 'apis/improveREST/rawImprove.coffee'
###* Props definition
  * @typedef {Object}  ReviewviewerProps
  * @property {string} instancename - Our Instancename for registration in ComponentHub
  * @property {object} resource - Our Instancename for registration in ComponentHub
  ###


###*
* @augments CompositionBase
###
export class Reviewviewer extends CompositionBase
  ###*
    * @name Reviewviewer#currentUser
    * @type {string}
    ###
  Object.defineProperty this::, "currentUser",
    get: ->
      return this.ComponentHub.activeServer.userdata
    enumerable: true
    configurable: true
  ###*
    * @name Reviewviewer#currentUserName
    * @type {string}
    ###
  Object.defineProperty this::, "currentUserName",
    get: ->
      return this.ComponentHub.activeServer.userdata.name
    enumerable: true
    configurable: true
  ###*
    * @name Reviewviewer#currentUserUsername
    * @type {string}
    ###
  Object.defineProperty this::, "currentUserUsername",
    get: ->
      return this.ComponentHub.activeServer.userdata.username
    enumerable: true
    configurable: true
  ###*
    * @name Reviewviewer#currentUserUsername
    * @type {boolean}
    ###
  Object.defineProperty this::, "userIsRequestor",
    get: ->
      return this.currentUser.username is this.requestor.username
    enumerable: true
    configurable: true
  ###* virtual Field (Getter)
    * @name Reviewviewer#userIsReviewer
    * @type {boolean}
    ###
  Object.defineProperty this::, "userIsReviewer",
    get: ->
      try
        for reviewer in this.props.resource.reviewers
          if this.currentUser.username is reviewer.username
            return true
        return false
      catch e
        return false
    enumerable: true
    configurable: true

  ###*
    * @param {Object} options
    * @property {ReviewviewerProps} props
    * @property {Function} emit
    * @property {object} refs
    * @property {object} quasar
    * @constructor
    ###
  constructor: (options) ->
    super(options)
    this.resource = options.props.resource
    this.icon= 'mdi-file-document-outline'
    this.showContent= false
    this.plan= false
    this.review=false
    this.finalize=false
    this.tab='comments'
    this.commententry=null
    this.requestor=
      name: 'dummy'
      username: 'dummy'
      role: 'dummy'
    this.userstatus=
      INVITED:'forward_to_inbox'
      ACCEPTED:'done'
      DECLINED:'cancel'
    this.selectedEntry=''
    this.vueRefresh = 0


  ###* depending on the given path, this method calculates the padding needed for the first column in a collapsible tree
    *
    * @param {string} path
    * @return {string}  a css directive
    ###
  setPadding: (path) ->
    try
      return "padding-left: #{(path.split('/').length - this.resource._vpath.split('/').length) * 10}px;"
    catch e
      return ''

  ###*
    * Adds a User to the list of reviewers.
    * This method checks that the requestor himself can't be added and that no user is added twice
    * @param {object} user
    ###
  globalUserAdded: (user) ->
    if @requestor.username is user.username
      this.quasar.notify({
        message: "The requestor cannot be added to reviewers",
        color: "red",
        position: 'center'
      })
      return
    if not @userExists(user)
      if not user.hasOwnProperty('email')
        user.email=''
      result = await this.ComponentHub.activeServer.doQuery apiAddReviewer this.resource.resourceId, user.id,user.name
      if result.status is 200
        # @todo the next two lines should be replaced with @resource.reviewers.push result.data
        user.status = 'INVITED'
        #@resource.reviewers.push user
        this.getDetails(this.resource)
      else
        this.quasar.notify({
          message: "Improve could not invite this User and responded with #{result.status}",
          color: "red",
          position: 'center'
        })
    else
      this.quasar.notify({
        message: "User #{user.name} is already Reviewer",
        color: "orange",
        position: 'center'
      })


  ###*
    * resets the swipe action in a swipe UI Element
    * @param {function} reset comes from the UI Widget
    ###
  drawBack: (reset) ->
    clearTimeout(timer)
    timer = setTimeout(() =>
      reset()
    , 500)

  ###*
    * Searches the given user in the list of reviewers
    * @param {object} userToFind the user to find
    * @return {boolean}
    ###
  userExists: (userToFind) ->
    res = (user for user in @resource.reviewers when user.id is userToFind.id)
    return res.length > 0

  tabSwitched: (value)->
    if value is "comments"
      this.resource.getEntries()


  ###*
    * Gets all detail information for the given review and enriches this attributes
    * Loads comments, reviewers, entries
    * the reviewers will be remapped, so the reviewer objects become extended user
    * objects with id and status
    * @param {ImproveReview} resource
    ###
  getDetails: (resource) ->
    unless resource?
      resource = @resource
    res = await this.ComponentHub.activeServer.doQuery apiGetReviewDetails resource.resourceId
    rawData = res.data
    tmpReviewer = []
    if rawData.hasOwnProperty('reviewers')
      for reviewer in rawData.reviewers
        reviewer.user.reviewerId  = reviewer.id
        reviewer.user.status  = reviewer.status
        tmpReviewer.push reviewer.user
      rawData.reviewers = tmpReviewer
    rawData && Object.assign resource, rawData
    @requestor = rawData.requestor
    await @resource.getEntries()
    #this.$comps['CommentChat'].setResource @resource
    return null

  ###*
   * Handler for swipe left on reviewer list. Will cause accepted state
   * @param {function} reset - callback to reset the swipe action
   * @param {object} user - the user we are dealing with
    ###
  onReviewListLeft: (reset,user) ->
    result = await this.ComponentHub.activeServerv.doQuery apiAnswerReviewInvitation @resource.resourceId, true
    if result.status is 200
      user.status="ACCEPTED"
    @drawBack(reset)
    return null

  ###*
    * Handler for swipe right on reviewer list. Will cause decline state
    * @param {function} reset to reset the swipe action
    * @param {object} user -  the user we are dealing with
    ###
  onReviewListRight: (reset,user) ->
    if @userIsRequestor
      this.quasar.dialog(
        title: 'Remove Reviewer',
        message: "Are you sure you want to remove #{user.name} from the reviewers list?",
        cancel: true,
        persistent: true
      ).onOk( =>
        res= await  this.ComponentHub.activeServer.doQuery apiDeleteReviewer @resource.resourceId, user.reviewerId
        if res.status is 200
          index = @resource.reviewers.indexOf(user)
          @resource.splice(index,1)
      )
    else
      result = await this.ComponentHub.activeServer.doQuery apiAnswerReviewInvitation @resource.resourceId, false
      if result.status is 200
        user.status="DECLINED"
    @drawBack(reset)
    return null

  removeReviewer: (user) ->
    this.quasar.dialog(
      title: 'Remove Reviewer',
      message: "Are you sure you want to remove #{user.name} from the reviewers list?",
      cancel: true,
      persistent: true
    ).onOk( =>
      res= await  this.ComponentHub.activeServer.doQuery apiDeleteReviewer @resource.resourceId, user.reviewerId
      if res.status is 200
        index = @resource.reviewers.indexOf(user)
        @resource.reviewers.splice(index,1)
    )

  reviewerAccepts: (user) ->
    result = await this.ComponentHub.activeServer.doQuery apiAnswerReviewInvitation @resource.resourceId, true
    if result.status is 200
      user.status="ACCEPTED"


  reviewerDeclines: (user) ->
    result = await this.ComponentHub.activeServer.doQuery apiAnswerReviewInvitation @resource.resourceId, false
    if result.status is 200
      user.status="DECLINED"

  entryItemDblClicked: (entry) ->
    this.entryItemClicked entry
    this.ComponentHub.openNewTabByRowKey(entry.id)


  entryItemClicked: (entry) ->
    this.selectedEntry=entry.id
    chatId = "commentchat" + this.resource.resourceId
    approvalId = "approvalbar" + this.resource.revisionId
    this.ComponentHub[chatId].resource= entry
    this.ComponentHub[chatId].iwebtype= entry.iWebType
    this.ComponentHub[chatId].getMessages()
    this.ComponentHub[approvalId].resource= entry

  onResourceChanged: (newResource) ->
    this.showContent= false
    await @getDetails(newResource)
    this.showContent= true

  mounted: ->
    super()
    await @getDetails(this.resource)
    this.showContent= true
    if this.userIsRequestor
      this.tab = 'users'
    else
      for reviewer in this.props.resource.reviewers
        if this.currentUser.username is reviewer.username
          if reviewer.status is 'INVITED'
            this.tab = 'users'
            return
      this.tab = 'comments'
