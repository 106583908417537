import {Container as IWContainer} from "plugins/improveweb/containerCls.coffee"

export class Container extends IWContainer

  ###*
   * @param {VueClassOptions=} options
   * @constructor
  ###
  constructor: (options) ->
    super(options)
    ### @ts-ignore ###
    this.resource=options.props.node

