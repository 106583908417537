import CompositionBase from 'core/CompositionBase.coffee'
###* Props definition
  * @typedef {Object}  NoneviewerProps
  * @property {string} instancename - Our Instancename for registration in ComponentHub
  ###


###*
* @augments CompositionBase
###
export class Noneviewer extends CompositionBase
  ###*
    * @param {Object} options
    * @property {NoneviewerProps} props
    * @property {Function} emit
    * @property {object} refs
    * @property {object} quasar
    * @constructor
    ###
  constructor: (options) ->
    super(options)
    this.resource = options.props.resource
    this.icon = 'mdi-file-document-outline'

  mounted: ->
    this.icon = await this.resource.getDisplayableFromContent()
