import { ImproveBrowser } from 'src/components/ImproveBrowser/ImproveBrowserCls.coffee'
import CompositionBase from 'core/CompositionBase.coffee'
import {improveApiUrl, tokenAuth,apiDeleteResource,apiNewFolder,apiCopyResource,apiGetResourceWithMeta,
        apiSetMetaDataBulk,apiMoveResource,apiRunStep,apiGetResourceByPath} from 'apis/improveREST/rawImprove.coffee'
import {nextTick} from 'vue'
import {ImproveStep} from "components/ImproveBrowser/resourceTypes/ImproveStep.coffee"
import {ImproveResource} from "components/ImproveBrowser/resourceTypes/ImproveResource.coffee"
import { validateFormPerSchema } from 'blitzar'
###* Props definition
  * @typedef {Object}  FolderviewerProps
  * @property {string} instancename - Our Instancename for registration in ComponentHub
  ###


###*
* @augments CompositionBase
###
export class PredatorFolder extends CompositionBase
  ###* virtual Field (Getter) returns the current url*
    * @name PredatorFolder#apiUrl
    * @type {string} apiUrl
    ###
  Object.defineProperty this::, "apiUrl",
    get: -> return improveApiUrl
    enumerable: true
    configurable: true

  ###* virtual Field (Getter) returns the current template*
    * @name PredatorFolder#currTemplate
    * @type {string} currTemplate
    ###
  Object.defineProperty this::, "currTemplate",
    get: ->
      if this.template?
        return "#{this.template.name} #{this.template.version} #{this.template.dsName}"
      return "No template selected"
    enumerable: true
    configurable: true

  ###* virtual Field (Getter) returns the current uploadDir*
    * @name PredatorFolder#currDataDir
    * @type {object} currDataDir
    ###
  Object.defineProperty this::, "currDataDir",
    get: ->
      if this.dataUploadDir?
        return this.dataUploadDir
      return this.dataFolder
      return null
    enumerable: true
    configurable: true

  ###* virtual Field (Getter) returns numberOfDataFiles*
    * @name PredatorFolder#numberOfDataFiles
    * @type {number} numberOfDataFiles
    ###
  Object.defineProperty this::, "numberOfDataFiles",
    get: ->
      if this.dataUploadDir?
        datafiles = this.ComponentHub.FileSystemTree.getAllChildren this.dataUploadDir.resourceId, false
        i=0
        for file in datafiles
          if !file.isFolderish
            i +=1
        return i
      return 0
    enumerable: true
    configurable: true

  ###* virtual Field (Getter) returns whether we disable Continue*
    * @name PredatorFolder#disableContinue
    * when 3 possible is: this.roNumber.length is 0 or this.rdrNumber.length is 0 or this.experimentDate.length is 0 or this.experimentName.length is 0 or this.respScientist.length is 0 or this.collabScientist.length is 0
    * @type {boolean} disableContinue
    ###
  Object.defineProperty this::, "disableContinue",
    get: ->
      switch this.step
        when 1 then return this.template is null
        when 2 then return this.numberOfDataFiles is 0
        when 3 then return !this.formOk
        when 4 then return !this.processFinished
        when 5 then return false
    enumerable: true
    configurable: true

  ###* virtual Field (Getter) is the dataform complete??
    * @name PredatorFolder#reducedMode
    * are we running in hiddenFramework mode ?
    * @type {boolean}
    ###
  Object.defineProperty this::, "formOk",
    get: ->
      result = validateFormPerSchema(this.dataFormModel, this.dataFormSchema)
      allGood = Object.values(result).every((res) => res is null)
      return allGood
    enumerable: true
    configurable: true

  ###* virtual Field (Getter) are we running in hiddenFramework mode ?
    * @name PredatorFolder#reducedMode
    * are we running in hiddenFramework mode ?
    * @type {boolean}
    ###
  Object.defineProperty this::, "reducedMode",
    get: ->
      if this.ComponentHub.activePlugin?
        if this.ComponentHub.activePlugin.hideFrameWork
          return true
      return false
    enumerable: true
    configurable: true

  ###* virtual Field (Getter) returns current resourceId of ReportFolder*
    * @name PredatorFolder#formDataPreview
    * @type {boolean} reportFolderId
    ###
  Object.defineProperty this::, "reportFolderId",
    get: ->
      if this.reportFolder?
        return this.reportFolder.resourceId
      return null
    enumerable: true
    configurable: true


  ###* virtual Field (Getter) returns current formData*
    * @name PredatorFolder#formDataPreview
    * @type {boolean} formDataPreview
    ###
  Object.defineProperty this::, "formDataPreview",
    get: ->
      return "#{this.rdrNumber} - #{this.roNumber}"
    enumerable: true
    configurable: true

  ###* virtual Field (Getter) returns current formData*
    * @name PredatorFolder#processState
    * @type {boolean} processState
    ###
  Object.defineProperty this::, "processState",
    get: ->
      if this.isQualityStep
        if this.previewStep?
          if this.previewStep.processes.length
            process = this.previewStep.processes[0]
            return "QUALITY CONTROL: State: #{process.runStatus}, Result: #{process.runResult}"
      else
        if this.executionStep?
          if this.executionStep.processes.length
            process = this.executionStep.processes[0]
            return "State: #{process.runStatus}, Result: #{process.runResult}"
        return "State: NOT STARTED, Result: UNDEFINED"
    enumerable: true
    configurable: true
  ###* virtual Field (Getter) returns current templateConfigs*
    * @name PredatorFolder#templateConfigs
    * @type {Array} templateConfigs
    ###
  Object.defineProperty this::, "templateConfigs",
    get: ->
      if this.templates?
        return this.templates
      return []
    enumerable: true
    configurable: true
  ###*
    * @param {Object} options
    * @property {FolderviewerProps} props
    * @property {Function} emit
    * @property {object} refs
    * @property {object} quasar
    * @constructor
    ###
  constructor: (options) ->
    super(options)
    this.resource=options.props.resource
    console.log this.resource.path
    # neverClose causes, that the tab containing this resource can never be closed by the user
    this.resource.neverClose = !this.ComponentHub.activeServer.userdata.FullWebAccess
    this.uploader=options.refs.uploader
    this.quasar=options.quasar
    this.icon = "mdi-google-downasaur"
    this.step = 1
    this.helpResource = null
    this.dataFolder = null
    this.reportFolder = null
    this.templateFolder = null
    this.templates = []
    this.template=null
    this.improve = this.ComponentHub.activeServer
    this.stepTemplate = null
    this.previewStepTemplate = null
    this.reportsExpanded=true
    this.tab="creation"
    this.reportsFilter=""
    this.reportsEntity=""
    this.now = ''
    this.cleanUp()

  getHelp: ->
    res = await this.improve.doQuery apiGetResourceByPath "#{this.resource.path}/predator_help.pdf", false
    if res.status >= 200 and res.status < 300
      this.helpResource = new ImproveResource res.data

  showHelp: ->
    if this.helpResource?
      this.ComponentHub.FileSystemTree.onRowClick 0,this.helpResource,0,null
    else
      alert "No Help File found! Please contact your Administrator"

  ###* resets all variables at initialization or after a report was generated
   * @return {any}
  ###
  cleanUp: ->
    this.tmpDataDir = null
    this.dataUploadDir = null
    this.dataFiles=[]
    this.rdrNumber = ''
    this.roNumber = ''  #RO7049389
    this.experimentName = ''
    this.experimentDate = (new Date()).toISOString().split("T")[0]
    this.respScientist = ''
    this.collabScientist = ''
    this.reportTitle = 'Untitled Report'
    this.reportDir = null
    this.analysisTree = null
    this.previewStep = null
    this.executionStep = null
    this.searchInterval = null
    this.showProgress = false
    this.processFinished = false
    this.processOK = false
    this.reportCreated = false
    this.processResult="OK"
    this.createdReportResourceId=""
    this.previewMode = false
    this.qualityTestRun = true
    this.reRun = false
    this.titleBeforeRerun=""
    this.templateBeforeRerun =
      name: ''
    this.isQualityStep = false
    this.dataFormModel = null
    this.dataFormSchema = []
    this.template=null
    try
      this.ComponentHub.templateselector.selection=[]
      this.ComponentHub.templateselector.selectedrows=null
    catch e
      # never mind

    return


  ###* Generates a temporary folder for holding the report input data
   * @return {string}
  ###
  generateTempDataDirName:->
    this.now = new Date().toLocaleString().replace(/[^a-z0-9]/gmi, " ").replace(/\s+/g, "_")
    user = this.improve.userdata.username
    return "#{user}_#{this.now}"

  ###* Opens the predator folder and searches for subfolders containing a config.json
   * Further on pushes all those folders which are containers for templates to the templates array
   * @return {*}
  ###
  determinePredatorFolders: ->
    this.templates = []
    await this.ComponentHub.refreshFolder this.resource.resourceId
    nextTick =>
      # Open for later download
      if !this.ComponentHub.FileSystemTree.isExpanded this.resource
        await this.ComponentHub.FileSystemTree.toggleExpanded this.resource
    folders = this.ComponentHub.FileSystemTree.getAllChildren this.resource.resourceId, false
    for folder in folders
      if folder.isFolderish
        await this.ComponentHub.refreshFolder folder.resourceId
        contents = this.ComponentHub.FileSystemTree.getAllChildren folder.resourceId, false
        for file in contents
          if file.name.toLowerCase() is "config.json"
            await file.getInitContent()
            if typeof file.content isnt 'object'
              try
                file.content = JSON.parse(file.content)
              catch e
                await this.ComponentHub.PageIndex.alert "File config.json in folder #{folder.path} does not contain valid JSON. Will skip it"
            if typeof file.content is 'object'
              file.content["dataSchema"] = this.convertModelUpdated file.content
              if !file.content.report.hasOwnProperty("formColumns")
                file.content.report["formColumns"] = 2
              file.content.report["folder"] = folder.name
              file.content.report["folderId"] = folder.entityId
              file.content.report["folderResourceId"] = folder.resourceId
              file.content["mandatories"] = []
              for field in file.content.report.fields
                if field.mandatory
                  file.content["mandatories"].push field.label

              if !this.ComponentHub.FileSystemTree.isExpanded folder
                await this.ComponentHub.FileSystemTree.toggleExpanded folder
              this.templates.push file.content
          if file.nodeType is "Analysis Tree"
            # also open all Nalysis Trees to ensure all steps are present in FileSystemBrowser
            await this.ComponentHub.refreshFolder file.resourceId
    return


  ###* When a template is selected with the mouse, we set this as our template
   * @param {object} row
   * @return {*}
  ###
  setTemplate: (row) ->
    this.template = row
    unless this.template?
      this.dataFolder = null
      this.reportFolder = null
      this.stepTemplate  = null
      this.previewStepTemplate = null
      this.dataFormModel = null
      this.dataFormSchema = []
      return
    this.dataFolder = this.ComponentHub.getResourceFromTree this.template.dataStore
    this.reportFolder = this.ComponentHub.getResourceFromTree this.template.reportFolder
    this.stepTemplate  = this.ComponentHub.getResourceFromTree this.template.step
    this.previewStepTemplate = this.ComponentHub.getResourceFromTree this.template.previewStep
    this.dataFormSchema = this.template.dataSchema
    verified = await this.verifyMetaData()
    if !verified
      this.template = null
      this.dataFolder = null
      this.reportFolder = null
      this.stepTemplate  = null
      this.previewStepTemplate = null
      this.dataFormModel = null
      this.dataFormSchema = []
      this.ComponentHub.templateselector.selection=[]
      this.ComponentHub.templateselector.selectedrows=null
      return

    await this.ComponentHub.refreshFolder this.dataFolder.resourceId
    nextTick =>
      # Open for later download
      if !this.ComponentHub.FileSystemTree.isExpanded this.dataFolder
        await this.ComponentHub.FileSystemTree.toggleExpanded this.dataFolder
    return

  ###* Get called when the blitz Form is updated
   * @return {Array}
  ###
  convertModelUpdated: (improveModel) ->
    tp  = {
      "label": "RO Number",
      "mapping": "PC_RO_NUMBER",
      "mandatory": true,
      "type": "String"
    }
    schema = []
    for field in improveModel.report.fields
      blitzType = "text"
      switch field.type
        when "String" then blitzType = "text"
        when "Date" then blitzType = "date"
        when "Int" then blitzType = "number"
        when "Float"  then blitzType = "number"
        else blitzType = "text"
      if field.hasOwnProperty("subLabel")
        sublabel = field.subLabel
      else
        if field.mandatory
          sublabel = "Required Field"
        else
          sublabel = "Optional Field"
      emptyField =
        id: field.mapping
        label: field.label
        data_type: field.type
        subLabel: sublabel
        component:  "QInput"
        type: blitzType
        minimal: true
        dense: true
        rounded: true
        standout: field.mandatory
        clearable:true
        span: "50%"
        required: field.mandatory
      schema.push emptyField
    schema.push {
      id: "qualityRun",
      component: "QToggle",
      type: "checkbox",
      span: 1,
      dense: true,
      label: improveModel.preview.switchLabel || "Quality control",
      subLabel: improveModel.preview.switchSubLabel || "When checked, a test run is done verifying the RO Number",
      defaultValue: improveModel.preview.defaultOn || false
    }
    return schema

  ###* Get called when the blitz Form is updated
   * @return {*}
  ###
  dataFormModelUpdated: (x,y) ->
    this.roNumber = x.PC_REPORT_RO
    this.rdrNumber = x.PC_REPORT_RDR
    this.qualityTestRun = if this.previewMode then false else x.qualityRun
    console.log x

  ###* Checks whether a metadata field is present in the schema fields
   * @param {string} fieldname to check
   * @return {object | null}
  ###
  getFieldFromSchema: (fieldname) ->
    if this.dataFormSchema?
      for field in this.dataFormSchema
        if field.id is fieldname
          value = null
          switch field.data_type
            when "String"
              value = if this.dataFormModel[field.id]? then this.dataFormModel[field.id] else ''
            when "Date"
              value = if this.dataFormModel[field.id]? then Date.parse( this.dataFormModel[field.id]) else 0
            when "Int"
              value = if this.dataFormModel[field.id]?  then  parseInt( this.dataFormModel[field.id]) else 0
            when "Float"
              value = if this.dataFormModel[field.id]? then parseFloat( this.dataFormModel[field.id]) else 0
            else
              value = if this.dataFormModel[field.id]? then this.dataFormModel[field.id] else ''
          retval =
            type: field.data_type
            value : value
          return retval
    return null


  ###* Writes metadata back to the execution step before it is started
   * @param {boolean} createEmptyReport - true if only a empty report should be generated
   * @param {boolean} qualityRun - true if only a check to verify input data should be run
   * @return {*}
  ###
  setStepData: (createEmptyReport = false, qualityRun = false) =>
    if (this.previewStep? or this.executionStep?) and this.reportTitle? and this.dataFormModel?
      newMD = []
      if createEmptyReport
        stepResourceId = this.executionStep.resourceId
        md =
          resourceId: stepResourceId
          descriptorId: this.ComponentHub.activeServer.metaDataDefinitionMapping['PC_REPORT_EMPTY'].id
          descriptorName: "PC_REPORT_EMPTY"
          descriptorType: "TEXT"
          textValue: "TRUE"
        newMD.push md
        md =
          resourceId: stepResourceId
          descriptorId: this.ComponentHub.activeServer.metaDataDefinitionMapping['PC_REPORT_TITLE'].id
          descriptorName: "PC_REPORT_TITLE"
          descriptorType: "TEXT"
          textValue: this.reportTitle
        newMD.push md
      else
        #
        ###* @ts-ignore ###
        stepResourceId = if qualityRun then this.previewStep.resourceId else this.executionStep.resourceId
        for md in this.stepTemplate.metadata
          mdnew = md
          mdnew.resourceId = stepResourceId
          pushIt = true
          switch mdnew.descriptorName
            when "PC_REPORT_DATAFILE"
              for file in this.dataFiles
                if !file.isFolderish
                  mdf = JSON.parse(JSON.stringify(mdnew))
                  mdf.textValue = file.name
                  newMD.push(mdf)
              pushIt = false
            when "PC_REPORT_TITLE"
              mdnew.textValue = this.reportTitle
            when "PC_REPORT_DATA_FOLDER"
              mdnew.textValue = this.dataUploadDir.resourceId
            when "PC_REPORT_TEST_COMPOUND"
              # Test Compound has to be removed
              # mdnew.textValue = this.repowizard.metadata.test_compound
              mdnew.textValue = this.roNumber
            else
              fieldDescriptor = this.getFieldFromSchema mdnew.descriptorName
              if fieldDescriptor?
                switch fieldDescriptor.type
                  when "String" then mdnew.textValue = fieldDescriptor.value
                  when "Date" then  mdnew.dateValue = fieldDescriptor.value
                  when "Int" then  mdnew.numValue = fieldDescriptor.value
                  when "Float" then  mdnew.numValue = fieldDescriptor.value
                  else value = then mdnew.textValue = fieldDescriptor.value
          if pushIt
            newMD.push mdnew
          pushIt = true

      res =  await this.improve.doQuery apiSetMetaDataBulk(stepResourceId, newMD)
      return res.status >= 200 and res.status < 300



  ###* Verifies whether all in Schema defined metadata are present in the steps metadata
   * @return {boolean}
  ###
  verifyMetaData: ->
    noSchemaFor = []
    noMetaFor = []
    schemaFields = []
    mdFields = []
    if this.dataFormSchema?
      for field in this.dataFormSchema
        if field.id isnt "qualityRun"
          schemaFields.push field.id
      for md in this.stepTemplate.metadata
        mdFields.push md.descriptorName

      if !schemaFields.includes("PC_REPORT_RO")
        noSchemaFor.push "PC_REPORT_RO"
      if !schemaFields.includes("PC_REPORT_RDR")
        noSchemaFor.push "PC_REPORT_RDR"
      noMetaFor = schemaFields.filter((i) => !mdFields.includes(i))
      errormsg = ""
      if noSchemaFor.length
        errormsg = "Within the template config there are no fields for #{noSchemaFor.join(', ')} declared. Those fields must exist in any report template configuration! "
      if noMetaFor.length
        errormsg += "Found #{noMetaFor.join(', ')} mappings in template config, but they do not exist as metadata in the executing step of the template. "
      if errormsg.length
        await this.ComponentHub.PageIndex.alert errormsg += "Template will not be loaded!"
        return false
      return true


  ###* Writes metadata back to the execution step before it is started
   * @param {boolean} createEmptyReport - true if only a empty report should be generated
   * @param {boolean} qualityRun - true if only a check to verify input data should be run
   * @return {*}
  ###
  setStepDataOld: (createEmptyReport=false, qualityRun = false) =>
    newMD = []
    if createEmptyReport
      stepResourceId = this.executionStep.resourceId
      md =
        resourceId: stepResourceId
        descriptorId: this.ComponentHub.activeServer.metaDataDefinitionMapping['PC_REPORT_EMPTY'].id
        descriptorName: "PC_REPORT_EMPTY"
        descriptorType: "TEXT"
        textValue: "TRUE"
      newMD.push md
      md =
        resourceId: stepResourceId
        descriptorId: this.ComponentHub.activeServer.metaDataDefinitionMapping['PC_REPORT_TITLE'].id
        descriptorName: "PC_REPORT_TITLE"
        descriptorType: "TEXT"
        textValue: this.reportTitle
      newMD.push md

    else
      stepResourceId = if qualityRun then this.previewStep.resourceId else this.executionStep.resourceId
      for md in this.stepTemplate.metadata
        mdnew = md
        #try
        #  delete mdnew.metadataId
        #  delete mdnew.metadataHistoryId
        #catch e
        #  #never mind
        mdnew.resourceId = stepResourceId
        pushIt = true
        switch mdnew.descriptorName
          when "PC_REPORT_TITLE"
            mdnew.textValue = this.reportTitle

          when "PC_REPORT_RDR"
            mdnew.textValue = this.rdrNumber

          when "PC_REPORT_TEST_COMPOUND"
            # Test Compound has to be removed
            # mdnew.textValue = this.repowizard.metadata.test_compound
            mdnew.textValue = this.roNumber

          when "PC_REPORT_DATA_FOLDER"
            mdnew.textValue = this.dataUploadDir.resourceId

          when "PC_REPORT_RO"
            mdnew.textValue = this.roNumber

          when "PC_REPORT_DATAFILE"
            for file in this.dataFiles
              if !file.isFolderish
                mdf = JSON.parse(JSON.stringify(mdnew))
                mdf.textValue = file.name
                newMD.push(mdf)
            pushIt = false

          when "PC_REPORT_EXPERIMENT_NAME"
            mdnew.textValue = this.experimentName

          when "PC_REPORT_EXPERIMENT_DATE"
            mdnew.dateValue = Date.parse(this.experimentDate)

          when "PC_REPORT_RESPONSIBLE_SCIENTISTS"
            mdnew.textValue = this.respScientist

          when "PC_REPORT_COLLABORATING_SCIENTISTS"
            mdnew.textValue = this.collabScientist
        if pushIt
          newMD.push mdnew
        pushIt = true

    res = await this.improve.doQuery apiSetMetaDataBulk(stepResourceId, newMD)
    return res.status >= 200 and res.status < 300

  ###* This is called for every step done. Triggered by the continue or back buttons in UI
   * @param {boolean} createEmptyReport - true if only a empty report should be generated
   * @return {*}
  ###
  stepperNext: (createEmptyReport=false) ->
    this.previewMode = createEmptyReport
    if createEmptyReport
      this.qualityTestRun = false
    switch this.step
      when 1
        unless this.template?
          return
        this.dataFolder = this.ComponentHub.getResourceFromTree this.template.dataStore
        this.reportFolder = this.ComponentHub.getResourceFromTree this.template.reportFolder
        this.stepTemplate  = this.ComponentHub.getResourceFromTree this.template.step
        unless this.tmpDataDir?
          this.tmpDataDir = this.generateTempDataDirName()
          res = await this.improve.doQuery apiNewFolder(this.dataFolder.resourceId, this.tmpDataDir,'Folder','Predator Upload')
          if res.status is 200 or res.status is 201
            await this.ComponentHub.refreshFolder this.dataFolder.resourceId
            this.dataUploadDir  = new ImproveResource res.data # this.ComponentHub.getResourceFromTree res.data.resourceId
          else
            await this.ComponentHub.PageIndex.alert "Could not create the temporary DataFolder. The server reports #{res.status} #{res.error} Do you have sufficent permissions ?"
            return
        if this.ComponentHub.hasOwnProperty('PredatorDataFolder_' + this.resource.resourceId)
          # If we already have been at Step2 or higher before, it might be, files still are queued
          this.ComponentHub['PredatorDataFolder_' + this.resource.resourceId].uploader.removeQueuedFiles()
        if createEmptyReport
          # in this case we skip data input uand upload files
          this.step = 3
          # call ourself again
          this.stepperNext createEmptyReport
          # and return silently
          return
      when 2
        if this.numberOfDataFiles is 0
          return
      when 3
        if !createEmptyReport
          # do not ask when just an empt report is created
          answer = await this.ComponentHub.PageIndex.confirm 'Starting report generation', "From this point on, you will not be able to change any parameters. Are you sure you want to continue?"
          if !answer
            return
        this.dataFiles = this.ComponentHub.FileSystemTree.getAllChildren this.dataUploadDir.resourceId, false
        if createEmptyReport
          this.reportTitle = this.template.name + '-' + this.template.version + '-empty-' + this.now
        else
          this.reportTitle = this.template.name  + '-' + this.roNumber + '-' + this.rdrNumber
          targetPath = "#{this.dataFolder.path}/#{this.reportTitle }"
          res = await this.improve.doQuery apiGetResourceByPath targetPath, false
          if res.status >= 200 and res.status < 300
            await this.ComponentHub.PageIndex.alert "Within this template, there already exists a report called #{this.reportTitle} or there was already an attempt to generate a report with such a RO/RDR combination which failed in the past. Please use another RDR Number, or contact your administrator to clean out abondoned attempts in improve."
            return
          else
            console.log "ignore the error in the console, this was only a check to ensure no such resource exists (unique name check)"

        # Create the Analysis Tree where generating Step goes in
        if !this.reRun or this.templateBeforeRerun.name isnt this.template.name or this.titleBeforeRerun isnt this.reportTitle
          #If the quality check failed we do not copy, move araound the whole stuff again
          if this.reRun
            # maybe it is a rerun, and only template or title changed, then there is already an analysis tree, we should delete, to start fresh
            targetPath = "#{this.dataUploadDir.path}/Reports"
            res = await this.improve.doQuery apiGetResourceByPath targetPath, false
            if res.status >= 200 and res.status < 300
              targetId = res.data.resourceId
              res = await this.improve.doQuery apiDeleteResource targetId
              if !(res.status >= 200 and res.status < 300)
                # FAILED
                await this.ComponentHub.PageIndex.alert "Could not clean up data?  Resetting now to Step 1 (Template Selection)"
                this.cleanUp()
                this.step = 1
                return
            else
              console.log "ignore the error in the console, this was only a check to ensure no such resource exists (unique name check)"
          res = await this.improve.doQuery apiNewFolder(this.dataUploadDir.resourceId, "Reports", 'Analysis Tree', "pRedator")
          if res.status is 200 or res.status is 201
            await this.ComponentHub.refreshFolder this.dataUploadDir.resourceId
            this.analysisTree  = this.ComponentHub.getResourceFromTree res.data.resourceId
            unless this.analysisTree?
              this.analysisTree = new ImproveResource res.data
          else
            await this.ComponentHub.PageIndex.alert "Could not create the Analysis Tree for template Step. Do you have sufficent permissions ?  Resetting now to Step 1 (Template Selection)"
            this.cleanUp()
            this.step = 1
            return

          # Now move the template Step into the fresh created Analysis Tree
          res = await this.improve.doQuery  apiCopyResource(this.stepTemplate.resourceId, this.analysisTree.resourceId, "Step1", 'pRedator')
          if res.status is 200 or res.status is 201
            if res.data.nodeType is "Step"
              this.executionStep = new ImproveStep res.data
            else
              parentRes = await this.improve.doQuery apiGetResourceWithMeta(res.data.parentId)
              if parentRes.status is 200 or res.status is 201
                if parentRes.data.nodeType = "Step"
                  this.executionStep = new ImproveStep parentRes.data
                else
                  await this.ComponentHub.PageIndex.alert "Could not create the execution step from the template Step. Already tried workaround. Seems to be an error in improve server (race condition)"
                  this.cleanUp()
                  this.step = 1
                  return


          else
            await this.ComponentHub.PageIndex.alert "Could not create the execution step from the template Step. Do you have sufficent permissions ? Resetting now to Step 1 (Template Selection)"
            this.cleanUp()
            this.step = 1
            return

          # Now move the preview template Step into the fresh created Analysis Tree
          res = await this.improve.doQuery  apiCopyResource(this.previewStepTemplate.resourceId, this.analysisTree.resourceId, "PreviewStep", 'pRedator')
          if res.status is 200 or res.status is 201
            if res.data.nodeType is "Step"
              this.previewStep = new ImproveStep res.data
            else
              parentRes = await this.improve.doQuery apiGetResourceWithMeta(res.data.parentId)
              if parentRes.status is 200 or res.status is 201
                if parentRes.data.nodeType = "Step"
                  this.previewStep = new ImproveStep parentRes.data
                else
                  await this.ComponentHub.PageIndex.alert "Could not create the preview step from the template Step. Already tried workaround. Seems to be an error in improve server (race condition)"
                  this.cleanUp()
                  this.step = 1
                  return
          else
            await this.ComponentHub.PageIndex.alert "Could not create the preview step from the template Step. Do you have sufficent permissions ? Resetting now to Step 1 (Template Selection)"
            this.cleanUp()
            this.step = 1
            return
          # refresh the analysis tree to ensure we get folder contents in the step view when the step is runnning afterwards
          await this.ComponentHub.refreshFolder this.analysisTree.resourceId
          await this.ComponentHub.refreshFolder this.previewStep.resourceId
          await this.ComponentHub.refreshFolder this.executionStep.resourceId

        # Set the metadata for the execution step
        stepmd = await this.setStepData(createEmptyReport,false)
        if !stepmd
          await this.ComponentHub.PageIndex.alert "Could not set  metadata to the execution step. Do you have sufficent permissions ?  Resetting now to Step 1 (Template Selection)"
          this.cleanUp()
          this.step = 1
          return
        if this.qualityTestRun
          # Set the metadata for the execution step
          stepmd = await this.setStepData(false,true)
          if !stepmd
            await this.ComponentHub.PageIndex.alert "Could not set  metadata to the preview step. Do you have sufficent permissions ?  Resetting now to Step 1 (Template Selection)"
            this.cleanUp()
            this.step = 1
            return
        if !this.reRun or this.titleBeforeRerun isnt this.reportTitle or this.templateBeforeRerun.name isnt this.template.name
          #At least rename the temporary dataFolder to match the report title. we even have to do this if rerun but the title changed
          res = await this.improve.doQuery apiMoveResource(this.dataUploadDir.resourceId, this.dataFolder.resourceId, this.reportTitle, 'pRedator')
          if res.status is 200 or res.status is 201
            await this.ComponentHub.refreshFolder this.dataFolder.resourceId
            this.dataUploadDir  = this.ComponentHub.getResourceFromTree this.dataUploadDir.resourceId
          else
            await this.ComponentHub.PageIndex.alert "Could not move the data files to their destination. Do you have sufficent permissions ?"
            this.cleanUp()
            this.step = 1
            return
        if this.qualityTestRun
          if !this.runQualityTestStep()
            return
        else
          if !this.runExecutionStep()
            return
      when 4
        if this.processFinished  and this.processResult is "ERROR"
          this.step = 1
          this.cleanUp()
          return

        else
          await this.ComponentHub['PredatorReportFolder_' + this.reportFolder.resourceId].loadFolder()
          this.reportsExpanded = true
          this.reportsFilter = this.reportsEntity.split(":")[-1..][0]

      when 5
        #this.template = null
        this.cleanUp()
    nextTick =>
      this.step += 1
      if this.step is 5
        this.cleanUp()
        this.step = 1
    return

  ###* This actually runs the execution step which generates the report
   * @return {*}
  ###
  runExecutionStep: () ->
    if this.executionStep?
      this.isQualityStep = false
      this.processFinished = false
      this.processOK = false
      # this is needed to show the running step in the vue template
      this.reRun = false
      nextTick =>
        res = await this.improve.doQuery apiRunStep this.executionStep.resourceId
        if res.status is 200
          if this.searchInterval
            this.showProgress = false
            clearInterval(this.searchInterval)
          this.searchInterval=setInterval () =>
            this.showProgress = true
            await this.ComponentHub['PredatorExecutionStep_' + this.resource.resourceId].refreshStep()
            if this.executionStep.processes.length
              process = this.executionStep.processes[0]
              console.log "Process Status",process.runStatus
              await this.ComponentHub.refreshFolder this.executionStep.resourceId
              if process.runStatus is "FINISHED"
                clearInterval(this.searchInterval)
                this.processFinished = true
                this.processResult = process.runResult
                this.processOK = process.runResult is "COMPLETED"
                await this.ComponentHub['PredatorExecutionStep_' + this.resource.resourceId].refreshStep()
                await this.ComponentHub.refreshFolder this.executionStep.resourceId
                if this.processOK
                  reportname = "#{this.reportFolder.path}/#{this.reportTitle}.docx"
                  res = await this.improve.doQuery apiGetResourceByPath reportname, true
                  if res.status >= 200 and res.status < 300
                    this.createdReportResourceId = res.data.resourceId
                    report = new ImproveResource res.data
                    this.reportsEntity=res.data.entityId
                    this.reportCreated = true
                    this.tab="management"
                    nextTick =>
                      await this.ComponentHub['PredatorReportFolder_' + this.reportFolder.resourceId].loadFolder()
                      this.reportsExpanded = true
                      this.reportsFilter = this.reportsEntity.split(":")[-1..][0]
                      await this.ComponentHub['PredatorReportFolder_' + this.reportFolder.resourceId].onRowClick(report,"pred_entity" )

                else
                  this.reportCreated = false
                this.showProgress = false
          ,1500
          return true
        else
          await this.ComponentHub.PageIndex.alert "Could not execute the Step. Do you have sufficent permissions ?"
          return false
    else
      return false

  ###* This runs the data verification report. If verification is done, the user is asked for running the execution step
   * @return {*}
  ###
  runQualityTestStep: () ->
    if this.previewStep?
      this.isQualityStep = true
      this.processFinished = false
      this.processOK = false
      # this is needed to show the running step in the vue template
      res = await this.improve.doQuery apiRunStep this.previewStep.resourceId
      if res.status is 200
        if this.searchInterval
          this.showProgress = false
          clearInterval(this.searchInterval)
        this.searchInterval=setInterval () =>
          this.showProgress = true
          await this.ComponentHub['PredatorExecutionStep_' + this.resource.resourceId].refreshStep()
          if this.previewStep.processes.length
            process = this.previewStep.processes[0]
            console.log "Process Status",process.runStatus
            await this.ComponentHub.refreshFolder this.previewStep.resourceId
            if process.runStatus is "FINISHED"
              clearInterval(this.searchInterval)
              this.processFinished = true
              this.processResult = process.runResult
              this.processOK = process.runResult is "COMPLETED"
              await this.ComponentHub['PredatorExecutionStep_' + this.resource.resourceId].refreshStep()
              await this.ComponentHub.refreshFolder this.previewStep.resourceId
              if this.processOK
                resultFile = "#{this.dataUploadDir.path}/Reports/PreviewStep/check.json"
                res = await this.improve.doQuery apiGetResourceByPath resultFile, true
                if res.status >= 200 and res.status < 300
                  checkFile = new ImproveResource res.data
                  content = await checkFile.getRawFileContents()
                  if content? and content.result?
                    ok = content.result[0] is "OK"
                    if ok
                      answer = await this.ComponentHub.PageIndex.confirm 'Input data is verified', "Do you want to run the report generation now ?"
                      if !answer
                        this.reRun=true
                        this.titleBeforeRerun = this.reportTitle
                        this.templateBeforeRerun = this.template
                      else
                        nextTick =>
                          this.runExecutionStep()

                    else

                      await this.ComponentHub.PageIndex.alert this.template.previewErrorMessage || "Data verification failed. '#{this.roNumber}' was not found in data"
                      this.reRun=true
                      this.titleBeforeRerun = this.reportTitle
                      this.templateBeforeRerun = this.template
                      this.processOK = false



                  else
                    await this.ComponentHub.PageIndex.alert "Data verification did not provide a readable result."
                    this.reRun=true
                    this.titleBeforeRerun = this.reportTitle
                    this.templateBeforeRerun = this.template

              else
                await this.ComponentHub.PageIndex.alert "Execution of the Preview failed"
              this.showProgress = false
              return
        ,1500
        return true
      else
        await this.ComponentHub.PageIndex.alert "Could not execute the Step. Do you have sufficent permissions ?"
        return false
    else
      return false

  mounted: ->
    super()
    await this.determinePredatorFolders()
    this.icon = await this.resource.getDisplayableFromContent()
    this.getHelp()
    this.showContent= true

